import Rosetta from "../../../../rosetta/Rosetta";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {API, ENDPOINTS} from "../../../../network/API";
import {Column} from "../../../table/TableComponent";
import {useEffect, useState} from "react";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {FilterText} from "../../../filter/FilterText";
import {Chronos} from "../../../../chronos/Chronos";
import {Navigator} from "../../../../util/Navigator";
import {CollapsableFilterComponent} from "../../../common/CollapsableFilterComponent";
import {CommonUtil} from "../../../../util/CommonUtil";
import Axios from "axios";

export const BookingListModes = {
    MODE_NO_PLACEMENT : 1,
    MODE_PLACEMENTS : 2
}

export const BookingListScreen = (props) => {

    const {mode} = props;

    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [tableOrdering, setTableOrdering] = useState({name : "lastName", direction : "asc"});

    const [totalCount, setTotalCount] = useState(-1);

    const [modeChanging, setModeChanging] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [filterStartDate, setFilterStartDate] = useState(null); // Chronos.now().format("yyyy-MM-dd")
    const [filterEndDate, setFilterEndDate] = useState(null); // Chronos.now().add(1, Chronos.WEEKS).format("yyyy-MM-dd")

    const [genderNetworkInFlight, setGenderNetworkInFlight] = useState(false);
    const [genders, setGenders] = useState([]);
    const [filterGenderId, setFilterGenderId] = useState(undefined);

    useEffect(() => {
        fetchGendersFromNetwork();
        createTableData();
    }, []);

    useEffect(() => {
        if (!tableData) {
            createTableData();
        }
    }, [tableData]);

    useEffect(() => {
        setModeChanging(true);
        setKeyword("");
        setTableData(null); // Let table data generate on another effect, things get screwy otherwise.
    }, [mode]);

    useEffect(() => {
        if (!modeChanging) {
            createTableData();
        }
    }, [keyword, filterStartDate, filterEndDate, filterGenderId]);

    function createTableData(mMode, mKeyword, mStartDate, mEndDate) {
        let out = {};

        if (mMode === undefined) {
            mMode = mode;
        }

        if (mKeyword === undefined) {
            mKeyword = keyword;
        }

        if (mStartDate === undefined) {
            mStartDate = filterStartDate;
        }

        if (mEndDate === undefined) {
            mEndDate = filterEndDate;
        }

        out.mode = mode;

        // if (mMode) {
        //     out.mode = mMode;
        // } else {
        //     out.mode = BookingListModes.MODE_NO_PLACEMENT;
        // }

        if (mKeyword && mKeyword.trim() !== "") {
            out.keyword = mKeyword;
        }

        if (mStartDate !== null) {
            try {
                out.startDate = Chronos.with(new Date(mStartDate)).seconds()
            } catch (e) {
                console.log(e);
            }
        }

        if (mEndDate !== null) {
            try {
                out.endDate = Chronos.with(new Date(mEndDate)).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        if (filterGenderId && parseInt(filterGenderId) !== -1) {
            out.genderIds = JSON.stringify([filterGenderId]);
        }

        setTableData(out);

        setModeChanging(false);
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data)
        } else if (action === AutoTableActions.ERROR) {
            AlertModal.showError(data);
        } else if (action === AutoTableActions.ROW_CLICKED) {
            Navigator.navigate("/placement/" + data.id);
        } else if (action === AutoTableActions.DATA_CHANGED) {
            if (data && data.hasOwnProperty("count")) {
                setTotalCount(data.count);
            }
        } else if (action === AutoTableActions.SORT_CHANGE) {
            setTableOrdering(data);
        }
    }

    function formatTableDate(data) {
        if (data) {
            try {
                return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
            } catch (e) {
                console.log(e);
            }
        }
        return "";
    }

    function fetchGendersFromNetwork() {
        if (genderNetworkInFlight) return;
        setGenderNetworkInFlight(true);

        Axios.get(ENDPOINTS.user.getGenders)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setGenders(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }

                setGenderNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setGenderNetworkInFlight(false);
            });
    }

    // RENDER

    let bookingRequiredNotice = [];
    if (!mode || parseInt(mode) === BookingListModes.MODE_NO_PLACEMENT) {
        bookingRequiredNotice = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <h3>{Rosetta.string("booking.occupant_awaiting_count", { count : (totalCount >= 0 ? totalCount : "?") })}</h3>
                </div>
            </div>
        );
    }

    // <Column name={"preferredLanguage"} title={Rosetta.string("booking.table_language")} className={"text-center"} sortable={true} />

    let tableElem = [];
    if (mode && tableData) {
        tableElem = (
            <AutoTable
                url={ENDPOINTS.booking.getBookingUsers}
                data={tableData}
                page={page}
                pageSize={pageSize}
                ordering={tableOrdering}
                callback={tableDidCallback}>

                <Column name={"firstName"} title={Rosetta.string("booking.table_first_name")} sortable={true} />
                <Column name={"lastName"} title={Rosetta.string("booking.table_last_name")} sortable={true} />
                <Column name={"genderName"} title={Rosetta.string("booking.table_gender")} className={"text-center"} sortable={true} />
                <Column name={"age"} title={Rosetta.string("booking.table_age")} className={"text-center"} sortable={true} />
                <Column name={"nationality"} title={Rosetta.string("booking.table_nationality")} className={"text-center"} sortable={true} />
                <Column name={"source"} title={Rosetta.string("booking.table_source")} className={"text-center"} sortable={true} />
                <Column name={"id"} title={Rosetta.string("booking.table_fulfilment")} className={"text-center"} render={(data, row) => {
                    let totalPercent = Math.round((row.bookedDuration / row.placementDuration) * 100);

                    return (
                        <>
                            <div><span className={"badge badge-primary"}>{Rosetta.string("booking.table_fulfilment_days", { booked : row.bookedDuration, total : row.placementDuration })}</span></div>
                            <div className={"mt-1"}>
                                <div className={"progress"}>
                                    <div className={"progress-bar"} style={{width: totalPercent + "%"}} />
                                </div>
                            </div>
                        </>
                    )
                }} />
                <Column name={"startDate"} title={Rosetta.string("booking.table_start_date")} className={"text-center"} render={(data, row) => {
                    return formatTableDate(data);
                }} />
                <Column name={"endDate"} title={Rosetta.string("booking.table_end_date")} className={"text-center"} render={(data, row) => {
                    return formatTableDate(data);
                }} />

            </AutoTable>
        )
    }

    return (
        <div className={"booking-list-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string((parseInt(mode) === BookingListModes.MODE_NO_PLACEMENT) ? "booking.occupancy_title" : "booking.booking_title")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"animate-screen-content"} key={"booking-list-" + mode}>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>

                            <CollapsableFilterComponent>
                                <div className={"row justify-content-center"}>

                                    <div className={"col-12 col-md-4 col-lg-3"}>
                                        <label>&nbsp;</label>
                                        <FilterText callback={(s) => setKeyword(s)} />
                                    </div>

                                    <div className={"col-12 col-md-4 col-lg-3"}>
                                        <label>{Rosetta.string("booking.filter_start_date")}</label>
                                        <input type={"date"} className={"form-control"} value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-4 col-lg-3"}>
                                        <label>{Rosetta.string("booking.filter_end_date")}</label>
                                        <input type={"date"} className={"form-control"} value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-4 col-lg-3"}>
                                        {CommonUtil.generateFilterSelect(
                                            Rosetta.string("booking.table_gender"),
                                            genders,
                                            filterGenderId,
                                            (e) => {
                                                setFilterGenderId(e.target.value);
                                            }
                                        )}
                                    </div>

                                </div>
                            </CollapsableFilterComponent>

                        </div>
                    </div>

                    {bookingRequiredNotice}

                    <div className={"row mt-4 justify-content-end"}>
                        <div className={"col-4 col-lg-3"}>
                            <label>{Rosetta.string("common.table_rows")}</label>
                            <select className={"form-select"} value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>

                            <div className={"card"}>
                                <div className={"card-body"} key={"booking-table-" + mode}>

                                    {tableElem}

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )

}