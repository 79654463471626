import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import {SegmentTab, SegmentTabBar, SegmentTabBarActions} from "../../../segmenttab/SegmentTabBar";
import {Container, DateTime, DropDown, File, Former, FormerActions, Text} from "../../../form/Former";
import {CommonUtil} from "../../../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {UserSelectionModal, UserSelectionModalActions, UserSelectionModalModes} from "../../../user/UserSelectionModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Navigator} from "../../../../util/Navigator";
import imageCompression from "browser-image-compression";
import {Chronos} from "../../../../chronos/Chronos";
import {Toast} from "../../../toast/TokyoToaster";
import {AppUser} from "../../../../util/AppUser";

export const TaskEditorScreen = (props) => {

    const MODE_REPORT = 1;
    const MODE_INSPECTION = 2;

    const [mode, setMode] = useState(MODE_REPORT);

    const [sites, setSites] = useState([]);
    const [units, setUnits] = useState([]);
    const [bedrooms, setBedrooms] = useState([]);
    const [beds, setBeds] = useState([]);
    const [inspectionTemplates, setInspectionTemplates] = useState([]);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [assignedUsers, setAssignedUsers] = useState([]);
    const [userSelectionShown, setUserSelectionShown] = useState(false);

    const [filterData, setFilterData] = useState({});

    const [optionNetworkInFlight, setOptionNetworkInFlight] = useState(false);

    const formerSubmit = useRef();

    useEffect(() => {
        fetchFormOptionsFromNetwork();
    }, []);

    useEffect(() => {
        fetchFormOptionsFromNetwork();
    }, [filterData]);

    function modeDidCallback(action, data) {
        if (action === SegmentTabBarActions.SELECT) {
            setMode(data);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                beginFormSubmission(data.data);
            } else {
                console.log("SUBMIT ERROR!");
                console.log(data.errors);
            }
        } else if (action === FormerActions.CHANGE) {
            if (data) {
                switch (data.name) {
                    case "siteId":
                    case "unitId":
                    case "bedroomId":
                    case "bedId":
                        setFilterDataProperty(data.name, data.value);
                        break;
                }
            }
        } else if (action === FormerActions.HANDSHAKE) {
            formerSubmit.current = data.submit;
        }
    }

    function setFilterDataProperty(keyName, value) {
        let fd = CommonUtil.cloneObject(filterData);
        fd[keyName] = value;
        setFilterData(fd);
    }

    function userSelectionDidCallback(action, data) {
        if (action === UserSelectionModalActions.SUBMIT) {
            const exAssignedUsers = [...assignedUsers];

            data.forEach((user) => {
                let hasUser = false;
                exAssignedUsers.forEach((exUser) => {

                });
                for (let i = 0; i < exAssignedUsers.length; i++) {
                    const exUser = exAssignedUsers[i];
                    if (parseInt(exUser.userId) === parseInt(user.id)) {
                        hasUser = true;
                        break;
                    }
                }

                if (!hasUser) {
                    exAssignedUsers.push({
                        userId: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        roleName: user.roleName
                    });
                }
            })

            setAssignedUsers(exAssignedUsers);
        } else if (action === OffcanvasActions.CLOSE) {
            setUserSelectionShown(false);
        }
    }

    function removeUserAssignment(user) {
        const exAssignedUsers = [...assignedUsers];

        for (let i = 0; i < exAssignedUsers.length; i++) {
            if (parseInt(exAssignedUsers[i].userId) === parseInt(user.userId)) {
                exAssignedUsers.splice(i, 1);
                break;
            }
        }

        setAssignedUsers(exAssignedUsers);
    }

    function beginFormSubmission(formData) {
        if (formData.hasOwnProperty("images") && formData.images.length > 0) {
            let firstImage = formData.images[0].file;
            imageCompression(firstImage, {
                maxSizeMB: 2,
                useWebWorker : true,
                initialQuality : 90
            })
            .then((image) => {
                submitTaskOverNetwork(formData, image);
            })
            .catch((e) => {
                console.log(e);
                submitTaskOverNetwork(formData);
            })
        } else {
            submitTaskOverNetwork(formData);
        }
    }

    function fetchFormOptionsFromNetwork() {
        if (optionNetworkInFlight) return;
        setOptionNetworkInFlight(true);

        let data = {
            siteId : CommonUtil.getOrDefault(filterData, "siteId", undefined),
            unitId : CommonUtil.getOrDefault(filterData, "unitId", undefined),
            bedroomId : CommonUtil.getOrDefault(filterData, "bedroomId", undefined)
        };

        Axios.post(ENDPOINTS.task.getTaskOptionData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSites(resp.data.sites);
                    setUnits(resp.data.units);
                    setBedrooms(resp.data.bedrooms);
                    setBeds(resp.data.beds);
                    setInspectionTemplates(resp.data.inspectionTemplates);
                } else {
                    console.log(API.formatError(resp));
                }
                setOptionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);

            })
    }

    function submitTaskOverNetwork(formData, firstImage) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        let url = ENDPOINTS.workerReport.submitWorkerReport;
        if (mode === MODE_INSPECTION) {
            url = ENDPOINTS.inspection.submitInspection;
        }

        let assignedUserIds = undefined;
        if (assignedUsers && assignedUsers.length > 0) {
            assignedUserIds = [];
            assignedUsers.forEach((user) => {
                assignedUserIds.push(user.userId);
            });
            assignedUserIds = JSON.stringify(assignedUserIds);
        }

        let requiredDate = undefined;
        if (formData.hasOwnProperty("requiredDate")) {
            console.log("REQUIRED DATE: " + formData.requiredDate);
            try {
                requiredDate = Chronos.with(new Date(formData.requiredDate)).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        let data = {
            siteId : formData.siteId,
            unitId : formData.unitId,
            bedroomId : CommonUtil.getOrDefault(formData, "bedroomId", undefined),
            bedId : CommonUtil.getOrDefault(formData, "bedId", undefined),
            description : CommonUtil.getOrDefault(formData, "comments", undefined),
            inspectionTemplateId : CommonUtil.getOrDefault(formData, "inspectionTemplateId", undefined),
            completeDate : requiredDate,
            assignedUserIds,
            file : firstImage
        };

        Axios.post(url, CommonUtil.objectToFormData(data))
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    // Start at index 1 because we should already have uploaded the index-zero image
                    uploadTaskImage(resp.data.id, formData, 1);
                } else {
                    AlertModal.showError(API.formatError(resp));
                    setSubmitNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("TS1000C"));
            });
    }

    function uploadTaskImage(id, formData, index) {
        let images = [];
        if (formData.hasOwnProperty("images")) {
            images = formData.images;
        }

        if (images.length <= index) {
            completeSubmission();
            return;
        }

        let imageFile = images[index].file;
        imageCompression(imageFile, {
            maxSizeMB : 2,
            useWebWorker : true,
            initialQuality : 90
        })
        .then((image) => {
            let url = ENDPOINTS.workerReport.uploadWorkerReportImage;
            let idKey = "workerReportId";
            if (mode === MODE_INSPECTION) {
                url = ENDPOINTS.inspection.uploadInspectionImage;
                idKey = "inspectionId";
            }

            let data = new FormData();
            data.append(idKey, id);
            data.append("file", image);

            Axios.post(url, data)
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        console.log("UPLOADED IMAGE!")
                    } else {
                        console.log("ERROR! " + API.formatError(resp));
                    }
                    uploadTaskImage(id, formData, index + 1);
                })
                .catch((e) => {
                    console.log(e);
                    uploadTaskImage(id, formData, index + 1);
                });
        })
        .catch((e) => {
            uploadTaskImage(id, formData, index + 1);
        });
    }

    function completeSubmission() {
        setSubmitNetworkInFlight(false);

        Toast.show(
            Rosetta.string("common.success"),
            Rosetta.string("user_reports.editor_submit_success"),
            Toast.SUCCESS,
            Toast.LONG
        );

        Navigator.navigate("/tasks/");
    }

    // RENDER

    // Specific form fields for Report/Inspections
    let modeFields = [];
    if (mode === MODE_REPORT) {
        modeFields = [
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <Text
                        name={"comments"}
                        label={Rosetta.string("user_reports.editor_comment")}
                        multiline={true}
                        className={"form-control"} />
                </Container>
            </Container>,

            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <File
                        name={"images"}
                        label={Rosetta.string("user_reports.editor_images")}
                        preview={"image"}
                        previewClassName={"image-preview ratio ratio-4x3 me-2 mb-2"}
                        buttonClassName={"btn btn-primary"}
                        className={"text-center"} />
                </Container>
            </Container>
        ]
    } else {
        modeFields = [
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <DropDown name={"inspectionTemplateId"} label={Rosetta.string("user_reports.editor_inspection_template")} className={"form-select"}>
                        {inspectionTemplates.map((template) => (
                            <option value={template.id}>{template.name}</option>
                        ))}
                    </DropDown>
                </Container>
            </Container>
        ]
    }

    let userElems = (
        <div className={"empty-message"}>{Rosetta.string("user_reports.detail_assigned_users_none")}</div>
    )
    if (assignedUsers.length > 0) {
        userElems = assignedUsers.map((user) => (
            <li className={"list-group-item d-flex justify-content-between align-items-center"}>
                <div className={"ms-2 me-auto"}>
                    <div className={"fw-bold"}>{Rosetta.string("common.name_format", { given_name : user.firstName, family_name : user.lastName })}</div>
                    <div>{user.roleName}</div>
                </div>
                <span className={"badge bg-danger rounded-pill clickable"} onClick={() => removeUserAssignment(user)}>{Rosetta.string("common.remove")}</span>
            </li>
        ));

        userElems = (
            <ul className={"list-group"}>
                {userElems}
            </ul>
        )
    }

    return (
        <div className={"worker-report-detail-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("user_reports.title_inspections")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <SegmentTabBar
                            className={"hops-segment-tab-bar"}
                            selected={mode}
                            callback={modeDidCallback}>
                            <SegmentTab id={MODE_REPORT} label={Rosetta.string("user_reports.editor_type_report")} />
                            <SegmentTab id={MODE_INSPECTION} label={Rosetta.string("user_reports.editor_type_inspection")} />
                        </SegmentTabBar>
                    </div>
                </div>

                <div className={"animate-screen-content"} key={"view-" + mode}>

                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-md-8 col-lg-6"}>
                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12"}>

                                            <Former callback={formDidCallback}>
                                                <Container className={"row"}>
                                                    <Container className={"col-12"}>

                                                        <Container className={"row mt-2"}>
                                                            <Container className={"col-12"}>
                                                                <DropDown name={"siteId"} label={Rosetta.string("user_reports.editor_site")} className={"form-select"}>
                                                                    {sites.map((site) => (
                                                                        <option value={site.id}>{site.name}</option>
                                                                    ))}
                                                                </DropDown>
                                                            </Container>
                                                        </Container>

                                                        <Container className={"row mt-2"}>
                                                            <Container className={"col-12"}>
                                                                <DropDown name={"unitId"} label={Rosetta.string("user_reports.editor_unit")} className={"form-select"}>
                                                                    {units.map((unit) => (
                                                                        <option value={unit.id}>{unit.name}</option>
                                                                    ))}
                                                                </DropDown>
                                                            </Container>
                                                        </Container>

                                                        <Container className={"row mt-2"}>
                                                            <Container className={"col-12"}>
                                                                <DropDown name={"bedroomId"} label={Rosetta.string("user_reports.editor_bedroom")} className={"form-select"}>
                                                                    {bedrooms.map((bedroom) => (
                                                                        <option value={bedroom.id}>{bedroom.name}</option>
                                                                    ))}
                                                                </DropDown>
                                                            </Container>
                                                        </Container>

                                                        <Container className={"row mt-2"}>
                                                            <Container className={"col-12"}>
                                                                <DropDown name={"bedId"} label={Rosetta.string("user_reports.editor_bed")} className={"form-select"}>
                                                                    {beds.map((bed) => (
                                                                        <option value={bed.id}>{bed.name}</option>
                                                                    ))}
                                                                </DropDown>
                                                            </Container>
                                                        </Container>

                                                        {modeFields}

                                                        <Container className={"row mt-2"}>
                                                            <Container className={"col-12"}>
                                                                <DateTime name={"requiredDate"} label={Rosetta.string("user_reports.detail_deadline")} className={"form-control"} />
                                                            </Container>
                                                        </Container>

                                                    </Container>
                                                </Container>
                                            </Former>

                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_assigned_users")}</label>
                                        </div>

                                        <div className={"col-12 mt-2"}>
                                            {userElems}
                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col-12 text-center"}>
                                            <button className={"btn btn-primary"} onClick={() => setUserSelectionShown(true)}>{Rosetta.string("user_reports.detail_assigned_users_add")}</button>
                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col-12 text-center"}>
                                            <button className={"btn btn-primary"} onClick={() => formerSubmit.current()}>{Rosetta.string("common.save")}</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <UserSelectionModal
                shown={userSelectionShown}
                userRoleIds={AppUser.getNonWorkerRoles()}
                mode={UserSelectionModalModes.MULTI}
                callback={userSelectionDidCallback} />
        </div>
    )

}