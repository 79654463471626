export const Inspection = {
    statuses : {
        CREATED : 1,
        ACCEPTED : 2,
        IN_PROGRESS : 3,
        COMPLETE : 4,
        CANCELLED : 5
    },
    imageTypes : {
        STANDARD : 1,
        COMPLETION : 2
    }
}