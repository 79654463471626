export const WorkerReport = {

    statuses : {
        RAISED : 1,
        ACCEPTED : 2,
        IN_PROGRESS : 3,
        COMPLETE : 4,
        REJECTED : 5
    },
    imageTypes : {
        WORKER : 1,
        COMPLETION : 2
    }

}