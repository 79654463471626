import {UrlUtil} from "./UrlUtil";

export const GatewayUtil = {
    getGatewayUrl : (suffix) => {
        return process.env.REACT_APP_GATEWAY_URL + suffix;
    },
    goToGateway : (url) => {
        if (!UrlUtil.isFullyQualifiedUrl(url)) {
            url = GatewayUtil.getGatewayUrl(url);
        }
        window.location.href = url;
    }
}