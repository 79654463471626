import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {Chronos} from "../../../../chronos/Chronos";

import "./BookingReplacementConfirmationModal.css";

export const BookingReplacementConfirmationActions = {
    CLOSE : "bookingreplacementconfirmation.close"
}

export const BookingReplacementConfirmationModal = (props) => {

    const {shown} = props;
    const {data} = props;
    const {callback} = props;
    const {forceDismiss} = props;

    const [selectedRecordId, setSelectedRecordId] = useState();
    const [dismissing, setDismissing] = useState(false);
    const [callbackData, setCallbackData] = useState(null);

    const [dismissTrigger, setDismissTrigger] = useState(null);

    useEffect(() => {
        if (!shown) {
            setSelectedRecordId(undefined);
            setDismissing(false);
            setCallbackData(null);
            setDismissTrigger(null);
        }
    }, [shown]);

    useEffect(() => {
        let dismissTimer = undefined;
        if (dismissing) {
            clearTimeout(dismissTimer);
            dismissTimer = setTimeout(() => {
                setDismissTrigger(true);
            }, 200);
        }

        return () => {
            clearTimeout(dismissTimer);
        }
    }, [dismissing]);

    useEffect(() => {
        if (dismissTrigger) {
            handleCallback(BookingReplacementConfirmationActions.CLOSE, callbackData);
        }
    }, [dismissTrigger]);

    useEffect(() => {
        if (forceDismiss === true && !dismissing) {
            setDismissing(true);
        }
    }, [forceDismiss]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submitOccupancy(recordId) {
        if (recordId) {
            let outData = undefined;
            for (const record of data) {
                if (record.unitBedroomBedOccupancyId === recordId) {
                    outData = record;
                    break;
                }
            }

            setCallbackData(outData);
            setDismissing(true);
        }
    }

    function formatDate(date) {
        try {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return "";
    }

    // RENDER

    if (!shown) {
        return [];
    }

    let selectList = [];
    if (data && data.length > 0) {
        selectList = data.map((record) => {
           let activeClass = "";
           if (record.unitBedroomBedOccupancyId === selectedRecordId) {
               activeClass = " active";
           }

            return (
                <div className={"list-group-item list-group-item-action" + activeClass} onClick={() => setSelectedRecordId(record.unitBedroomBedOccupancyId)}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h5>{Rosetta.string("common.name_format", { given_name : record.firstName, family_name : record.lastName })}</h5>
                        </div>
                    </div>

                    <div className={"row mt-1"}>
                        <div className={"col-12 col-md-6 col-lg-4 mb-1"}>
                            <label>{Rosetta.string("booking.detail_dob")}</label>
                            <div>{formatDate(record.dateOfBirth)}</div>
                        </div>

                        <div className={"col-12 col-md-6 col-lg-4 mb-1"}>
                            <label>{Rosetta.string("booking.detail_nationality")}</label>
                            <div>{record.nationalityName}</div>
                        </div>

                        <div className={"col-12 col-md-6 col-lg-4 mb-1"}>
                            <label>{Rosetta.string("booking.detail_language")}</label>
                            <div>{record.preferredLanguage}</div>
                        </div>
                    </div>

                    <div className={"row mt-1"}>
                        <div className={"col-12 col-md-6 col-lg-4 mb-1"}>
                            <label>{Rosetta.string("booking.detail_start_date")}</label>
                            <div>{formatDate(record.nextStartDate)}</div>
                        </div>

                        <div className={"col-12 col-md-6 col-lg-4 mb-1"}>
                            <label>{Rosetta.string("booking.detail_end_date")}</label>
                            <div>{formatDate(record.nextEndDate)}</div>
                        </div>
                    </div>
                </div>
            )
        });
    } else {
        selectList = (
            <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
        )
    }

    let submitButtonElem = (<button className={"btn btn-success disabled"}>{Rosetta.string("booking.detail_replace_prompt_commit")}</button>);
    if (selectedRecordId) {
        submitButtonElem = (<button className={"btn btn-success"} onClick={() => submitOccupancy(selectedRecordId)}>{Rosetta.string("booking.detail_replace_prompt_commit")}</button>);
    }

    let mutationKey = "booking-replacement-modal";
    if (dismissing) {
        mutationKey += "-dismissing";
    }

    let className = "";
    if (dismissing) {
        className = " dismissing";
    }

    return (
        <div className={"modal fade show booking-replacement-confirmation-modal" + className} key={mutationKey}>
            <div className={"modal-dialog modal-lg"}>
                <div className={"modal-content"}>
                    <div className={"modal-header"}>
                        {Rosetta.string("booking.detail_replace_prompt_title")}
                    </div>

                    <div className={"modal-body"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                {Rosetta.string("booking.detail_replace_prompt_message")}
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <div className={"list-group"}>
                                    {selectList}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"modal-footer justify-content-between"}>
                        <button className={"btn btn-default"} onClick={() => setDismissing(true)}>{Rosetta.string("common.cancel")}</button>

                        {submitButtonElem}
                    </div>
                </div>
            </div>
        </div>
    );

}