import {BrowserRouter, Route, Routes} from "react-router-dom";
import {InitNavigator} from "../util/Navigator";
import RosettaAwareComponent from "./RosettaAwareComponent";
import {TokyoToaster} from "./toast/TokyoToaster";
import {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../util/CommonUtil";
import {DataManager} from "../data/DataManager";
import {API} from "../network/API";

import {BaseModalActions} from "./alertmodal/BaseModal";
import {AlertModalComponent} from "./alertmodal/AlertModal";
import {LoginScreen} from "./screens/login/LoginScreen";
import {AppUser} from "../util/AppUser";
import {Armstrong} from "../util/Armstrong";
import {WorkerUserInfoScreen} from "./screens/worker/WorkerUserInfoScreen";
import {HopsHeaderBar, HopsHeaderBarActions, HopsHeaderBarStyle} from "./headerbar/HopsHeaderBar";
import {CollapsableNavigationComponent} from "./navigation/CollapseableNavigationComponent";
import {OffcanvasActions, OffcanvasGravity} from "./offcanvas/Offcanvas";
import Rosetta from "../rosetta/Rosetta";
import {MessageListScreen} from "./screens/worker/messages/MessageListScreen";
import {MessageDetailScreen} from "./screens/worker/messages/MessageDetailScreen";
import {WorkerReportEntryLandingScreen} from "./screens/worker/report/WorkerReportEntryLandingScreen";
import {UserInfoWidgetActions} from "./navigation/UserInfoWidget";
import {NavList} from "./navigation/NavigationList";
import {VideoCarouselActions, VideoCarouselModal} from "./screens/help/VideoCarouselModal";
import {AdminLTESidebar} from "./adminlte/sidebar/AdminLTESidebar";
import {AdminLTEHeader, AdminLTEHeaderActions, AdminLTEHeaderHelper} from "./adminlte/header/AdminLTEHeader";

import iconHelp from "../assets/images/help.svg";
import iconProfile from "../assets/images/account.svg";
import {Comment} from "./comment/Comment";
import {AdminDashboardScreen} from "./screens/admin/dashboard/AdminDashboardScreen";
import {AvailabilityLandingScreen} from "./screens/admin/availability/AvailabilityLandingScreen";
import {AvailabilityBedDetailScreen} from "./screens/admin/availability/AvailabilityBedDetailScreen";
import {WorkerReportLandingScreen} from "./screens/admin/workerReport/WorkerReportLandingScreen";
import {WorkerReportDetailScreen} from "./screens/admin/workerReport/WorkerReportDetailScreen";
import {InspectionListScreen} from "./screens/admin/inspections/InspectionListScreen";
import {InspectionDetailScreen} from "./screens/admin/inspections/InspectionDetailScreen";
import {TaskEditorScreen} from "./screens/admin/inspections/TaskEditorScreen";
import {InspectionTemplateListScreen} from "./screens/admin/settings/InspectionTemplateListScreen";
import {InspectionTemplateEditorScreen} from "./screens/admin/settings/InspectionTemplateEditorScreen";
import {BookingListModes, BookingListScreen} from "./screens/admin/booking/BookingListScreen";
import {BookingDetailScreen} from "./screens/admin/booking/BookingDetailScreen";
import {FilingLandingScreen} from "./screens/admin/filing/FilingLandingScreen";
import {SitePlanListScreen} from "./screens/admin/settings/siteplans/SitePlanListScreen";
import {SitePlanDetailScreen} from "./screens/admin/settings/siteplans/SitePlanDetailScreen";
import {DynamicLabelsLandingScreen} from "./screens/admin/settings/dynamicLabelling/DynamicLabelsLandingScreen";
import {EvacuationListScreen} from "./screens/admin/site/EvacuationListScreen";
import {OrganisationSelectScreen} from "./screens/admin/organisation/OrganisationSelectScreen";
import {UserAccountModal, UserAccountModalActions} from "./adminlte/header/UserAccountModal";

export const MainComponent = (props) => {

    const [alertModalState, setAlertModalState] = useState({shown:false});
    const [user, setUser] = useState(null);
    const [organisationId, setOrganisationId] = useState(null);
    const [rosettaMutationKey, setRosettaMutationKey] = useState(null);

    const [navigationExpanded, setNavigationExpanded] = useState(false);

    const [videoCarouselShown, setVideoCarouselShown] = useState(false);

    const [userAccountModalShown, setUserAccountModalShown] = useState(false);

    const dataManagerCallback = useRef(null);

    useEffect(() => {
        Armstrong.init();

        let user = DataManager.getUser();
        if (user) {
            setUser(user);
        }

        let sessionToken = DataManager.getSessionToken();
        if (sessionToken !== null) {
            API.setAuthenticationToken(sessionToken);
        }

        const orgId = DataManager.getOrganisationId();
        API.setOrganisationId(orgId);
        setOrganisationId(orgId);

        API.setAuthFailureCallback(() => {
            userDidLogout();
        });

        dataManagerCallback.current = (keyName, value) => {
            if (keyName === DataManager.keys.user) {
                if (value !== user) {
                    userDidChange(JSON.parse(value));
                }
            } else if (keyName === DataManager.keys.sessionToken) {
                API.setAuthenticationToken(value);
            } else if (keyName === DataManager.keys.organisationId) {
                API.setOrganisationId(value);
                setOrganisationId(value);
            }
        }

        DataManager.addCallback(dataManagerCallback.current);

        // Set up global Alert Dialog callbacks
        if (!window.hasOwnProperty("_showModal")) {
            window._showModal = (title, message, buttons, icon) => {
                showAlertModal(title, message, buttons, icon);
            }
        }

        if (!window.hasOwnProperty("_hideModal")) {
            window._dismissModal = () => {
                const ams = CommonUtil.cloneObject(alertModalState);
                ams.forceDismiss = true;
                setAlertModalState(ams);
            }
        }

        return () => {
            if (dataManagerCallback.current !== null) {
                DataManager.removeCallback(dataManagerCallback.current);
            }
        }
    }, []);

    // ALERT MODAL

    function showAlertModal(title, message, buttons, icon) {
        setAlertModalState({
            shown : true,
            title,
            message,
            buttons,
            icon
        });
    }

    function alertModalDidCallback(action) {
        if (action === BaseModalActions.CLOSE) {
            setAlertModalState({shown: false});
        }
    }

    // USER

    function userDidChange(user) {
        setUser(user);
        if (!user) {
            userDidLogout();
        }
    }

    function userDidLogout() {
        DataManager.clear();

        // Force page refresh now
        window.location.href = "/";
    }

    function headerBarDidCallback(action, data) {
        console.log("HEADER BAR CALLBACK: " + action);
        if (action === HopsHeaderBarActions.MENU_OPEN || action === AdminLTEHeaderActions.MENU_CLICK) {
            setNavigationExpanded(!navigationExpanded);
        } else if (action === HopsHeaderBarActions.HELP_CLICKED) {
            setVideoCarouselShown(true);
        }
    }

    function navigationDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setNavigationExpanded(false);
        } else if (action === UserInfoWidgetActions.SIGNOUT) {
            userDidLogout();
        }
    }

    function userAccountModalDidCallback(action, data) {
        if (action === UserAccountModalActions.ACTION_CLOSE) {
            setUserAccountModalShown(false);
        } else if (action === UserAccountModalActions.ACTION_SIGN_OUT) {
            userDidLogout();
        }
    }

    // RENDER

    let mainContent = [];

    if (user) {
        if (parseInt(user.roleID) === AppUser.roles.WORKER) {
            mainContent = (
                <>
                    <div className={"header-wrapper"}>
                        <div className={"container"}>
                            <HopsHeaderBar
                                style={HopsHeaderBarStyle.COMPACT}
                                showBack={true}
                                callback={headerBarDidCallback} />
                        </div>
                    </div>

                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"d-none-sm col-md-4 col-lg-3"}>
                                <CollapsableNavigationComponent
                                    gravity={OffcanvasGravity.END}
                                    items={[
                                        {
                                            title : Rosetta.string("navigation.title"),
                                            items : [
                                                {
                                                    title : Rosetta.string("navigation.worker_info"),
                                                    url : "/"
                                                },
                                                {
                                                    title : Rosetta.string("navigation.worker_messages"),
                                                    url : "/messages"
                                                },
                                                {
                                                    title : Rosetta.string("navigation.worker_report"),
                                                    url : "/report-issue"
                                                }
                                            ]
                                        }
                                    ]}
                                    expanded={navigationExpanded}
                                    callback={navigationDidCallback} />
                            </div>

                            <div className={"col-12 col-md-8 col-lg-9"}>
                                <Routes>
                                    <Route
                                        path={""}
                                        element={<WorkerUserInfoScreen />} />

                                    <Route
                                        path={"/messages"}
                                        element={<MessageListScreen />} />

                                    <Route
                                        path={"/message/:messageId"}
                                        element={<MessageDetailScreen />} />

                                    <Route
                                        path={"/report-issue/"}
                                        element={<WorkerReportEntryLandingScreen />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            let showFullMenu = true;
            let employersNavItem = null;
            if (AppUser.isInRole([AppUser.roles.GATEWAY_ADMIN, AppUser.roles.GATEWAY_READ_ONLY])) {
                employersNavItem = NavList.item(Rosetta.string("navigation.select_employer"), "/select-employer/", "fa-solid fa-building-user");

                // Check to see if Admin has Organisation ID set, if not only show option
                // to select Organisation
                if (!user.farm) {
                    if (!organisationId) {
                        showFullMenu = false;
                    }
                }
            }

            let navigationItems = [
                employersNavItem
            ];

            let mainRoutes = [
                <Route path={"/"}
                   element={<OrganisationSelectScreen />} />
            ];

            if (showFullMenu) {
                navigationItems = [
                    {
                        items : [
                            employersNavItem,
                            NavList.item(Rosetta.string("navigation.main_dashboard"), "/", "fas fa-tachometer-alt"),
                            NavList.item(Rosetta.string("navigation.main_availability"), "/availability", "fa-solid fa-image"),
                            NavList.item(Rosetta.string("navigation.main_all_tasks"), "/tasks", "fa-solid fa-list-check",undefined, [
                                NavList.item(Rosetta.string("navigation.tasks_reports"), "/tasks/reports", "fa-solid fa-flag"),
                                NavList.item(Rosetta.string("navigation.tasks_inspections"), "tasks/inspections", "fa-solid fa-clipboard-list")
                            ]),
                            NavList.item(Rosetta.string("navigation.main_booking"), "/booking", "fa-regular fa-address-book"),
                            NavList.item(Rosetta.string("navigation.main_occupancy"), "/occupancy", "fa-solid fa-book"),
                            NavList.item(Rosetta.string("navigation.main_filing"), "/filing", "fa-solid fa-folder-open"),
                            NavList.item(Rosetta.string("navigation.main_settings"), "/settings", "fa-solid fa-gear", undefined, [
                                NavList.item(Rosetta.string("navigation.settings_inspection_templates"), "/settings/inspection_templates", "fa-solid fa-pen-ruler"),
                                NavList.item(Rosetta.string("navigation.settings_dynamic_labelling"), "/settings/dynamic_labelling", "fa-solid fa-tags"),
                                NavList.item(Rosetta.string("navigation.settings_site_plans"), "/settings/site_plans", "fa-solid fa-sitemap")
                            ]),
                            NavList.item(Rosetta.string("navigation.main_evac_list"), "/evac_list", "fa-solid fa-person-walking-arrow-right")
                        ]
                    }
                ];

                mainRoutes = [
                    <Route
                        path={""}
                        element={<AdminDashboardScreen />} />,

                    <Route
                        path={"/availability"}
                        element={<AvailabilityLandingScreen />} />,

                    <Route
                        path={"/bed/:id"}
                        element={<AvailabilityBedDetailScreen />} />,

                    <Route
                        path={"/tasks/"}
                        element={<WorkerReportLandingScreen />} />,

                    <Route
                        path={"/tasks/reports"}
                        element={<WorkerReportLandingScreen />} />,

                    <Route
                        path={"/report/:id"}
                        element={<WorkerReportDetailScreen />} />,

                    <Route
                        path={"tasks/inspections"}
                        element={<InspectionListScreen />} />,

                    <Route
                        path={"/inspection/:id"}
                        element={<InspectionDetailScreen />} />,

                    <Route
                        path={"/task/create"}
                        element={<TaskEditorScreen />} />,

                    <Route
                        path={"/settings/"}
                        element={<InspectionTemplateListScreen />} />,

                    <Route
                        path={"/settings/inspection_templates/"}
                        element={<InspectionTemplateListScreen />} />,

                    <Route
                        path={"/inspection_template/:id"}
                        element={<InspectionTemplateEditorScreen />} />,

                    <Route
                        path={"/booking/"}
                        element={<BookingListScreen mode={BookingListModes.MODE_NO_PLACEMENT} />} />,

                    <Route
                        path={"/placement/:id"}
                        element={<BookingDetailScreen />} />,

                    <Route
                        path={"/occupancy/"}
                        element={<BookingListScreen mode={BookingListModes.MODE_PLACEMENTS} />} />,

                    <Route
                        path={"/filing/"}
                        element={<FilingLandingScreen />} />,

                    <Route
                        path={"/settings/site_plans"}
                        element={<SitePlanListScreen />} />,

                    <Route
                        path={"/site_plan/:id"}
                        element={<SitePlanDetailScreen />} />,

                    <Route
                        path={"/settings/dynamic_labelling"}
                        element={<DynamicLabelsLandingScreen />} />,

                    <Route
                        path={"/evac_list/"}
                        element={<EvacuationListScreen />} />,

                    <Route
                        path={"/select-employer/"}
                        element={<OrganisationSelectScreen />} />
                ];
            }

            mainContent = (
                <>

                    <div className={"wrapper"}>

                        <AdminLTEHeader
                            callback={headerBarDidCallback}
                            actions={[
                                AdminLTEHeaderHelper.icon(iconHelp, Rosetta.string("common.help"), () => {
                                    setVideoCarouselShown(true);
                                }),
                                AdminLTEHeaderHelper.icon(iconProfile, Rosetta.string("common.profile"), () => {
                                    setUserAccountModalShown(true);
                                })
                            ]} />

                        <UserAccountModal
                            shown={userAccountModalShown}
                            callback={userAccountModalDidCallback} />

                        <AdminLTESidebar
                            expanded={!navigationExpanded}
                            items={navigationItems} />

                        <div className={"content-wrapper"}>
                            <Routes>

                                {mainRoutes}

                                <Route
                                    path={"/select_employer/"}
                                    element={<div>EMPLOYER LIST</div>} />

                            </Routes>
                        </div>
                    </div>

                    <VideoCarouselModal
                        shown={videoCarouselShown}
                        callback={(action, data) => {
                            if (action === VideoCarouselActions.CLOSE) {
                                setVideoCarouselShown(false);
                            }
                        }} />

                </>
            );
        }
    } else {
        mainContent = (
            <Routes>

                <Route
                    path={""}
                    element={<LoginScreen />} />

            </Routes>
        )
    }

    return (
        <BrowserRouter>
            <InitNavigator />

            <RosettaAwareComponent key={rosettaMutationKey} callback={() => setRosettaMutationKey(Math.random())}>
                <TokyoToaster>
                    {mainContent}
                </TokyoToaster>
            </RosettaAwareComponent>

            <AlertModalComponent
                shown={alertModalState.shown}
                icon={alertModalState.icon}
                title={alertModalState.title}
                message={alertModalState.message}
                buttons={alertModalState.buttons}
                forceDismiss={alertModalState.forceDismiss}
                callback={alertModalDidCallback} />

        </BrowserRouter>
    );

}