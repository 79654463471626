import {Offcanvas, OffcanvasGravity} from "../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import {FilterText} from "../filter/FilterText";
import {UserSelectionActions, UserSelectionList} from "./UserSelectionList";
import Rosetta from "../../rosetta/Rosetta";
import WindowUtil from "../../util/WindowUtil";

export const UserSelectionModalModes = {
    SINGLE : "userselectionmodal.single",
    MULTI : "userselectionmodal.multiple"
};

export const UserSelectionModalActions = {
    SUBMIT : "userselectionmodal.submit"
};

export const UserSelectionModal = (props) => {

    const {shown} = props;
    const {title} = props;
    const {mode} = props;
    const {callback} = props;
    const {userRoleIds} = props;

    const [data, setData] = useState({});
    const [selection, setSelection] = useState([]);

    const [keyword, setKeyword] = useState("");
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        createListData();
    }, []);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();

            setSelection([]);
            setKeyword("");
            setForceDismiss(false);
        }
    }, [shown]);

    useEffect(() => {
        createListData();
    }, [keyword]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function createListData() {
        let out = {};

        if (keyword && keyword.trim() !== "") {
            out.keyword = keyword;
        }

        if (userRoleIds && userRoleIds.length > 0) {
            out.userRoleIds = JSON.stringify(userRoleIds);
        }

        setData(out);
    }

    function submitForm() {
        handleCallback(UserSelectionModalActions.SUBMIT, selection);
        setForceDismiss(true);
    }

    function userSelectionListDidCallback(action, data) {
        if (action === UserSelectionActions.SELECT) {
            let useMode = mode ? mode : UserSelectionModalModes.SINGLE;

            if (useMode === UserSelectionModalModes.SINGLE) {
                setSelection([data]);
            } else {
                let newSelection = [...selection];

                let hasItem = false;
                for (let i = 0; i < newSelection.length; i++) {
                    if (newSelection[i].id === data.id) {
                        hasItem = true;
                        newSelection.splice(i, 1);
                        break;
                    }
                }

                if (!hasItem) {
                    newSelection.push(data);
                }

                setSelection(newSelection);
            }
        }
    }

    if (!shown) return [];

    return (
        <Offcanvas
            shown={true}
            title={title}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <FilterText callback={(string) => setKeyword(string)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <UserSelectionList
                        data={data}
                        selection={selection}
                        callback={userSelectionListDidCallback} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-success"} onClick={() => submitForm()}>{Rosetta.string("common.submit")}</button>
                </div>
            </div>

        </Offcanvas>
    )

}