import "./NavigationList.css";
import {NavLink} from "react-router-dom";
import ArrayUtil from "../../util/ArrayUtil";

export const NavList = {
    item : (title, url, icon, badge, items) => {
        return {
            title,
            url,
            icon,
            badge,
            items
        };
    }
}

export const NavigationList = (props) => {

    function handleCallback(action, item) {
        if (props.callback !== undefined) {
            props.callback(action, item);
        }
    }

    function generateItems(items) {
        let out = [];

        for (let item of items) {
            let subItems = [];
            if (item.hasOwnProperty("items")) {
                subItems = generateItems(item.items);

                if (subItems.length > 0) {
                    subItems = (
                        <span className={"navigation-child"}>
                            {subItems}
                        </span>
                    );
                }
            }

            if (item.hasOwnProperty("url")) {
                out.push(
                    <div className={"navigation-contain"}>
                        <NavLink
                            className={"navigation-item clickable"}
                            to={item.url} end
                            activeClassName={"active"}
                            onClick={() => handleCallback("click", item)}>

                            {item.title}
                        </NavLink>

                        {subItems}
                    </div>
                );
            } else {
                out.push(
                    <div className={"navigation-contain"}>
                        <div
                            className={"navigation-item"}
                            onClick={() => handleCallback("click", item)}>

                            {item.title}
                        </div>

                        {subItems}
                    </div>
                )
            }
        }

        return out;
    }

    let mainContent = [];

    if (props.items !== undefined) {
        for (let i = 0; i < props.items.length; i++) {
            let header = props.items[i];
            mainContent.push(
                <div className={"navigation-heading"}>{header.title}</div>
            );

            ArrayUtil.appendArray(mainContent, generateItems(header.items));
        }
    }

    return (
        <div className={"tokyo-navigation-list"}>
            {mainContent}
        </div>
    )

}