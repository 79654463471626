import "./VideoPlayer.css";
import {CommonUtil} from "../../util/CommonUtil";
import {useEffect, useRef, useState} from "react";
import {Chronos} from "../../chronos/Chronos";

import iconPlay from "../../assets/images/media_play.svg";
import iconPause from "../../assets/images/media_pause.svg";
import {ImageUtil} from "../../util/ImageUtil";

export const VideoPlayer = (props) => {

    const {callback} = props;

    const video = useRef();

    const [playing, setPlaying] = useState(false);
    const [ready, setReady] = useState(false);
    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(0);

    const [totalTime, setTotalTime] = useState("00:00");

    const [showControls, setShowControls] = useState(true);

    useEffect(() => {
        if (video.current) {
            setupVideoPlayer();
        }
    }, [video]);

    useEffect(() => {
        if (playing) {
            video.current.play();
        } else {
            video.current.pause();
        }
    }, [playing])

    useEffect(() => {
        console.log("SHOW CONTROLS: " + showControls);
    }, [showControls]);

    useEffect(() => {
        setTotalTime(Chronos.formatTime(total));
    }, [total]);

    function setupVideoPlayer() {
        video.current.addEventListener("canplay", () => {
            setReady(true);

            setTotal(video.current.duration);
        });

        video.current.addEventListener("waiting", () => {
            setReady(false);
        });

        video.current.addEventListener("pause", () => {
            setPlaying(false);
        });

        video.current.addEventListener("play", () => {
            setPlaying(true);
        });

        video.current.addEventListener("timeupdate", () => {
            setProgress(video.current.currentTime);
        })
    }

    function seekbarWasClicked(e) {
        const clientBounds = e.target.getBoundingClientRect();
        const clickX = e.clientX - clientBounds.left;

        const progressPercent = clickX / (clientBounds.right - clientBounds.left);

        video.current.currentTime = total * progressPercent;
    }

    let controlsClass = "";
    if (playing && !showControls) {
        controlsClass = " hide-controls";
    }

    return (
        <div className={"ratio ratio-16x9 video-player-container"} onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
            <div className={"video-player"}>
                <video
                    ref={video}
                    autoPlay={false}
                    controls={false}
                    src={CommonUtil.getOrDefault(props, "src", null)}
                />
            </div>

            <div className={"video-overlay"}>
                <div className={"aligner"} />

                <div className={"video-controls" + controlsClass} key={"video-player-controls"}>
                    <div className={"action-button"} onClick={() => setPlaying(!playing)} style={{backgroundImage : ImageUtil.background(playing ? iconPause : iconPlay)}} />
                    <div className={"seek-bar"}>
                        <div className={"progress"} onClick={seekbarWasClicked}>
                            <div className={"progress-bar"} style={{width : ((progress / total)) * 100 + "%"}} />
                        </div>
                    </div>
                    <div className={"time"}>{totalTime}</div>
                </div>

            </div>
        </div>
    )

}