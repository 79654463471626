import Rosetta from "../../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {Column} from "../../../table/TableComponent";
import {Chronos} from "../../../../chronos/Chronos";
import {Toast} from "../../../toast/TokyoToaster";
import {AvailabilityWorkerBookingModal} from "./AvailabilityWorkerBookingModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {EventUtil} from "../../../../util/EventUtil";

export const AvailabilityBedDetailScreen = () => {

    const TABLE_OCCUPIED = "availability.occupied";
    const TABLE_HISTORY = "availability.history";
    const TABLE_UPCOMING = "availability.upcoming";

    const {id} = useParams();

    const [bed, setBed] = useState(null);
    const [bedPage, setBedPage] = useState(1);
    const [historyPage, setHistoryPage] = useState(1);
    const [upcomingPage, setUpcomingPage] = useState(1);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [occupancyCount, setOccupancyCount] = useState(null);

    const [bookingModalShown, setBookingModalShown] = useState(false);
    const [bookingModalId, setBookingModalId] = useState(undefined);

    const [oouNetworkInFlight, setOouNetworkInFlight] = useState(false);

    const [expireNetworkInFlight, setExpireNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchBedDetailFromNetwork();
    }, []);

    function tableDidCallback(table, action, data) {
        if (action === AutoTableActions.ROW_CLICKED) {
            switch (table) {
                case TABLE_OCCUPIED:
                case TABLE_HISTORY:
                case TABLE_UPCOMING:
                    showBookingEditor(data.id);
                    break;
                default: console.log("??");
            }
        } else if (action === AutoTableActions.PAGE_CHANGED) {
            switch (table) {
                case TABLE_OCCUPIED: setBedPage(data); break;
                case TABLE_HISTORY: setHistoryPage(data); break;
                case TABLE_UPCOMING: setUpcomingPage(data); break;
                default: console.log("?");
            }
        } else if (action === AutoTableActions.DATA_CHANGED) {
            if (table === TABLE_OCCUPIED) {
                setOccupancyCount(data.count);
            }
        } else if (action === AutoTableActions.ERROR) {
            Toast.show(
                Rosetta.string("common.error"),
                data,
                Toast.ERROR,
                Toast.LONG
            );
        }
    }

    function showBookingEditor(id) {
        setBookingModalId(id);
        setBookingModalShown(true);
    }

    function bookingModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setBookingModalShown(false);
        }
    }

    function formatTableDate(data) {
        let out = "---";
        try {
            out = Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }

        return out;
    }

    function promptBedOutOfUse() {
        let title = "out_of_use_prompt_title";
        let message = "out_of_use_prompt_message";
        let buttonClass = "danger";
        let value = 1;
        if (bed && parseInt(bed.outOfUse) === 1) {
            title = "out_of_use_in_use_title";
            message = "out_of_use_in_use_message";
            buttonClass = "success";
            value = 0;
        }

        AlertModal.showModal(
            Rosetta.string("bed_detail." + title),
            Rosetta.string("bed_detail." + message),
            [
                AlertModal.button(
                    Rosetta.string("bed_detail." + title),
                    () => {
                        setBedOutOfUseOverNetwork(value);
                        AlertModal.dismissModal();
                    },
                    buttonClass
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        )
    }

    function promptExpireOccupancy(id) {
        AlertModal.showModal(
            Rosetta.string("bed_detail.occupancy_remove_title"),
            Rosetta.string("bed_detail.occupancy_remove_message"),
            [
                AlertModal.button(
                    Rosetta.string("bed_detail.occupancy_remove_title"),
                    () => {
                        expireOccupancyOverNetwork(id);
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchBedDetailFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const data = {
            id
        };

        Axios.post(ENDPOINTS.availability.getBedDetail, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setBed(resp.data.bed);
                } else {
                    AlertModal.showError(
                        Rosetta.string("common.error"),
                        API.formatError(resp)
                    );
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showModal(
                    Rosetta.string("common.error"),
                    API.defaultError("BD1000C")
                );
            })
    }

    function setBedOutOfUseOverNetwork(outOfUse) {
        if (oouNetworkInFlight) return;
        setOouNetworkInFlight(true);

        let data = {
            unitBedroomBedId : id,
            outOfUse
        };

        Axios.post(ENDPOINTS.availability.setBedOutOfUse, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setBed(resp.data.bed);

                    let message = "out_of_use_success_out";
                    if (parseInt(resp.data.bed.outOfUse) === 0) {
                        message = "out_of_use_success_in";
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("bed_detail." + message),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setOouNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setOouNetworkInFlight(false);
                AlertModal.showError(API.defaultError("BOUS1000C"));
            })
    }

    function expireOccupancyOverNetwork(occupancyId) {
        if (expireNetworkInFlight) return;
        setExpireNetworkInFlight(true);

        let data = {
            unitBedroomBedId : id,
            unitBedroomBedOccupancyId : occupancyId
        };

        Axios.post(ENDPOINTS.availability.removeBedOccupancy, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("bed_detail.occupancy_remove_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExpireNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setExpireNetworkInFlight(false);
                AlertModal.showError(API.defaultError("BOE1000C"));
            })
    }

    // Render

    const showContent = !networkInFlight && bed;

    // <Column name={"preferredLanguage"} title={Rosetta.string("bed_detail.occupied_table_language")} />,

    const commonTableColumns = [
        <Column name={"firstName"} title={Rosetta.string("bed_detail.occupied_table_first_name")} />,
        <Column name={"lastName"} title={Rosetta.string("bed_detail.occupied_table_last_name")} />,
        <Column name={"gender"} title={Rosetta.string("bed_detail.occupied_table_gender")} />,
        <Column name={"age"} title={Rosetta.string("bed_detail.occupied_table_age")} />,
        <Column name={"nationality"} title={Rosetta.string("bed_detail.occupied_table_nationality")} />,
        <Column name={"source"} title={Rosetta.string("bed_detail.occupied_table_source")} />,
        <Column name={"startDate"} className={"text-center"} title={Rosetta.string("bed_detail.occupied_table_start_date")} render={(data, row) => {
            return formatTableDate(data);
        }} />,
        <Column name={"endDate"} className={"text-center"} title={Rosetta.string("bed_detail.occupied_table_end_date")} render={(data, row) => {
            return formatTableDate(data);
        }} />
    ];

    let occupancyLabel = [];
    if (showContent && occupancyCount !== null) {
        occupancyLabel = (
            <>({occupancyCount}/{bed.capacity})</>
        )
    }

    let oouButton = [];
    let bookingButton = (<span className={"btn btn-primary"} onClick={() => showBookingEditor()}>{Rosetta.string("bed_detail.action_book_worker")}</span>);
    let bedOccupancyStatus = "---";
    if (showContent && bed !== null) {
        if (bed ? parseInt(bed.outOfUse) === 0 : false) {
            oouButton = (
                <span className={"btn btn-danger me-1"} onClick={() => promptBedOutOfUse()}>{Rosetta.string("bed_detail.action_out_of_use")}</span>
            )
            bedOccupancyStatus = Rosetta.string(parseInt(bed.occupation) >= bed.capacity ? "bed_detail.bed_status_occupied" : "bed_detail.bed_status_unoccupied")
        } else {
            oouButton = (
                <span className={"btn btn-success me-1"} onClick={() => promptBedOutOfUse()}>{Rosetta.string("bed_detail.action_in_use")}</span>
            )
            bookingButton = [];
            bedOccupancyStatus = Rosetta.string("bed_detail.bed_detail_out_of_use")
        }
    }

    return (
        <div className={"content availability-bed-detail-screen"}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <h1>{Rosetta.string("bed_detail.title")}</h1>
                                </div>

                                <div className={"col-12 col-md-6 text-end"}>
                                    {oouButton}
                                    {bookingButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"animate-screen-content"}>
                    <div className={"row"}>
                        <div className={"col-12"}>

                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-md-6 col-lg-4 mb-2"}>
                                            <label>{Rosetta.string("bed_detail.bed_site")}</label>
                                            <span className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    { bed ? bed.siteName : "" }
                                                </ContentOrPlaceholder>
                                            </span>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4 mb-2"}>
                                            <label>{Rosetta.string("bed_detail.bed_unit")}</label>
                                            <span className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    { bed ? bed.unitName : "" }
                                                </ContentOrPlaceholder>
                                            </span>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4 mb-2"}>
                                            <label>{Rosetta.string("bed_detail.bed_bedroom")}</label>
                                            <span className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    { bed ? bed.unitBedroomName : "" }
                                                </ContentOrPlaceholder>
                                            </span>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4 mb-2"}>
                                            <label>{Rosetta.string("bed_detail.bed_name")}</label>
                                            <span className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    { bed ? bed.name : "" }
                                                </ContentOrPlaceholder>
                                            </span>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6 col-lg-4 mb-2"}>
                                            <label>{Rosetta.string("bed_detail.bed_status")}</label>
                                            <span className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    { bedOccupancyStatus }
                                                </ContentOrPlaceholder>
                                            </span>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4 mb-2"}>
                                            <label>{Rosetta.string("bed_detail.bed_type")}</label>
                                            <span className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    { bed ? bed.bedTypeName : "" }
                                                </ContentOrPlaceholder>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h5>{Rosetta.string("bed_detail.occupation")} {occupancyLabel}</h5>
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <AutoTable
                                            url={ENDPOINTS.availability.getBedOccupation}
                                            data={{
                                                bedIds : JSON.stringify([id]),
                                                dateMode : "between",
                                                startDate : Chronos.now().seconds(),
                                                endDate : Chronos.now().seconds()
                                            }}
                                            emptyMessage={Rosetta.string("bed_detail.occupied_table_empty")}
                                            page={bedPage}
                                            callback={(action, data) => tableDidCallback(TABLE_OCCUPIED, action, data)}>

                                            {commonTableColumns}

                                            <Column name={"id"} render={(data, row) => {
                                                let extraClass = "";
                                                let onClick = (e) => {
                                                    EventUtil.cancel(e);
                                                    promptExpireOccupancy(data);
                                                };
                                                if (expireNetworkInFlight) {
                                                    extraClass = " disabled";
                                                    onClick = null;
                                                }
                                                return (
                                                    <button className={"btn btn-danger" + extraClass} onClick={onClick}>
                                                        {Rosetta.string("common.remove")}
                                                    </button>
                                                );
                                            }} />

                                        </AutoTable>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h5>{Rosetta.string("bed_detail.history")}</h5>
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <AutoTable
                                            url={ENDPOINTS.availability.getBedOccupation}
                                            data={{
                                                bedIds : JSON.stringify([id]),
                                                dateMode : "standard",
                                                endDate : Chronos.now().seconds()
                                            }}
                                            emptyMessage={Rosetta.string("bed_detail.history_table_empty")}
                                            page={historyPage}
                                            callback={(action, data) => tableDidCallback(TABLE_HISTORY, action, data)}>

                                            {commonTableColumns}

                                        </AutoTable>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h5>{Rosetta.string("bed_detail.upcoming")}</h5>
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <AutoTable
                                            url={ENDPOINTS.availability.getBedOccupation}
                                            data={{
                                                bedIds : JSON.stringify([id]),
                                                dateMode : "standard",
                                                startDate : Chronos.now().endOfDay().seconds()
                                            }}
                                            emptyMessage={Rosetta.string("bed_detail.upcoming_table_empty")}
                                            page={upcomingPage}
                                            callback={(action, data) => tableDidCallback(TABLE_UPCOMING, action, data)}>

                                            {commonTableColumns}

                                        </AutoTable>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <AvailabilityWorkerBookingModal
                shown={bookingModalShown}
                id={bookingModalId}
                unitBedroomBedId={id}
                callback={bookingModalDidCallback} />
        </div>
    )

}