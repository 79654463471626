import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Navigator} from "../../../../util/Navigator";
import Rosetta from "../../../../rosetta/Rosetta";
import {SegmentTab, SegmentTabBar, SegmentTabBarActions} from "../../../segmenttab/SegmentTabBar";
import {FilingInspectionsComponent} from "./FilingInspectionsComponent";
import {FilingFilesComponent} from "./FilingFilesComponent";

export const FilingLandingScreen = (props) => {

    const TAB_INSPECTIONS = 1;
    const TAB_FILES = 2;

    const URI_INSPECTIONS = "inspections";
    const URI_FILES = "files";

    const [selectedTab, setSelectedTab] = useState(TAB_INSPECTIONS);

    const location = useLocation();

    useEffect(() => {
        handleTabChangeFromUri();
    }, []);

    useEffect(() => {
        handleTabChangeFromUri();
    }, [location]);

    useEffect(() => {
        Navigator.setHashParams({
            t: getUriFromTab(selectedTab)
        })
    }, [selectedTab]);

    function handleTabChangeFromUri() {
        const tabValue = Navigator.getHashParam("t");
        if (tabValue) {
            setSelectedTab(getTabFromUri(tabValue));
        }
    }

    function getTabFromUri(tabValue) {
        switch (tabValue) {
            case URI_INSPECTIONS: return TAB_INSPECTIONS;
            case URI_FILES: return TAB_FILES;
        }
        return null;
    }

    function getUriFromTab(value) {
        switch (value) {
            case TAB_INSPECTIONS: return URI_INSPECTIONS;
            case TAB_FILES: return URI_FILES;
        }
        return null;
    }

    let mainContent = [];
    if (selectedTab === TAB_INSPECTIONS) {
        mainContent = (<FilingInspectionsComponent />);
    } else if (selectedTab === TAB_FILES) {
        mainContent = (<FilingFilesComponent />);
    }

    function tabBarDidCallback(action, data) {
        if (action === SegmentTabBarActions.SELECT) {
            setSelectedTab(data);
        }
    }

    return (
        <div className={"filing-landing-screen"}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("filing.title")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <SegmentTabBar
                            className={"hops-segment-tab-bar"}
                            selected={selectedTab}
                            callback={tabBarDidCallback}>

                            <SegmentTab id={TAB_INSPECTIONS} label={Rosetta.string("filing.tab_inspection")} />
                            <SegmentTab id={TAB_FILES} label={Rosetta.string("filing.tab_files")} />

                        </SegmentTabBar>
                    </div>
                </div>

                {mainContent}
            </div>
        </div>
    )

}