import {useEffect, useState} from "react";
import {CommonUtil} from "../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {Toast} from "../toast/TokyoToaster";
import Rosetta from "../../rosetta/Rosetta";
import {LoadingSpinner} from "../loading/LoadingSpinner";

export const UserSelectionActions = {
   SELECT : "userselection.select"
};

export const UserSelectionList = (props) => {

    const {callback} = props;
    const {data} = props;
    const {selection} = props;
    const {className} = props;

    const [users, setUsers] = useState();
    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [page, setPage] = useState(undefined);

    useEffect(() => {
        fetchUsersFromNetwork();
    }, []);

    useEffect(() => {
        fetchUsersFromNetwork();
    }, [data]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function isSelected(user) {
        if (selection) {
            let userId = parseInt(user.id);

            if (Array.isArray(selection)) {
                for (const sel of selection) {
                    let selectionUserId = sel;
                    if (CommonUtil.isObject(sel)) {
                        selectionUserId = sel.id;
                    }

                    if (parseInt(selectionUserId) === userId) {
                        return true;
                    }
                }
            } else {
                let selectionUserId = selection;
                if (CommonUtil.isObject(selection)) {
                    selectionUserId = selection.id;
                }

                return parseInt(selectionUserId) === userId;
            }
        }
        return false;
    }

    function fetchUsersFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const postData = CommonUtil.mergeObjects(data, {
            page
        });

        Axios.post(ENDPOINTS.user.getUsers, postData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setUsers(resp.data.data);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("US1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    let userElems = [];
    if (!networkInFlight && users && users.length > 0) {
        userElems = users.map((user) => {
            let classExtra = "";
            let badgeElem = [];
            if (isSelected(user)) {
                classExtra = " active";
                badgeElem = (
                    <span className="badge bg-dark rounded-pill">{Rosetta.string("common.selected")}</span>
                )
            }

            return (
                <li className={"list-group-item list-group-item-action d-flex justify-content-between align-items-center clickable " + classExtra} onClick={() => handleCallback(UserSelectionActions.SELECT, user)}>
                    <div className={"ms-2 me-auto"}>
                        <div className={"fw-bold"}>{Rosetta.string("common.name_format", {given_name : user.firstName, family_name : user.lastName})}</div>
                        <div>{user.roleName}</div>
                    </div>
                    {badgeElem}
                </li>
            )
        })
    } else if (networkInFlight) {
        userElems = (
            <div className={"text-center"}>
                <LoadingSpinner />
            </div>
        )
    }

    return (
        <div className={"user-selection-list " + className}>
            <ul className={"list-group user-list"}>
                {userElems}
            </ul>
        </div>
    );

}