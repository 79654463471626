import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {FilterText} from "../../../filter/FilterText";
import {CommonUtil} from "../../../../util/CommonUtil";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {API, ENDPOINTS} from "../../../../network/API";
import {Column} from "../../../table/TableComponent";
import {StringUtil} from "../../../../util/StringUtil";
import {Chronos} from "../../../../chronos/Chronos";
import Axios from "axios";
import {Navigator} from "../../../../util/Navigator";
import {NavLink} from "react-router-dom";
import {CollapsableFilterComponent} from "../../../common/CollapsableFilterComponent";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Toast} from "../../../toast/TokyoToaster";

export const WorkerReportLandingScreen = (props) => {

    const [page, setPage] = useState(1);

    const [filterKeyword, setFilterKeyword] = useState("");
    const [filterSiteId, setFilterSiteId] = useState(null);
    const [filterUnitId, setFilterUnitId] = useState(null);
    const [filterStatusId, setFilterStatusId] = useState(-1);
    const [filterUserId, setFilterUserId] = useState(null);
    const [filterDate, setFilterDate] = useState(null);
    const [filterCompleteDate, setFilterCompleteDate] = useState(null);
    const [filterCompletedDate, setFilterCompletedDate] = useState(null);

    const [filterData, setFilterData] = useState(null);
    const [filterNetworkInFlight, setFilterNetworkInFlight] = useState(false);

    const [filterPayload, setFilterPayload] = useState(null);

    const [showExport, setShowExport] = useState(false);
    const [exportNetworkInFlight, setExportNetworkInFlight] = useState(false);

    useEffect(() => {
        createFilterPayload();
        fetchFilterDataFromNetwork();
    }, []);

    useEffect(() => {
        createFilterPayload();
    }, [
        page,
        filterKeyword, filterSiteId, filterUnitId, filterStatusId,
        filterUserId, filterDate, filterCompleteDate, filterCompletedDate
    ]);

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, [
        filterSiteId
    ]);

    function createFilterPayload(setValue) {
        if (setValue === undefined) {
            setValue = true;
        }

        const properties = [
            { key : "keyword", value : filterKeyword },
            { key : "siteIds", value : filterSiteId, isArray : true },
            { key : "unitIds", value : filterUnitId, isArray : true },
            { key : "userIds", value : filterUserId, isArray: true },
            { key : "statusIds", value : filterStatusId, isArray: true, allowMinusOne : true },
            { key : "createdDate", value : filterDate, isDate : true },
            { key : "completeDate", value : filterCompleteDate, isDate: true },
            { key : "completedDate", value : filterCompletedDate, isDate: true }
        ];

        const payload = CommonUtil.generateFilterPayload(properties);

        payload.page = page;

        if (setValue) {
            setFilterPayload(payload);
        }

        return payload;
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data);
        } else if (action === AutoTableActions.DATA_CHANGED) {
            setShowExport(true);
        } else if (action === AutoTableActions.ROW_CLICKED) {
            moveToDetail(data);
        }
    }

    function formatTableDate(date) {
        try {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return "---";
    }

    function moveToDetail(report) {
        if (report) {
            Navigator.navigate("/report/" + report.id);
        }
    }

    function fetchFilterDataFromNetwork() {
        if (filterNetworkInFlight) return;
        setFilterNetworkInFlight(true);

        let data = {};

        if (filterSiteId) {
            data.siteIds = JSON.stringify([filterSiteId]);
        }

        Axios.post(ENDPOINTS.workerReport.getReportFilterData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFilterData(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setFilterNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterNetworkInFlight(false);
            });
    }

    function fetchExportFromNetwork() {
        if (exportNetworkInFlight) return;
        setExportNetworkInFlight(true);

        Axios.post(ENDPOINTS.workerReport.exportWorkerReports, filterPayload)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    window.location.href = resp.data.downloadUri;

                    Toast.show(
                        Rosetta.string("user_reports.report_export_title"),
                        Rosetta.string("user_reports.report_export_message"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("WRE1000C"));
                setExportNetworkInFlight(false);
            });
    }

    // RENDER

    let exportButton = [];
    if (showExport) {
        if (exportNetworkInFlight) {
            exportButton = (
                <span className={"btn btn-success disabled mt-3 me-2"}>{Rosetta.string("common.exporting")}</span>
            )
        } else {
            exportButton = (
                <span className={"btn btn-success mt-3 me-2"} onClick={() => fetchExportFromNetwork()}>{Rosetta.string("common.export")}</span>
            );
        }
    }

    return (
        <div className={"worker-report-landing-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("user_reports.title_reports")}</h1>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 text-end"}>
                        {exportButton}

                        <NavLink to={"/task/create"} className={"btn btn-primary mt-3"}>{Rosetta.string("user_reports.action_new")}</NavLink>
                    </div>
                </div>

                <div className={"animate-screen-content"}>
                    <div className={"row"}>

                        <div className={"col-12"}>
                            <CollapsableFilterComponent>
                                <div className={"row justify-content-center"}>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        <label>{Rosetta.string("user_reports.filter_search")}</label>
                                        <FilterText callback={(s) => setFilterKeyword(s)} />
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        {CommonUtil.generateFilterSelect(Rosetta.string("user_reports.filter_site"), CommonUtil.getOrDefault(filterData, "sites", []), filterSiteId, (e) => setFilterSiteId(e.target.value))}
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        {CommonUtil.generateFilterSelect(Rosetta.string("user_reports.filter_unit"), CommonUtil.getOrDefault(filterData, "units", []), filterUnitId, (e) => setFilterUnitId(e.target.value))}
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        {CommonUtil.generateFilterSelect(Rosetta.string("user_reports.filter_status"), CommonUtil.getOrDefault(filterData, "statuses", []), filterStatusId, (e) => setFilterStatusId(e.target.value), undefined, undefined, undefined, Rosetta.string("common.default"))}
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        {CommonUtil.generateFilterSelect(Rosetta.string("user_reports.filter_reported_by"), CommonUtil.getOrDefault(filterData, "users", []), filterUserId, (e) => setFilterUserId(e.target.value))}
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        <label>{Rosetta.string("user_reports.filter_date_raised")}</label>
                                        <input type={"date"} className={"form-control"} value={filterDate} onChange={(e) => setFilterDate(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        <label>{Rosetta.string("user_reports.filter_date_complete")}</label>
                                        <input type={"date"} className={"form-control"} value={filterCompleteDate} onChange={(e) => setFilterCompleteDate(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4"}>
                                        <label>{Rosetta.string("user_reports.filter_date_completed")}</label>
                                        <input type={"date"} className={"form-control"} value={filterCompletedDate} onChange={(e) => setFilterCompletedDate(e.target.value)} />
                                    </div>

                                </div>
                            </CollapsableFilterComponent>

                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row mt-4"}>
                                        <div className={"col-12"}>

                                            <AutoTable
                                                url={ENDPOINTS.workerReport.getWorkerReports}
                                                data={filterPayload ? filterPayload : createFilterPayload(false)}
                                                page={page}
                                                pageSize={20}
                                                callback={tableDidCallback}>

                                                <Column name={"description"} title={Rosetta.string("user_reports.table_title")} render={(data, row) => {
                                                    return StringUtil.ellipsise(data, 24);
                                                }} />
                                                <Column name={"workerReportStatus"} title={Rosetta.string("user_reports.table_status")} />
                                                <Column name={"siteName"} title={Rosetta.string("user_reports.table_site")} />
                                                <Column name={"unitName"} title={Rosetta.string("user_reports.table_unit")} />
                                                <Column name={"firstName"} title={Rosetta.string("user_reports.table_reported_by")} render={(data, row) => {
                                                    return Rosetta.string("common.name_format", { given_name : row.firstName, family_name : row.lastName })
                                                }} />
                                                <Column name={"dateCreated"} title={Rosetta.string("user_reports.table_date_raised")} render={(data, row) => {
                                                    return formatTableDate(data);
                                                }} />
                                                <Column name={"requiredDate"} title={Rosetta.string("user_reports.table_date_complete")} render={(data, row) => {
                                                    return formatTableDate(data);
                                                }} />
                                                <Column name={"completionDate"} title={Rosetta.string("user_reports.table_date_completed")} render={(data, row) => {
                                                    return formatTableDate(data);
                                                }} />

                                            </AutoTable>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )

}