import {useState} from "react";
import {CommonUtil} from "../../util/CommonUtil";
import Rosetta from "../../rosetta/Rosetta";

import "./CollapsableFilterComponent.css";

export const CollapsableFilterComponent = (props) => {

    const [collapsed, setCollapsed] = useState(CommonUtil.getOrDefault(props, "collapsed", true));

    function toggleCollapse() {
        setCollapsed(!collapsed);
    }

    let content = [];
    if (!collapsed) {
        content = (
            <div className={"card"}>
                <div className={"card-body collapsable-area"}>
                    {props.children}
                </div>
            </div>
        );
    }

    return (
        <div className={"collapsable-filter-component"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <button className={"btn btn-outline-dark"} onClick={toggleCollapse}>{ Rosetta.string(collapsed ? "common.filter_show" : "common.filter_hide") }</button>
                </div>
                <div className={"col-12 mt-2"}>
                    {content}
                </div>
            </div>
        </div>
    )

}