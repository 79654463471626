import {Button, Container, DropDown, Former, FormerActions, Text} from "../../../form/Former";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {Toast} from "../../../toast/TokyoToaster";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";

export const WorkerReportEntryBasicScreen = (props) => {

    const {callback} = props;

    const [sites, setSites] = useState([])
    const [units, setUnits] = useState([])
    const [bedrooms, setBedrooms] = useState([])
    const [beds, setBeds] = useState([])
    const [inventoryItems, setInventoryItems] = useState([]);

    const [filterDataNetworkInFlight, setFilterDataNetworkInFlight] = useState(false);

    const [transferData, setTransferData] = useState(null);

    const [filterSelections, setFilterSelections] = useState({});

    const imageInput = useRef(null);

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, []);

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, [filterSelections]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                setTransferData(data.data);
                imageInput.current.click();
            }
        } else if (action === FormerActions.CHANGE) {
            const fd = CommonUtil.cloneObject(filterSelections);
            fd[data.name] = data.value;
            setFilterSelections(fd);
        }
    }

    function imageInputDidChange(e) {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            let reader = new FileReader();
            reader.onload = () => {
                const td = CommonUtil.cloneObject(transferData);
                td.images = [{
                    file,
                    data : reader.result
                }];
                handleCallback("submit", td);
            };
            reader.readAsDataURL(file);
        }
    }

    function fetchFilterDataFromNetwork() {
        if (filterDataNetworkInFlight) return;
        setFilterDataNetworkInFlight(true);

        Axios.post(ENDPOINTS.site.getSiteFilterData, filterSelections)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSites(resp.data.sites);
                    setUnits(resp.data.units);
                    setBedrooms(resp.data.bedrooms);
                    setBeds(resp.data.beds);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setFilterDataNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterDataNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("FDF1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    // RENDER

    return (
        <div className={"worker-report-entry-basic-screen"}>
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>

                    <div className={"row mb-4"}>
                        <div className={"col-12"}>
                            <h3>{Rosetta.string("worker_report.step_1_title")}</h3>
                        </div>
                    </div>

                    <Former callback={formDidCallback}>
                        <Container className={"row"}>
                            <Container className={"col-12"}>

                                <Text name={"title"} className={"form-control"} label={Rosetta.string("worker_report.step_1_report_title")} mandatory={true} />

                            </Container>
                        </Container>

                        <Container className={"row mt-2"}>
                            <Container className={"col-12"}>

                                <DropDown name={"siteId"} className={"form-select"} label={Rosetta.string("worker_report.step_1_site")} mandatory={true}>
                                    {sites.map((site) => (
                                        <option value={site.id}>{site.name}</option>
                                    ))}
                                </DropDown>

                            </Container>
                        </Container>

                        <Container className={"row mt-2"}>
                            <Container className={"col-12"}>

                                <DropDown name={"unitId"} className={"form-select"} label={Rosetta.string("worker_report.step_1_unit")} mandatory={true}>
                                    {units.map((unit) => (
                                        <option value={unit.id}>{unit.name}</option>
                                    ))}
                                </DropDown>

                            </Container>
                        </Container>

                        <Container className={"row mt-2"}>
                            <Container className={"col-12"}>

                                <DropDown name={"bedroomId"} className={"form-select"} label={Rosetta.string("worker_report.step_1_bedroom")}>
                                    {bedrooms.map((bedroom) => (
                                        <option value={bedroom.id}>{bedroom.name}</option>
                                    ))}
                                </DropDown>

                            </Container>
                        </Container>

                        <Container className={"row mt-2"}>
                            <Container className={"col-12"}>

                                <DropDown name={"bedId"} className={"form-select"} label={Rosetta.string("worker_report.step_1_bedroom")}>
                                    {beds.map((bed) => (
                                        <option value={bed.id}>{bed.name}</option>
                                    ))}
                                </DropDown>

                            </Container>
                        </Container>

                        <Container className={"row mt-2"}>
                            <Container className={"col-12"}>

                                <DropDown name={"inventoryItemId"} className={"form-select"} label={Rosetta.string("worker_report.step_1_inventory")}>
                                    {inventoryItems.map((item) => (
                                        <option value={item.id}>{item.title}</option>
                                    ))}
                                </DropDown>

                            </Container>
                        </Container>

                        <Container className={"row mt-2"}>
                            <Container className={"col-12 text-center"}>
                                <Button className={"btn btn-primary"} title={Rosetta.string("worker_report.step_1_submit")} />
                            </Container>
                        </Container>

                    </Former>

                </div>
            </div>

            <div className={"file-hide"}>
                <input type={"file"} ref={imageInput} onChange={imageInputDidChange} />
            </div>
        </div>
    )

}