import "./HopsHeaderBar.css";

import {ImageUtil} from "../../util/ImageUtil";
import Rosetta from "../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import {Armstrong} from "../../util/Armstrong";

import iconBack from "../../assets/images/arrow_left.svg";
import iconMenu from "../../assets/images/menu.svg";
import iconHelp from "../../assets/images/help.svg";
import iconAccount from "../../assets/images/account.svg";

export const HopsHeaderBarStyle = {
    COMPACT : 1,
    NORMAL : 2
}

export const HopsHeaderBarActions = {
    MENU_OPEN : "headerbar.menuOpen",
    HELP_CLICKED : "headerbar.helpClicked",
    ACCOUNT_CLICKED : "headerbar.accountClicked"
}

export const HopsHeaderBar = (props) => {

    const {style} = props;
    const {showBack} = props;
    const {callback} = props;

    const [screenSize, setScreenSize] = useState(0);

    const screenListener = useRef();

    useEffect(() => {
        screenListener.current = Armstrong.addCallback((size) => {
            setScreenSize(size);
        });

        return () => {
            Armstrong.removeCallback(screenListener.current);
        }
    }, []);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    let containerClass = "container";

    let backButtonIcon = null;
    let backButtonClass = "";
    if (showBack) {
        backButtonIcon = iconBack;
        backButtonClass = " clickable";
    }

    let menuAction = (<div className={"menu-action"} />);
    if (screenSize < Armstrong.breakpoints.medium) {
        menuAction = (
            <div className={"menu-action clickable"} onClick={() => handleCallback(HopsHeaderBarActions.MENU_OPEN)} style={{backgroundImage : ImageUtil.background(iconMenu)}}>
                <span className={"badge bg-danger"}>9</span>
            </div>
        );
    }

    let headerContent = [];
    if (style === HopsHeaderBarStyle.COMPACT) {
        headerContent = (
            <>
                <div className={"menu-action" + backButtonClass} style={{backgroundImage : ImageUtil.background(backButtonIcon)}} />
                <div className={"spacer"} />
                <div className={"header-title"}>{Rosetta.string("common.app_name")}</div>
                <div className={"spacer"} />
                {menuAction}
            </>
        )
    } else {
        // TODO Extended header
        containerClass = "container-fluid";

        headerContent = (
            <>
                <div className={"header-title"}>{Rosetta.string("common.app_name")}</div>
                {menuAction}
                <div className={"spacer"} />
                <div className={"menu-action clickable"} style={{backgroundImage : ImageUtil.background(iconHelp)}} onClick={() => handleCallback(HopsHeaderBarActions.HELP_CLICKED)} />
                <div className={"menu-action clickable"} style={{backgroundImage : ImageUtil.background(iconAccount)}} onClick={() => handleCallback(HopsHeaderBarActions.ACCOUNT_CLICKED)} />
            </>
        )
    }

    return (
        <div className={"hops-header-bar"}>
            <div className={containerClass}>
                <div className={"header-content-container"}>
                    {headerContent}
                </div>
            </div>
        </div>
    );

}