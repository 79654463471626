export const UrlUtil = {
    getLastPathPart : (url) => {
        if (url) {
            let urlParts = url.split("/");
            if (urlParts.length > 0) {
                return urlParts[urlParts.length - 1];
            }
        }
        return url;
    },
    getQueryParameters : (uri) =>  {
        let out = {};

        let uriSplit = uri.split("?");
        if (uriSplit.length > 1) {
            let paramSplit = uriSplit[1].split("&");

            for (let pair of paramSplit) {
                let pairSplit = pair.split("=");
                if (pairSplit.length > 1) {
                    out[pairSplit[0]] = decodeURI(pairSplit[1]);
                }
            }
        }

        return out;
    },
    isFullyQualifiedUrl : (url) => {
        return url.includes("http://") || url.includes("https://");
    }
}