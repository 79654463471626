import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/adminlte/css/adminlte.min.css";
import "./assets/adminlte/css/alt/adminlte.components.min.css";
import "./assets/adminlte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
import "./assets/adminlte/plugins/fontawesome-free/css/all.min.css";
import './App.css';
import {MainComponent} from "./components/MainComponent";

function App() {
  return (
      <MainComponent />
  );
}

export default App;
