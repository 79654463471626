import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {Toast} from "../../../toast/TokyoToaster";

import "./AdminDashboardScreen.css";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {Column} from "../../../table/TableComponent";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {CommonUtil} from "../../../../util/CommonUtil";
import {Chronos} from "../../../../chronos/Chronos";
import {BookingListModes} from "../booking/BookingListScreen";
import {Navigator} from "../../../../util/Navigator";
import {FilingFileUploadModal} from "../filing/FilingFileUploadModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";

import iconBed from "../../../../assets/images/icon_bed.svg";
import iconBedEmpty from "../../../../assets/images/bed_empty.svg";
import iconTaskList from "../../../../assets/images/icon_task_list.svg";

import {ImageUtil} from "../../../../util/ImageUtil";

export const AdminDashboardScreen = () => {

    const [summaryData, setSummaryData] = useState(null);
    const [summaryNetworkInFlight, setSummaryNetworkInFlight] = useState(false);
    const [summarySiteId, setSummarySiteId] = useState("-1");

    const [filterSiteId, setFilterSiteId] = useState(null);
    const [bedAvailabilityPage, setBedAvailabilityPage] = useState(1);
    const [bedAvailabilityPayload, setBedAvailabilityPayload] = useState({});

    const [documentPage, setDocumentPage] = useState(1);
    const [documentPayload, setDocumentPayload] = useState({
        expiryDateStart : Chronos.now().add(-1, Chronos.WEEKS).seconds(),
        expiryDateEnd : Chronos.now().add(60, Chronos.DAYS).seconds()
    });

    const [taskListPage, setTaskListPage] = useState(1);
    const [taskListPayload, setTaskListPayload] = useState({});
    const [taskListData, setTaskListData] = useState(null);

    const [occupantPage, setOccupantPage] = useState(1);
    const [occupantPayload, setOccupantPayload] = useState({
        mode : BookingListModes.MODE_NO_PLACEMENT
    });

    const [fileModalShown, setFileModalShown] = useState(false);
    const [fileModalId, setFileModalId] = useState(null);
    const [fileReupload, setFileReupload] = useState(false);

    useEffect(() => {
        fetchSiteSummaryFromNetwork();
    }, []);

    useEffect(() => {
        // Handle Bed Availability Site Filtering
        let payload = CommonUtil.cloneObject(bedAvailabilityPayload);
        if (parseInt(filterSiteId) !== -1) {
            payload.siteId = filterSiteId;
        } else {
            delete payload.siteId;
        }
        setBedAvailabilityPayload(payload);
    }, [filterSiteId]);

    function formatTableDate(date) {
        if (date) {
            try {
                return Chronos.withTimestampSeconds(date).format("dd/MM/yy");
            } catch (e) {
                console.log(e);
            }
        }
        return "";
    }

    function showFileModal(id, reupload) {
        if (reupload === undefined) {
            reupload = false;
        }

        setFileModalId(id);
        setFileReupload(reupload);
        setFileModalShown(true);
    }

    function fileModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setFileModalShown(false);
        }
    }

    function fetchSiteSummaryFromNetwork() {
        if (summaryNetworkInFlight) return;
        setSummaryNetworkInFlight(true);

        let data = {};

        Axios.post(ENDPOINTS.site.getSiteSummary, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSummaryData(resp.data.data);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setSummaryNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSummaryNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("SS1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    let summaryWidgets = [];
    let mainContent = (
        <div className={"row"}>
            <div className={"col-12"}>
                <LoadingSpinner />
            </div>
        </div>
    );

    if (summaryData) {
        let siteOptions = [];

        // summaryWidgets = summaryData.map((site) => {
        //     siteOptions.push(
        //         <option value={site.id}>{site.name}</option>
        //     )
        //
        //     let unitSummaries = [];
        //     if (site.hasOwnProperty("units") && site.units.length > 0) {
        //         unitSummaries = site.units.map((unit) => {
        //             let bedroomSummaries = [];
        //             if (unit.hasOwnProperty("bedrooms") && unit.bedrooms.length > 0) {
        //                 bedroomSummaries = unit.bedrooms.map((bedroom) => (
        //                     <div className={"site-summary-sub-sub-summary"}>
        //                         {Rosetta.string("admin_dashboard.bed_occupancy_sub_label", {name : bedroom.name, occupied : bedroom.occupiedBedCount, count : bedroom.bedCount})}
        //                     </div>
        //                 ))
        //             }
        //
        //             return (
        //                 <div className={"site-summary-sub-summary"}>
        //                     {Rosetta.string("admin_dashboard.bed_occupancy_sub_label", {name : unit.name, occupied : unit.occupiedBedCount, count : unit.bedCount})}
        //
        //                     {bedroomSummaries}
        //                 </div>
        //             )
        //         })
        //     }
        //
        //     let percentage = ((site.occupiedBedCount / site.bedCount) * 100).toFixed(0);
        //     if (isNaN(percentage)) {
        //         percentage = 0;
        //     }
        //
        //     return (
        //         <div className={"col-6 col-md-4 col-lg-3"}>
        //             <div className={"info-box bg-info"}>
        //                 <span className={"info-box-icon align-items-start"}>
        //                     <div className={"ratio ratio-1x1"} style={{backgroundImage : ImageUtil.background(iconBed)}} />
        //                 </span>
        //                 <div className={"info-box-content"}>
        //                     <span className={"info-box-text"}>{site.name}</span>
        //                     <span className={"info-box-number"}>{Rosetta.string("admin_dashboard.bed_occupancy_summary", { occupied : site.occupiedBedCount, count: site.bedCount, percentage })}</span>
        //                     <div className={"progress"}>
        //                         <div className={"progress-bar"} style={{width : percentage + "%"}} />
        //                     </div>
        //                     <span className={"progress-description contain-height"}>
        //                         {unitSummaries}
        //                     </span>
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // })

        let occupancyProgress = 0;
        let occupancyTotal = 0;

        let freeBeds = 0;
        let activeBeds = 0;

        summaryData.forEach((site) => {
            siteOptions.push(
                <option value={site.id}>{site.name}</option>
            )

            if (parseInt(summarySiteId) === parseInt(site.id) || summarySiteId === "-1") {
                occupancyProgress += parseInt(site.occupiedBedCount);
                occupancyTotal += parseInt(site.bedCount);

                freeBeds += (site.bedCount - site.occupiedBedCount);
                activeBeds += parseInt(site.occupiedBedCount);
            }
        })

        let occupancyPercent = Math.round((occupancyProgress / occupancyTotal) * 100);
        if (isNaN(occupancyPercent)) {
            occupancyPercent = 0;
        }

        let freePercent = Math.round((freeBeds / occupancyTotal) * 100);
        if (isNaN(freePercent)) {
            freePercent = 0;
        }

        let activePercent = Math.round((activeBeds / occupancyTotal) * 100);
        if (isNaN(activePercent)) {
            activePercent = 0;
        }

        let taskListItem = [];
        if (taskListData) {
            let taskListCount = 0;

            if (taskListData.hasOwnProperty("count")) {
                taskListCount = taskListData.count;
            }

            taskListItem = (
                <div className={"col-6 col-md-4 col-lg-3"}>
                    <div className={"info-box bg-success"}>
                        <span className={"info-box-icon align-items-start"}>
                            <div className={"ratio ratio-1x1"} style={{backgroundImage : ImageUtil.background(iconTaskList)}} />
                        </span>

                        <div className={"info-box-content"}>
                            <span className={"info-box-text"}>{Rosetta.string("admin_dashboard.summary_active_tasks")}</span>
                            <span className={"info-box-number"}>{taskListCount}</span>
                            <div className={"progress"}>
                                <div className={"progress-bar"} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        summaryWidgets = (
            <>
                <div className={"row"}>
                    <div className={"col-12 col-md-4 col-lg-3 mb-2"}>
                        <label>{Rosetta.string("admin_dashboard.summary_site")}</label>
                        <select className={"form-select"} value={summarySiteId} onChange={(e) => setSummarySiteId(e.target.value)}>
                            <option value={"-1"}>{Rosetta.string("admin_dashboard.filter_site_none")}</option>
                            {siteOptions}
                        </select>
                    </div>
                </div>

                <div className={"row"}>

                    <div className={"col-6 col-md-4 col-lg-3"}>
                        <div className={"info-box bg-info"}>
                            <span className={"info-box-icon align-items-start"}>
                                <div className={"ratio ratio-1x1"} style={{backgroundImage : ImageUtil.background(iconBed)}} />
                            </span>

                            <div className={"info-box-content"}>
                                <span className={"info-box-text"}>{Rosetta.string("admin_dashboard.summary_occupancy")}</span>
                                <span className={"info-box-number"}>{occupancyPercent}%</span>
                                <div className={"progress"}>
                                    <div className={"progress-bar"} style={{width : occupancyPercent + "%"}} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-6 col-md-4 col-lg-3"}>
                        <div className={"info-box bg-success"}>
                            <span className={"info-box-icon align-items-start"}>
                                <div className={"ratio ratio-1x1"} style={{backgroundImage : ImageUtil.background(iconBedEmpty)}} />
                            </span>

                            <div className={"info-box-content"}>
                                <span className={"info-box-text"}>{Rosetta.string("admin_dashboard.summary_free_beds")}</span>
                                <span className={"info-box-number"}>{freeBeds}</span>
                                <div className={"progress"}>
                                    <div className={"progress-bar"} style={{width : freePercent + "%"}} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {taskListItem}

                </div>

            </>
        )

        mainContent = (
            <>
                <div className={"row"}>

                    <div className={"col-12 col-xl-6"}>
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <h3>{Rosetta.string("admin_dashboard.unbooked_title")}</h3>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <AutoTable
                                    url={ENDPOINTS.booking.getBookingUsers}
                                    data={occupantPayload}
                                    page={occupantPage}
                                    pageSize={5}
                                    callback={(action, data) => {
                                        if (action === AutoTableActions.PAGE_CHANGED) {
                                            setOccupantPage(data);
                                        } else if (action === AutoTableActions.SORT_CHANGE) {
                                            const payload = CommonUtil.cloneObject(occupantPayload);
                                            payload.ordering = data;
                                            setOccupantPayload(payload);
                                        } else if (action === AutoTableActions.ROW_CLICKED) {
                                            Navigator.navigate("/placement/" + data.id);
                                        } else if (action === AutoTableActions.ERROR) {
                                            Toast.show(
                                                Rosetta.string("common.error"),
                                                data,
                                                Toast.ERROR,
                                                Toast.LONG
                                            );
                                        }
                                    }}>
                                    <Column name={"firstName"} title={Rosetta.string("admin_dashboard.unbooked_table_first_name")} sortable={false} />
                                    <Column name={"lastName"} title={Rosetta.string("admin_dashboard.unbooked_table_last_name")} sortable={false} />
                                    <Column name={"genderName"} title={Rosetta.string("admin_dashboard.unbooked_table_gender")} className={"text-center"} sortable={false} />
                                    <Column name={"age"} title={Rosetta.string("admin_dashboard.unbooked_table_age")} className={"text-center"} sortable={false} />
                                    <Column name={"nationality"} title={Rosetta.string("admin_dashboard.unbooked_table_nationality")} className={"text-center"} sortable={false} />
                                    <Column name={"startDate"} title={Rosetta.string("admin_dashboard.unbooked_table_date_start")} className={"text-center"} sortable={false} render={(data, row) => {
                                        return formatTableDate(data);
                                    }} />
                                    <Column name={"endDate"} title={Rosetta.string("admin_dashboard.unbooked_table_date_end")} className={"text-center"} sortable={false} render={(data, row) => {
                                        return formatTableDate(data);
                                    }} />
                                    <Column name={"id"} className={"text-end"} render={(data, row) => (
                                        <>
                                            <button className={"btn btn-primary"}>{Rosetta.string("common.view")}</button>
                                        </>
                                    )} />
                                </AutoTable>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-xl-6"}>
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <h3>{Rosetta.string("admin_dashboard.document_renewal")}</h3>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <AutoTable
                                    url={ENDPOINTS.siteDocument.getSiteDocuments}
                                    data={documentPayload}
                                    page={documentPage}
                                    pageSize={5}
                                    callback={(action, data) => {
                                        if (action === AutoTableActions.PAGE_CHANGED) {
                                            setDocumentPage(data);
                                        } else if (action === AutoTableActions.SORT_CHANGE) {
                                            const payload = CommonUtil.cloneObject(documentPayload);
                                            payload.ordering = data;
                                            setDocumentPayload(payload);
                                        } else if (action === AutoTableActions.ROW_CLICKED) {
                                            showFileModal(data.id);
                                        } else if (action === AutoTableActions.ERROR) {
                                            Toast.show(
                                                Rosetta.string("common.error"),
                                                data,
                                                Toast.ERROR,
                                                Toast.LONG
                                            );
                                        }
                                    }}>
                                    <Column name={"title"} title={Rosetta.string("admin_dashboard.document_table_name")} sortable={false} />
                                    <Column name={"siteName"} title={Rosetta.string("admin_dashboard.document_table_site")} sortable={false} />
                                    <Column name={"unitName"} title={Rosetta.string("admin_dashboard.document_table_unit")} sortable={false} />
                                    <Column name={"dateCreated"} title={Rosetta.string("admin_dashboard.document_table_date_added")} sortable={false} render={(data, row) => {
                                        return formatTableDate(data);
                                    }} />
                                    <Column name={"expiryDate"} title={Rosetta.string("admin_dashboard.document_table_date_expiry")} sortable={false} render={(data, row) => {
                                        return formatTableDate(data);
                                    }} />
                                    <Column name={"id"} className={"text-end"} render={(data, row) => (
                                        <>
                                            <button className={"btn btn-primary"}>{Rosetta.string("common.view")}</button>
                                        </>
                                    )} />
                                </AutoTable>
                            </div>
                        </div>
                    </div>

                </div>


                <div className={"row"}>
                    <div className={"col-12 col-md-12 col-lg-6"}>
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <h3>{Rosetta.string("admin_dashboard.task_list_title")}</h3>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <AutoTable
                                    url={ENDPOINTS.task.getDashboardTaskList}
                                    data={taskListPayload}
                                    page={taskListPage}
                                    pageSize={5}
                                    callback={(action, data) => {
                                        if (action === AutoTableActions.PAGE_CHANGED) {
                                            setTaskListPage(data);
                                        } else if (action === AutoTableActions.SORT_CHANGE) {
                                            const payload = CommonUtil.cloneObject(taskListPayload);
                                            payload.ordering = data;
                                            setTaskListPayload(payload);
                                        } else if (action === AutoTableActions.ROW_CLICKED) {
                                            if (parseInt(data.type) === 1) {
                                                Navigator.navigate("/inspection/" + data.id);
                                            } else {
                                                Navigator.navigate("/report/" + data.id);
                                            }
                                        } else if (action === AutoTableActions.ERROR) {
                                            Toast.show(
                                                Rosetta.string("common.error"),
                                                data,
                                                Toast.ERROR,
                                                Toast.LONG
                                            );
                                        } else if (action === AutoTableActions.DATA_CHANGED) {
                                            setTaskListData(data);
                                        }
                                    }}>
                                    <Column name={"unitName"} title={Rosetta.string("admin_dashboard.task_list_table_unit")} className={"text-center"} sortable={false} />
                                    <Column name={"description"} title={Rosetta.string("admin_dashboard.task_list_table_title")} sortable={false} />
                                    <Column name={"siteName"} title={Rosetta.string("admin_dashboard.task_list_table_site")} className={"text-center"} sortable={false} />
                                    <Column name={"dateCreated"} title={Rosetta.string("admin_dashboard.task_list_table_date_raised")} className={"text-center"} sortable={false} render={(data, row) => {
                                        return formatTableDate(data);
                                    }} />
                                    <Column name={"id"} className={"text-end"} render={(data, row) => (
                                        <>
                                            <button className={"btn btn-primary"}>{Rosetta.string("common.view")}</button>
                                        </>
                                    )} />
                                </AutoTable>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

    return (
        <div className={"admin-dashboard-screen"}>
            <div className={"content-header"}>
                <div className={"container-fluid"}>
                    <div className={"row mb-2"}>
                        <h1>{Rosetta.string("admin_dashboard.title")}</h1>
                    </div>
                </div>
            </div>


            <div className={"container-fluid pb-4"}>

                <div className={"row"}>
                    <div className={"col-12"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                {summaryWidgets}

                                {mainContent}

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <FilingFileUploadModal
                shown={fileModalShown}
                siteDocumentId={fileModalId}
                reupload={fileReupload}
                callback={fileModalDidCallback} />

        </div>

    )

}

// <Column name={"preferredLanguage"} title={Rosetta.string("admin_dashboard.unbooked_table_language")} className={"text-center"} sortable={false} />

/*
        <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("admin_dashboard.bed_availability_title")}</h3>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12 col-md-6 col-lg-3"}>
                        <label>{Rosetta.string("admin_dashboard.filter_site")}</label>
                        <select className={"form-select"} value={filterSiteId} onChange={(e) => setFilterSiteId(e.target.value)}>
                            <option value={-1}>{Rosetta.string("admin_dashboard.filter_site_none")}</option>
                            {siteOptions}
                        </select>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <AutoTable
                            url={ENDPOINTS.site.getSiteBedAvailability}
                            data={bedAvailabilityPayload}
                            page={bedAvailabilityPage}
                            pageSize={5}
                            callback={(action, data) => {
                                if (action === AutoTableActions.PAGE_CHANGED) {
                                    setBedAvailabilityPage(data);
                                } else if (action === AutoTableActions.SORT_CHANGE) {
                                    const payload = CommonUtil.cloneObject(bedAvailabilityPayload);
                                    payload.ordering = data;
                                    setBedAvailabilityPayload(payload);
                                } else if (action === AutoTableActions.ROW_CLICKED) {
                                    Navigator.navigate("/bed/" + data.id);
                                } else if (action === AutoTableActions.ERROR) {
                                    Toast.show(
                                        Rosetta.string("common.error"),
                                        data,
                                        Toast.ERROR,
                                        Toast.LONG
                                    );
                                }
                            }}>
                            <Column name={"unitName"} title={Rosetta.string("admin_dashboard.bed_availability_table_unit")} sortable={false} />
                            <Column name={"bedroomName"} title={Rosetta.string("admin_dashboard.bed_availability_table_bedroom")} sortable={false} />
                            <Column name={"name"} title={Rosetta.string("admin_dashboard.bed_availability_table_bed")} sortable={false} />
                            <Column name={"nextStartDate"} title={Rosetta.string("admin_dashboard.bed_availability_table_availability_date")} sortable={false} render={(data, row) => {
                                return formatTableDate(data);
                            }} />
                            <Column name={"id"} className={"text-end"} render={(data, row) => (
                                <>
                                    <button className={"btn btn-primary"}>{Rosetta.string("admin_dashboard.bed_availability_table_action_view")}</button>
                                </>
                            )} />
                        </AutoTable>
                    </div>
                </div>
         */

// <Column name={"type"} title={Rosetta.string("admin_dashboard.task_list_table_type")} className={"text-center"} sortable={false} render={(data, row) => {
//                                         let badgeLabel = "admin_dashboard.task_list_table_type_inspection";
//                                         if (parseInt(data) === 2) {
//                                             badgeLabel = "admin_dashboard.task_list_table_type_report";
//                                         }
//                                         return (<span className={"badge badge-secondary"}>{Rosetta.string(badgeLabel)}</span>);
//                                     }} />