import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import {useEffect, useRef, useState} from "react";
import Rosetta from "../../../../rosetta/Rosetta";
import {
    Button,
    Container,
    DateTime,
    DropDown,
    File,
    Former,
    FormerActions,
    FormerValues,
    Text
} from "../../../form/Former";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {CommonUtil} from "../../../../util/CommonUtil";
import {Chronos} from "../../../../chronos/Chronos";
import {Toast} from "../../../toast/TokyoToaster";
import WindowUtil from "../../../../util/WindowUtil";

export const FilingFileUploadModal = (props) => {

    const {shown} = props;
    const {reupload} = props;
    const {callback} = props;
    const {siteDocumentId} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    const [callbackData, setCallbackData] = useState(null);

    const [siteDocument, setSiteDocument] = useState(null);
    const [fileNetworkInFlight, setFileNetworkInFlight] = useState(false);

    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadNetworkInFlight, setUploadNetworkInFlight] = useState(false);

    const [formState, setFormState] = useState({});
    const [error, setError] = useState(null);

    const [optionsNetworkInFlight, setOptionsNetworkInFlight] = useState(false);
    const [sites, setSites] = useState([]);
    const [units, setUnits] = useState([]);
    const [siteId, setSiteId] = useState(undefined);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            fetchOptionsDataFromNetwork();
            if (siteDocumentId) {
                fetchSiteDocumentFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();

            setForceDismiss(false);
            setFormState({});
            setCallbackData(null);
            setSiteId(undefined);
            setError(null);
            setUploadProgress(0);
            setFileNetworkInFlight(false);
            setOptionsNetworkInFlight(false);
            setUploadNetworkInFlight(false);
        }
    }, [shown]);

    useEffect(() => {
        fetchOptionsDataFromNetwork();
    }, [siteId]);

    useEffect(() => {
        if (siteDocument) {
            const formState = {
                title : CommonUtil.getOrDefault(siteDocument, "title", undefined),
                description : CommonUtil.getOrDefault(siteDocument, "description", null),
                siteId : CommonUtil.getOrDefault(siteDocument, "siteId", undefined),
                unitId : CommonUtil.getOrDefault(siteDocument, "unitId", undefined)
            };

            if (siteDocument.expiryDate) {
                try {
                    formState.expiryDate = Chronos.withTimestampSeconds(siteDocument.expiryDate).format("yyyy-MM-dd");
                } catch (e) {
                    console.log(e);
                }
            }

            setFormState(formState);
        }
    }, [siteDocument]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                uploadFileOverNetwork(data.data);
            }
        } else if (action === FormerActions.CHANGE) {
            if (data.name === "siteId") {
                if (data.value !== FormerValues.NOVALUE) {
                    setSiteId(data.value);
                } else {
                    setSiteId(undefined);
                }
            }
        }
    }

    function fetchOptionsDataFromNetwork() {
        if (optionsNetworkInFlight) return;
        setOptionsNetworkInFlight(true);

        const data = {
            siteId : siteId ? siteId : undefined
        };

        Axios.post(ENDPOINTS.inspection.getFilterData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSites(resp.data.sites);
                    setUnits(resp.data.units);
                } else {
                    console.log(API.formatError(resp));
                }
                setOptionsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setOptionsNetworkInFlight(false);
            })
    }

    function fetchSiteDocumentFromNetwork() {
        if (fileNetworkInFlight) return;
        setFileNetworkInFlight(true);

        const data = {
            ids : [siteDocumentId],
            page: 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.siteDocument.getSiteDocuments, data)
            .then((r) => {
                let error = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setSiteDocument(resp.data.data[0]);
                    } else {
                        error = API.defaultError("SDF1100C");
                    }
                } else {
                    error = API.formatError(resp);
                }

                if (error !== null) {
                    setError(error);
                }

                setFileNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFileNetworkInFlight(false);
                setError(API.defaultError("SDF1101C"));
            });
    }

    function uploadFileOverNetwork(formData) {
        if (uploadNetworkInFlight) return;
        setUploadNetworkInFlight(true);

        setError(null);

        const data = new FormData();

        const fdKeys = Object.keys(formData);
        for (let key of fdKeys) {
            let value = formData[key];
            if (key === "expiryDate") {
                try {
                    value = Chronos.with(new Date(value)).seconds();
                } catch (e) {
                    console.log(e);
                }
            } else if (key === "file") {
                if (value.length > 0) {
                    value = value[0].file;
                }
            }
            data.append(key, value);
        }

        if (siteDocumentId) {
            if (reupload) {
                data.append("replaceSiteDocumentId", siteDocumentId);
            } else {
                data.append("id", siteDocumentId);
            }
        }

        const config = {
            onUploadProgress : (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);
                setUploadProgress(progress);
            }
        }

        Axios.post(ENDPOINTS.siteDocument.submitSiteDocument, data, config)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setCallbackData(resp.data.document);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("filing.upload_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setUploadNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUploadNetworkInFlight(false);
                setError(API.defaultError("SDU1100C"));
            });
    }

    // RENDER

    if (!shown) return [];

    let submitButton = (<Button className={"btn btn-success"} label={Rosetta.string("common.submit")} />);
    if (uploadNetworkInFlight) {
        submitButton = (
            <div className={"progress"}>
                <div className={"progress-bar"} style={{ width: uploadProgress + "%" }} />
            </div>
        );
    }

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mb-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("filing.upload_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            {errorElem}

            <Former
                state={formState}
                callback={formDidCallback}>

                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Text name={"title"} label={Rosetta.string("filing.upload_form_title")} className={"form-control"} mandatory={true} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text name={"description"} multiline={true} label={Rosetta.string("filing.upload_form_description")} className={"form-control"} mandatory={false} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown name={"siteId"} label={Rosetta.string("filing.upload_form_site")} className={"form-select"} mandatory={true}>
                            {sites.map((site) => (
                                <option value={site.id}>{site.name}</option>
                            ))}
                        </DropDown>
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown name={"unitId"} label={Rosetta.string("filing.upload_form_unit")} className={"form-select"} mandatory={false}>
                            {units.map((unit) => (
                                <option value={unit.id}>{unit.name}</option>
                            ))}
                        </DropDown>
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DateTime name={"expiryDate"} label={Rosetta.string("filing.upload_form_expiry")} className={"form-control"} mandatory={false} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <File
                            name={"file"}
                            label={Rosetta.string("filing.upload_file_label")}
                            containerClassName={"text-center"}
                            previewClassName={"pb-1"}
                            buttonClassName={"btn btn-primary"}
                            buttonLabel={Rosetta.string("filing.upload_file_select")}
                            mandatory={reupload || siteDocumentId === null || siteDocumentId === undefined} />
                    </Container>
                </Container>

                <Container className={"row mt-4"}>
                    <Container className={"col-12 text-center"}>
                        {submitButton}
                    </Container>
                </Container>

            </Former>

        </Offcanvas>
    )

}