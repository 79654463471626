import Rosetta from "../rosetta/Rosetta";

export const FileUtil = {

    /**
     * Gets a string representation of a file size.
     *
     * @param sizeBytes     The size in bytes to format
     * @param short         If the string should be "short" or not. I.e. a "long" string is "12 megabytes", whereas short is "123mb".
     * @returns {*}         Formatted string
     */
    formatFileSize : (sizeBytes, short) => {
        if (short === undefined) {
            short = true;
        }

        const units = [
            { size : 1073741824, name : "filesizes.gigabytes", abbr : "filesizes.gigabytes_short" },
            { size : 1048576, name : "filesizes.megabytes", abbr : "filesizes.megabytes_short" },
            { size : 1024, name : "filesizes.kilobytes", abbr : "filesizes.kilobytes_short" }
        ]

        let out = null;

        for (const unit of units) {
            let calc = sizeBytes / unit.size;
            if (calc >= 1) {
                let useString = unit.name;
                if (short) {
                    useString = unit.abbr;
                }

                out = Rosetta.string(useString, { size : calc.toFixed(1) }, calc);
                break;
            }
        }

        if (out === null) {
            let useString = "filesizes.bytes";
            if (short) {
                useString = "filesizes.bytes_short";
            }
            out = Rosetta.string(useString, { size : sizeBytes }, sizeBytes);
        }

        return out;
    }

}