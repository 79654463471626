import Rosetta from "../../../../../rosetta/Rosetta";
import {SiteMapComponent, SiteMapComponentActions, SiteMapComponentModes} from "../../../../site/SiteMapComponent";
import {useParams} from "react-router-dom";
import {SiteEditorModal} from "../../site/SiteEditorModal";
import {useState} from "react";
import {OffcanvasActions} from "../../../../offcanvas/Offcanvas";
import {UnitEditorModal} from "../../site/UnitEditorModal";
import {UnitBedroomEditorModal} from "../../site/UnitBedroomEditorModal";
import {UnitBedroomBedEditorModal} from "../../site/UnitBedroomBedEditorModal";
import {SiteCopyModal, SiteCopyModalModes} from "../../site/SiteCopyModal";
import {BaseModalActions} from "../../../../alertmodal/BaseModal";
import {UnitInventoryViewerModal} from "../../availability/UnitInventoryViewerModal";
import {SiteDocumentViewerModal} from "../../site/SiteDocumentViewerModal";
import {Navigator} from "../../../../../util/Navigator";

export const SitePlanDetailScreen = (props) => {

    const {id} = useParams();

    const [mapForceRefresh, setMapForceRefresh] = useState(undefined);

    const [siteEditorShown, setSiteEditorShown] = useState(false);
    const [siteEditorSiteId, setSiteEditorSideId] = useState(undefined);

    const [unitEditorShown, setUnitEditorShown] = useState(false);
    const [unitEditorSiteId, setUnitEditorSiteId] = useState(undefined);
    const [unitEditorUnitId, setUnitEditorUnitId] = useState(undefined);

    const [bedroomEditorShown, setBedroomEditorShown] = useState(false);
    const [bedroomEditorUnitId, setBedroomEditorUnitId] = useState(undefined);
    const [bedroomEditorBedroomId, setBedroomEditorBedroomId] = useState(undefined);

    const [bedEditorShown, setBedEditorShown] = useState(false);
    const [bedEditorBedroomId, setBedEditorBedroomId] = useState(undefined);
    const [bedEditorBedId, setBedEditorBedId] = useState(undefined);

    const [copyModalShown, setCopyModalShown] = useState(false);
    const [copyModalMode, setCopyModalMode] = useState(SiteCopyModalModes.SITE);
    const [copyModalName, setCopyModalName] = useState("");
    const [copyModalId, setCopyModalId] = useState(-1);
    const [copyModalUnitId, setCopyModalUnitId] = useState();
    const [copyModalBedroomId, setCopyModalBedroomId] = useState();

    const [inventoryShown, setInventoryShown] = useState(false);
    const [inventorySiteId, setInventorySiteId] = useState(undefined);
    const [inventoryUnitId, setInventoryUnitId] = useState(undefined);
    const [inventoryInventory, setInventoryInventory] = useState(undefined);

    const [documentsShown, setDocumentsShown] = useState(false);
    const [documentsSiteId, setDocumentsSiteId] = useState(undefined);
    const [documentsUnitId, setDocumentsUnitId] = useState(undefined);
    const [documentsDocuments, setDocumentDocuments] = useState(undefined);

    function mapDidCallback(action, data) {
        console.log("SITE MAP CALLBACK: " + action);
        console.log(data);
        if (action === SiteMapComponentActions.SITE_EDIT) {
            showSiteEditor(data.id)
        } else if (action === SiteMapComponentActions.SITE_COPY) {
            showCopyModal(SiteCopyModalModes.SITE, data.id, data.name);
        } else if (action === SiteMapComponentActions.SITE_ADD || action === SiteMapComponentActions.UNIT_EDIT) {
            if (data.hasOwnProperty("siteId")) {
                showUnitEditor(data.siteId, data.id);
            } else {
                showUnitEditor(data.id);
            }
        } else if (action === SiteMapComponentActions.UNIT_COPY) {
            showCopyModal(SiteCopyModalModes.UNIT, data.id, data.name);
        } else if (action === SiteMapComponentActions.UNIT_ADD || action === SiteMapComponentActions.BEDROOM_EDIT) {
            if (data.hasOwnProperty("unitId")) {
                showBedroomEditor(data.unitId, data.id);
            } else {
                showBedroomEditor(data.id);
            }
        } else if (action === SiteMapComponentActions.BEDROOM_COPY) {
            showCopyModal(SiteCopyModalModes.BEDROOM, data.id, data.name, data.unitId);
        } else if (action === SiteMapComponentActions.BEDROOM_ADD || action === SiteMapComponentActions.BED_EDIT || action === SiteMapComponentActions.BED_CLICKED) {
            if (data.hasOwnProperty("unitBedroomId")) {
                showBedEditor(data.unitBedroomId, data.bedId);
            } else {
                showBedEditor(data.id);
            }
        } else if (action === SiteMapComponentActions.INVENTORY_SHOWN) {
            showInventoryModal(data.siteId, data.unitId, data.inventory);
        } else if (action === SiteMapComponentActions.DOCUMENTS_SHOWN) {
            showDocumentsModal(data.siteId, data.unitId, data.documents);
        } else if (action === SiteMapComponentActions.DATA_EMPTY) {
            Navigator.navigate("/settings/site_plans/");
        }
    }

    function refreshMap() {
        setMapForceRefresh(Math.random());
    }

    function showSiteEditor(siteId) {
        setSiteEditorSideId(siteId);
        setSiteEditorShown(true);
    }

    function siteEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setSiteEditorShown(false);
            refreshMap();
        }
    }

    function showUnitEditor(siteId, unitId) {
        setUnitEditorSiteId(siteId);
        setUnitEditorUnitId(unitId);
        setUnitEditorShown(true);
    }

    function unitEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setUnitEditorShown(false);
            refreshMap();
        }
    }

    function showBedroomEditor(unitId, bedroomId) {
        setBedroomEditorUnitId(unitId);
        setBedroomEditorBedroomId(bedroomId);
        setBedroomEditorShown(true);
    }

    function bedroomEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setBedroomEditorShown(false);
            refreshMap();
        }
    }

    function showBedEditor(bedroomId, bedId) {
        setBedEditorBedroomId(bedroomId);
        setBedEditorBedId(bedId);
        setBedEditorShown(true);
    }

    function bedEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setBedEditorShown(false);
            refreshMap();
        }
    }

    function showCopyModal(mode, id, name, unitId, bedroomId) {
        setCopyModalMode(mode);
        setCopyModalId(id);
        setCopyModalName(name);
        setCopyModalUnitId(unitId);
        setCopyModalBedroomId(bedroomId);
        setCopyModalShown(true);
    }

    function copyModalDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setCopyModalShown(false);

            if (data) {
                refreshMap();
            }
        }
    }

    function showInventoryModal(siteId, unitId, inventory) {
        setInventorySiteId(siteId);
        setInventoryUnitId(unitId);
        setInventoryInventory(inventory);
        setInventoryShown(true);
    }

    function inventoryDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setInventoryShown(false);

            if (data) {
                refreshMap();
            }
        }
    }

    function showDocumentsModal(siteId, unitId, documents) {
        setDocumentsSiteId(siteId);
        setDocumentsUnitId(unitId);
        setDocumentDocuments(documents);
        setDocumentsShown(true);
    }

    function documentsDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setDocumentsShown(false);
        }
    }

    return (
        <div className={"site-plan-list-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("site_plans.title")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"animate-screen-content"}>

                    <SiteMapComponent
                        mode={SiteMapComponentModes.EDIT}
                        siteId={id}
                        callback={mapDidCallback}
                        returnObject={true}
                        forceRefresh={mapForceRefresh} />

                </div>

            </div>

            <SiteEditorModal
                shown={siteEditorShown}
                siteId={siteEditorSiteId}
                callback={siteEditorDidCallback} />

            <UnitEditorModal
                shown={unitEditorShown}
                siteId={unitEditorSiteId}
                unitId={unitEditorUnitId}
                callback={unitEditorDidCallback} />

            <UnitBedroomEditorModal
                shown={bedroomEditorShown}
                unitId={bedroomEditorUnitId}
                bedroomId={bedroomEditorBedroomId}
                callback={bedroomEditorDidCallback} />

            <UnitBedroomBedEditorModal
                shown={bedEditorShown}
                bedroomId={bedEditorBedroomId}
                bedId={bedEditorBedId}
                callback={bedEditorDidCallback} />

            <SiteCopyModal
                shown={copyModalShown}
                mode={copyModalMode}
                id={copyModalId}
                name={copyModalName}
                siteId={id}
                unitId={copyModalUnitId}
                bedroomId={copyModalBedroomId}
                callback={copyModalDidCallback} />

            <UnitInventoryViewerModal
                shown={inventoryShown}
                siteId={inventorySiteId}
                unitId={inventoryUnitId}
                inventory={inventoryInventory}
                callback={inventoryDidCallback} />

            <SiteDocumentViewerModal
                shown={documentsShown}
                siteId={documentsSiteId}
                unitId={documentsUnitId}
                documents={documentsDocuments}
                callback={documentsDidCallback} />

        </div>
    )

}