import Rosetta from "../../../../../rosetta/Rosetta";
import {AutoTable, AutoTableActions} from "../../../../table/AutoTable";
import {ENDPOINTS} from "../../../../../network/API";
import {Navigator} from "../../../../../util/Navigator";
import {Toast} from "../../../../toast/TokyoToaster";
import {Column} from "../../../../table/TableComponent";
import {useState} from "react";
import {SiteEditorModal} from "../../site/SiteEditorModal";
import {OffcanvasActions} from "../../../../offcanvas/Offcanvas";
import {EventUtil} from "../../../../../util/EventUtil";
import {CommonUtil} from "../../../../../util/CommonUtil";

export const SitePlanListScreen = (props) => {

    const [tablePayload, setTablePayload] = useState({});
    const [tablePage, setTablePage] = useState(1);

    const [siteEditorShown, setSiteEditorShown] = useState(false);
    const [siteEditorSiteId, setSiteEditorSiteId] = useState(undefined);

    function updateTable() {
        const td = CommonUtil.cloneObject(tablePayload);
        setTablePayload(td);
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setTablePage(data);
        } else if (action === AutoTableActions.SORT_CHANGE) {
            // TODO
        } else if (action === AutoTableActions.ROW_CLICKED) {
            Navigator.navigate("/site_plan/" + data.id);
        } else if (action === AutoTableActions.ERROR) {
            Toast.show(
                Rosetta.string("common.error"),
                data,
                Toast.ERROR,
                Toast.LONG
            );
        }
    }

    function showSiteEditor(siteId) {
        setSiteEditorSiteId(siteId);
        setSiteEditorShown(true);
    }

    function siteEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setSiteEditorShown(false);
            updateTable();
        }
    }

    return (
        <div className={"site-plan-list-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-8"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("site_plans.title")}</h1>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4 text-end"}>
                        <button className={"btn btn-primary mt-2"} onClick={() => showSiteEditor()}>{Rosetta.string("site_plans.site_add")}</button>
                    </div>
                </div>

                <div className={"animate-screen-content"}>

                    <div className={"row"}>
                        <div className={"col-12"}>

                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <AutoTable
                                        url={ENDPOINTS.site.getSites}
                                        data={tablePayload}
                                        page={tablePage}
                                        callback={tableDidCallback}>

                                        <Column name={"name"} title={Rosetta.string("site_plans.table_name")} />
                                        <Column name={"addressLine1"} title={Rosetta.string("site_plans.table_address1")} />
                                        <Column name={"addressLine1"} title={Rosetta.string("site_plans.table_address2")} />
                                        <Column name={"addressTown"} title={Rosetta.string("site_plans.table_city")} />
                                        <Column name={"addressCounty"} className={"text-center"} title={Rosetta.string("site_plans.table_county")} />
                                        <Column name={"addressPostcode"} className={"text-center"} title={Rosetta.string("site_plans.table_postcode")} />
                                        <Column name={"id"} className={"text-end"} render={(data, row) => (
                                            <>
                                                <button className={"btn btn-primary"}>{Rosetta.string("common.view")}</button>
                                                <button className={"btn btn-primary ms-1"} onClick={(e) => { EventUtil.cancel(e); showSiteEditor(row.id);}}>{Rosetta.string("common.edit")}</button>
                                            </>
                                        )} />

                                    </AutoTable>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <SiteEditorModal
                shown={siteEditorShown}
                siteId={siteEditorSiteId}
                callback={siteEditorDidCallback} />
        </div>
    )

}