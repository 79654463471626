import Rosetta from "../../../../rosetta/Rosetta";
import {NavLink} from "react-router-dom";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {useState} from "react";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Column} from "../../../table/TableComponent";
import {Navigator} from "../../../../util/Navigator";
import {Chronos} from "../../../../chronos/Chronos";
import {TimeUtil} from "../../../../util/TimeUtil";
import Axios from "axios";
import {Toast} from "../../../toast/TokyoToaster";
import {EventUtil} from "../../../../util/EventUtil";
import {CommonUtil} from "../../../../util/CommonUtil";

export const InspectionTemplateListScreen = () => {

    const [tableData, setTableData] = useState({});
    const [page, setPage] = useState(1);

    const [deleteNetworkInFlight, setDeleteNetworkInFlight] = useState(false);

    function moveToEditor(id) {
        if (id === undefined) {
            id = "new";
        }
        Navigator.navigate("/inspection_template/" + id);
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data);
        } else if (action === AutoTableActions.ERROR) {
            AlertModal.showError(data);
        } else if (action === AutoTableActions.ROW_CLICKED) {
            moveToEditor(data.id);
        }
    }

    function formatTableDate(date) {
        try {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return "---";
    }

    function promptForTemplateDeletion(template) {
        AlertModal.showModal(
            Rosetta.string("inspection_templates.delete_prompt_title"),
            Rosetta.string("inspection_templates.delete_prompt_message", { name : template.name }),
            [
                AlertModal.button(Rosetta.string("common.delete"), () => {
                    deleteTemplateOverNetwork(template.id)
                    AlertModal.dismissModal();
                }, "danger"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function deleteTemplateOverNetwork(id) {
        if (deleteNetworkInFlight) return;
        setDeleteNetworkInFlight(true);

        let data = {
            inspectionTemplateId : id
        };

        Axios.post(ENDPOINTS.inspection.deleteInspectionTemplate, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setTableData(CommonUtil.cloneObject(tableData));

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("inspection_templates.delete_success_message"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDeleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("ITA1000C"));
                setDeleteNetworkInFlight(false);
            })
    }

    return (
        <div className={"worker-report-landing-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("inspection_templates.title")}</h1>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 text-end"}>
                        <button className={"btn btn-primary mt-3"} onClick={() => moveToEditor()}>{Rosetta.string("inspection_templates.action_new")}</button>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <AutoTable
                                    url={ENDPOINTS.inspection.getInspectionTemplates}
                                    data={tableData}
                                    page={page}
                                    callback={tableDidCallback}>

                                    <Column name={"name"} title={Rosetta.string("inspection_templates.table_title")} />
                                    <Column name={"inspectionFrequency"} title={Rosetta.string("inspection_templates.table_frequency")} className={"text-center"} render={(data) => TimeUtil.parseTimeDuration(data, "---")} />
                                    <Column name={"lastSubmissionDate"} title={Rosetta.string("inspection_templates.table_last_date")} className={"text-center"} render={(data, row) => formatTableDate(data)} />
                                    <Column name={"nextInspectionDate"} title={Rosetta.string("inspection_templates.table_next_date")} className={"text-center"} render={(data, row) => formatTableDate(data)} />
                                    <Column name={"inspectionTemplateType"} title={Rosetta.string("inspection_templates.table_location")} className={"text-center"} />
                                    <Column name={"id"} className={"text-end"} render={(data, row) => (
                                        <button className={"btn btn-danger"} onClick={(e) => { EventUtil.cancel(e); promptForTemplateDeletion(row); }}>{Rosetta.string("common.delete")}</button>
                                    )} />

                                </AutoTable>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )

}