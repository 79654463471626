import {useEffect, useRef, useState} from "react";
import {VideoPlayer} from "../../videoplayer/VideoPlayer";
import YouTube from "react-youtube";
import {VideoUtil} from "../../../util/VideoUtil";
import Rosetta from "../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";

import "./VideoCarouselModal.css";

export const VideoCarouselActions = {
    CLOSE : "videoCarousel.dismiss"
}

export const VideoCarouselModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [videos, setVideos] = useState([]);
    const [videoCount, setVideoCount] = useState(0);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [currentVideo, setCurrentVideo] = useState(null);

    const [dismissing, setDismissing] = useState(false);

    const dismissTimeout = useRef();

    useEffect(() => {
        if (shown) {
            console.log("SHOWN");
            setVideos([]);
            setVideoCount(0);
            fetchVideosFromNetwork();
        } else {
            console.log("NOT SHOWN");
            setCurrentVideo(null);
            setNetworkInFlight(false);
            setDismissing(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function dismiss() {
        setDismissing(true);

        clearTimeout(dismissTimeout.current);
        dismissTimeout.current = setTimeout(() => {
            handleCallback(VideoCarouselActions.CLOSE);
        }, 200);
    }

    function fetchVideosFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        Axios.post(ENDPOINTS.welcome.getVideos, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let videos = resp.data.data;

                    setVideos(videos);
                    setVideoCount(resp.data.count);

                    if (videos.length > 0 && !currentVideo) {
                        setCurrentVideo(videos[0]);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("WVF1000C"));
            });
    }

    if (!shown) return [];

    let videoElement = [];
    if (currentVideo !== null) {
        const videoTypeId = parseInt(currentVideo.videoTypeId);
        if (videoTypeId === 1) {
            videoElement = (
                <VideoPlayer src={currentVideo.uri} />
            )
        } else if (videoTypeId === 2) {
            let videoId = VideoUtil.getYouTubeVideoId(currentVideo.uri);

            if (videoId !== null) {
                videoElement = (
                    <YouTube videoId={videoId}/>
                )
            } else {
                // TODO Error
            }
        }
    }

    let extraClass = "";
    let key = "video-carousel-modal";
    if (dismissing) {
        extraClass = " dismissing";
        key += "-dismissing";
    }

    return (
        <div className={"modal video-carousel-modal" + extraClass} key={key}>
            <div className={"modal-dialog modal-xl"}>
                <div className={"modal-content"}>

                    <div className={"modal-header"}>
                        <div className={"modal-title"}>{Rosetta.string("help.videos_title")}</div>
                        <button type="button" className="btn-close" aria-label={Rosetta.string("common.close")} onClick={() => dismiss()} />
                    </div>

                    <div className={"modal-body"}>
                        <div className={"container-fluid"}>

                            <div className={"row"}>
                                <div className={"col-12 text-center"}>

                                    {videoElement}

                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 text-center"}>

                                    {
                                        videos.map((video) => (
                                            <div className={"video-item" + ((currentVideo && currentVideo.id === video.id) ? " active" : "")} onClick={() => setCurrentVideo(video)}>
                                                <div className={"video-thumb"} />
                                                <div className={"video-title"}>{video.title}</div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}