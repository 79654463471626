import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {AlertModal} from "../../alertmodal/AlertModal";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";

export const WorkerUserInfoScreen = (props) => {

    const {callback} = props;

    const [user, setUser] = useState();

    useEffect(() => {
        setUser(DataManager.getUser());
    }, []);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    let mainContent = [];
    if (user) {
        mainContent = (
            <div className={"col-12 col-md-8 col-lg-6"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("worker_info.title_personal")}</h3>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("worker_info.personal_first_name")}</label>
                        <div className={"form-control"}>{user.firstName}</div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("worker_info.personal_last_name")}</label>
                        <div className={"form-control"}>{user.lastName}</div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("worker_info.personal_email")}</label>
                        <div className={"form-control"}>{user.email}</div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className={"worker-user-info-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("navigation.worker_info")} />
                </div>
            </div>

            <div className={"row justify-content-center"}>
                {mainContent}
            </div>

        </div>
    );

}