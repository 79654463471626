import Rosetta from "../../../rosetta/Rosetta";

import iconMenu from "../../../assets/images/menu.svg";

import "./AdminLTEHeader.css";
import {EventUtil} from "../../../util/EventUtil";

import defaultBrandingLogo from "../../../assets/images/doorway_logo_blue.png";

export const AdminLTEHeaderActions = {
    MENU_CLICK : "adminlteheader.menu_click"
}

export const AdminLTEHeaderHelper = {
    icon : (icon, iconAlt, callback) => {
        return {
            icon,
            iconAlt,
            callback
        };
    },
    text : (label, callback) => {
        return {
            label,
            callback
        };
    }
}

export const AdminLTEHeader = (props) => {

    const {callback} = props;
    const {actions} = props;
    const {brandingImageUrl} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    let actionElems = [];
    if (actions && actions.length > 0) {
        for (let action of actions) {
            let extraClass = "";
            if (action.callback !== undefined) {
                extraClass = " clickable";
            }

            let imageElem = [];
            if (action.icon) {
                imageElem = (
                    <div className={"image"}>
                        <img src={action.icon} alt={action.iconAlt} />
                    </div>
                )
            }

            let labelElem = [];
            if (action.label) {
                labelElem = (
                    <p>{action.label}</p>
                )
            }

            actionElems.push(
                <li className={"nav-item"}>
                    <span className={"nav-link " + extraClass} onClick={(e) => action.callback ? action.callback() : EventUtil.cancel(e)}>
                        {imageElem}
                        {labelElem}
                    </span>
                </li>
            )
        }
    }

    return (
        <nav className={"main-header navbar navbar-expand navbar-white navbar-light"}>
            <ul className={"navbar-nav"}>
                <li className={"nav-item"}>
                    <span className={"nav-link clickable"} onClick={() => handleCallback(AdminLTEHeaderActions.MENU_CLICK)}>
                        <div className={"image"}>
                            <img src={iconMenu} alt={Rosetta.string("common.menu")} />
                        </div>
                    </span>
                </li>

                <li className={"branding-logo"}>
                    <img src={brandingImageUrl ? brandingImageUrl : defaultBrandingLogo} alt={""} />
                </li>
            </ul>

            <ul className={"navbar-nav ml-auto"}>
                {actionElems}
            </ul>
        </nav>
    )

}