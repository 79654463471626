import {useEffect, useState} from "react";
import Rosetta from "../../../../rosetta/Rosetta";
import {SegmentTab, SegmentTabBar, SegmentTabBarActions} from "../../../segmenttab/SegmentTabBar";
import {Button, Container, Former, FormerActions, Text} from "../../../form/Former";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {Toast} from "../../../toast/TokyoToaster";
import ArrayUtil from "../../../../util/ArrayUtil";
import WindowUtil from "../../../../util/WindowUtil";
import {Chronos} from "../../../../chronos/Chronos";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {StringUtil} from "../../../../util/StringUtil";

import "./UserMessagesModal.css";

import closeIcon from "../../../../assets/images/close.svg";
import {ImageUtil} from "../../../../util/ImageUtil";

export const UserMessagesModalActions = {
    CLOSE : "usermessagesmodal.close"
}

export const UserMessagesModal = (props) => {

    const MODE_LIST = 1;
    const MODE_EDITOR = 2;

    const {shown} = props;
    const {user} = props;
    const {callback} = props;

    const [messages, setMessages] = useState([]);
    const [messageCount, setMessageCount] = useState(0);
    const [messagesNetworkInFlight, setMessagesNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [selectedMessageId, setSelectedMessageId] = useState(null);

    const [page, setPage] = useState(1);
    const [forceFetchNetwork, setForceFetchNetwork] = useState(null);

    const [mode, setMode] = useState(MODE_EDITOR);

    const [dismissing, setDismissing] = useState(false);
    const [dismissTrigger, setDismissTrigger] = useState(null);

    const [formState, setFormState] = useState({});

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setMode(MODE_EDITOR);
            setMessages([]);
            setMessageCount(0);
            setDismissing(false);
            setDismissTrigger(null);
            setPage(1);
            setMessages([]);
            setSelectedMessageId(null);
            setForceFetchNetwork(null);
        }
    }, [shown]);

    useEffect(() => {
        if (mode === MODE_LIST) {
            setMessages([]);
            setMessageCount(0);
            setSelectedMessageId(null);

            if (page !== 1) {
                setPage(1);
            } else {
                setForceFetchNetwork(Math.random());
            }
        }
    }, [mode]);

    useEffect(() => {
        fetchMessagesFromNetwork();
    }, [page])

    useEffect(() => {
        if (shown && messages.length === 0) {
            fetchMessagesFromNetwork();
        }
    }, [shown]);

    useEffect(() => {
        let dismissTimeout = setTimeout(() => {
            clearTimeout(dismissTimeout);
            setDismissTrigger(true);
        }, 200);
    }, [dismissing]);

    useEffect(() => {
        if (dismissTrigger) {
            handleCallback(UserMessagesModalActions.CLOSE);
            setDismissing(false);
            setDismissTrigger(null);
        }
    }, [dismissTrigger]);

    useEffect(() => {
        if (forceFetchNetwork) {
            fetchMessagesFromNetwork();
        }
    }, [forceFetchNetwork]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function tabBarDidCallback(action, data) {
        if (action === SegmentTabBarActions.SELECT) {
            setMode(data);
        }
    }

    function editorFormDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitMessageOverNetwork(data.data);
            }
        }
    }

    function messageItemWasClicked(message) {
        if (message.id === selectedMessageId) {
            setSelectedMessageId(null);
        } else {
            setSelectedMessageId(message.id);
        }
    }

    function fetchMessagesFromNetwork() {
        if (messagesNetworkInFlight) return;
        setMessagesNetworkInFlight(true);

        let data = {
            userId : user ? user.id : undefined,
            page,
            ordering : JSON.stringify({
                name : "dateCreated",
                direction : "desc"
            })
        };

        Axios.post(ENDPOINTS.message.getMessages, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let newMessageArray = ArrayUtil.appendArray(messages, resp.data.data, true);
                    setMessages(newMessageArray);
                    setMessageCount(resp.data.count);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setMessagesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setMessagesNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("UMF1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    function submitMessageOverNetwork(data) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        let formData = {
            userId : user ? user.id : undefined,
            title : data.title,
            message : data.message
        };

        Axios.post(ENDPOINTS.message.submitMessage, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("messages.admin_editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setFormState({m:Math.random()}); // Clear form now
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("UMS1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    if (!shown) return [];

    let mainContent = [];
    if (mode === MODE_EDITOR) {
        let submitButton = [];

        if (!submitNetworkInFlight) {
            let submitButtonClass = "";
            // if (submitNetworkInFlight) {
            //     submitButtonClass += " disabled";
            // }
            console.log("SHOW SUBMIT");
            submitButton = (<Button label={Rosetta.string("messages.admin_editor_submit")} className={"btn btn-success" + submitButtonClass} />);
        } else {
            console.log("SHOW LOADING SPINNER");
            submitButton = (<LoadingSpinner inline={true} small={true} />);
        }

        mainContent = (
            <div className={"message-screen-did-appear"}>
                <Former
                    state={formState}
                    callback={editorFormDidCallback}>

                    <Container className={"row"}>
                        <Container className={"col-12"}>
                            <Text name={"title"} label={Rosetta.string("messages.admin_editor_title")} className={"form-control"} mandatory={true} />
                        </Container>
                    </Container>

                    <Container className={"row mt-2"}>
                        <Container className={"col-12"}>
                            <Text name={"message"} label={Rosetta.string("messages.admin_editor_message")} multiline={true} className={"form-control"} mandatory={true} />
                        </Container>
                    </Container>

                    <Container className={"row mt-4"}>
                        <Container className={"col-12 text-center"}>
                            {submitButton}
                        </Container>
                    </Container>

                </Former>
            </div>
        )
    } else {
        let listItems = messages.map((message) => {
            let dateDisplay = "";
            try {
                dateDisplay = Chronos.withTimestampSeconds(message.dateCreated).format("dd/MM/yyyy HH:mm");
            } catch (e) {
                console.log(e);
            }

            let readBadge = [];
            if (parseInt(message.read) === 1) {
                readBadge = (
                    <span className={"badge badge-secondary"}>{Rosetta.string("messages.admin_user_read")}</span>
                )
            }

            let bodyElems = [];
            if (message.message) {
                let messageString = message.message;
                if (selectedMessageId !== message.id) {
                    messageString = StringUtil.ellipsise(messageString,  200);
                }

                bodyElems = messageString.split("\n").map((string) => (
                    <p>{string}</p>
                ))
            }

            return (
                <div className={"list-group-item list-group-item-action"} onClick={() => messageItemWasClicked(message)}>
                    <div className={"message-date"}>{dateDisplay} {readBadge}</div>
                    <div className={"message-subject"}>{message.title}</div>
                    <div className={"message-body"}>{bodyElems}</div>
                </div>
            )
        });

        let loadMoreButton = [];
        if (messagesNetworkInFlight) {
            loadMoreButton = (
                <div className={"list-group-item text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </div>
            )
        } else if (messageCount > 0 && messages.length < messageCount) {
            loadMoreButton = (
                <div className={"list-group-item text-center"}>
                    <button className={"btn btn-primary"} onClick={() => setPage(page + 1)}>{Rosetta.string("common.load_more")}</button>
                </div>
            )
        } else if (messages.length === 0) {
            loadMoreButton = (
                <div className={"list-group-item text-center"}>
                    {Rosetta.string("common.empty_message")}
                </div>
            )
        }

        mainContent = (
            <div className={"list-group message-group"}>
                {listItems}
                {loadMoreButton}
            </div>
        )
    }

    let className = "";
    let mutationKey = "user-messages-modal";
    if (dismissing) {
        className = " dismissing";
        mutationKey += "-dismissing";
    }

    return (
        <div className={"modal fade show user-messages-modal" + className} key={mutationKey}>
            <div className={"modal-dialog modal-lg"}>
                <div className={"modal-content"}>

                    <div className={"modal-header justify-content-between"}>
                        <div>{Rosetta.string("messages.admin_title")}</div>
                        <div className={"close-button"} onClick={() => setDismissing(true)} style={{backgroundImage : ImageUtil.background(closeIcon)}} />
                    </div>

                    <div className={"modal-body"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <SegmentTabBar
                                    selected={mode}
                                    callback={tabBarDidCallback}>

                                    <SegmentTab id={MODE_EDITOR} label={Rosetta.string("messages.admin_tab_send")} />
                                    <SegmentTab id={MODE_LIST} label={Rosetta.string("messages.admin_tab_view")} />

                                </SegmentTabBar>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                {mainContent}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}