import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {ImageUtil} from "../../../../util/ImageUtil";

import "./OrganisationSelectScreen.css";
import {DataManager} from "../../../../data/DataManager";
import {Navigator} from "../../../../util/Navigator";

export const OrganisationSelectScreen = (props) => {

    const [organisations, setOrganisations] = useState([]);
    const [orgNetworkInFlight, setOrgNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchOrganisationsFromNetwork();
    }, []);

    function organisationWasSelected(organisation) {
        if (organisation) {
            DataManager.setOrganisationId(organisation.id);
            Navigator.navigate("/");
        }
    }

    function fetchOrganisationsFromNetwork() {
        if (orgNetworkInFlight) return;
        setOrgNetworkInFlight(true);

        Axios.get(ENDPOINTS.user.getUserEmployers)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setOrganisations(resp.data.organisations);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setOrgNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setOrgNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UEF1000C"));
            });
    }

    // RENDER

    let mainContent = [];

    if (orgNetworkInFlight) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        );
    } else {
        if (organisations.length > 0) {
            let options = organisations.map((org) => (
                <div className={"list-group-item list-group-item-action clickable organisation-item"} onClick={() => organisationWasSelected(org)}>
                    <div className={"organisation-image"} style={{backgroundImage : ImageUtil.background(org.logoURL)}} />
                    <div className={"content-area"}>
                        <div className={"title"}>{org.name}</div>
                        <div className={"message"}>{org.address}</div>
                    </div>
                </div>
            ))

            mainContent = (
                <div className={"row justify-content-center mb-4"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"list-group"}>
                            {options}
                        </div>
                    </div>
                </div>
            )
        } else {
            mainContent = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={"organisation-select-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("organisation.title")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"animate-screen-content"}>
                    <div className={"row mb-4"}>
                        <div className={"col-12 text-center text-bold"}>
                            {Rosetta.string("organisation.subtitle")}
                        </div>
                    </div>

                    {mainContent}
                </div>
            </div>
        </div>
    )

}