import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import Rosetta from "../../../../rosetta/Rosetta";
import {Button, Container, DateTime, Former, FormerActions} from "../../../form/Former";
import {CommonUtil} from "../../../../util/CommonUtil";
import {Chronos} from "../../../../chronos/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {DateUtil} from "../../../../util/DateUtil";

export const BookingBedOccupancyEditorActions = {
    SUBMIT : "bookingbedoccupancyeditor.submit"
};

export const BookingBedOccupancyEditorModal = (props) => {

    const {shown} = props;
    const {callback} = props;
    const {bed} = props;
    const {startDate} = props;
    const {endDate} = props;
    const {placementId} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    const [availabilityResult, setAvailabilityResult] = useState([]);
    const [availabilityNetworkInFlight, setAvailabilityNetworkInFlight] = useState(false);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (shown) {
            setFormData({
                startDate : startDate ? DateUtil.secondsToDateString(startDate) : undefined,
                endDate : endDate ? DateUtil.secondsToDateString(endDate) : undefined
            });

            if (bed) {
                fetchBedAvailabilityFromNetwork();
            }
        } else {
            setForceDismiss(false);
        }
    }, [shown]);

    useEffect(() => {
        if (bed) {
            fetchBedAvailabilityFromNetwork();
        }
    }, [formData]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function occupancyWasSubmitted() {
        let useStartDate = startDate;
        let useEndDate = endDate;

        if (formData.startDate !== undefined) {
            useStartDate = Chronos.parse(formData.startDate).seconds();
        }

        if (formData.endDate !== undefined) {
            useEndDate = Chronos.parse(formData.endDate).seconds();
        }

        handleCallback(BookingBedOccupancyEditorActions.SUBMIT, {
            bed,
            startDate : useStartDate,
            endDate : useEndDate,
            placementId
        });
        setForceDismiss(true);
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                occupancyWasSubmitted();
            }
        } else if (action === FormerActions.CHANGE) {
            putFormData(data.name, data.value);
        }
    }

    function putFormData(keyName, value) {
        const newFormData = CommonUtil.cloneObject(formData);
        newFormData[keyName] = value;
        setFormData(newFormData);
    }

    function formatDate(date) {
        try {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return "";
    }

    function fetchBedAvailabilityFromNetwork() {
        if (availabilityNetworkInFlight) return;
        setAvailabilityNetworkInFlight(true);

        let useStartDate = startDate;
        let useEndDate = endDate;

        if (formData.startDate !== undefined) {
            try {
                useStartDate = Chronos.parse(formData.startDate).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        if (formData.endDate !== undefined) {
            try {
                useEndDate = Chronos.parse(formData.endDate).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        const data = {
            siteId: bed ? bed.siteId : undefined,
            unitId: bed ? bed.unitId : undefined,
            bedroomId: bed ? bed.unitBedroomId : undefined,
            bedId: bed ? bed.bedId : undefined,
            startDate: useStartDate,
            endDate: useEndDate
        };

        Axios.post(ENDPOINTS.site.getSiteBedAvailability, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setAvailabilityResult(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setAvailabilityNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAvailabilityNetworkInFlight(false);
            });
    }

    if (!shown) return [];

    let conflictElems = [];

    if (availabilityResult && availabilityResult.length > 0) {
        conflictElems = availabilityResult.map((availability) => (
            <div className={"row mt-2"}>
                <div className={"col-12"}>

                    <div className={"card card-danger"}>
                        <div className={"card-header"}>
                            {Rosetta.string("booking.detail_conflict_title")}
                        </div>
                        <div className={"card-body"}>
                            {Rosetta.string("booking.detail_conflict_message", { start_date : formatDate(availability.nextStartDate), end_date : formatDate(availability.nextEndDate) })}
                        </div>
                    </div>

                </div>
            </div>
        ))
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("booking.occupancy_editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    {Rosetta.string("booking.occupancy_editor_message")}
                </div>
            </div>

            <div className={"row mt-2"}>
                <label>{Rosetta.string("booking.detail_assignment_details_site")}</label>
                <div className={"form-control"}>{ bed ? bed.siteName : "" }</div>
            </div>

            <div className={"row mt-2"}>
                <label>{Rosetta.string("booking.detail_assignment_details_unit")}</label>
                <div className={"form-control"}>{ bed ? bed.unitName : "" }</div>
            </div>

            <div className={"row mt-2"}>
                <label>{Rosetta.string("booking.detail_assignment_details_bedroom")}</label>
                <div className={"form-control"}>{ bed ? bed.unitBedroomName : "" }</div>
            </div>

            <div className={"row mt-2"}>
                <label>{Rosetta.string("booking.detail_assignment_details_bed")}</label>
                <div className={"form-control"}>{ bed ? bed.name : "" }</div>
            </div>

            <Former
                state={formData}
                callback={formDidCallback}>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DateTime className={"form-control"} name={"startDate"} label={Rosetta.string("booking.occupancy_editor_start_date")} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DateTime className={"form-control"} name={"endDate"} label={Rosetta.string("booking.occupancy_editor_end_date")} />
                    </Container>
                </Container>

                {conflictElems}

                <Container className={"row mt-2"}>
                    <Container className={"col-12 text-center"}>
                        <Button label={Rosetta.string("common.save")} className={"btn btn-success"} />
                    </Container>
                </Container>

            </Former>

        </Offcanvas>
    )

}