import {Chronos} from "../chronos/Chronos";

export const DateUtil = {
    secondsToDateString : (seconds) => {
        if (seconds) {
            try {
                return Chronos.withTimestampSeconds(seconds).format("yyyy-MM-dd");
            } catch (e) {
                console.log(e);
            }
        }
        return null;
    }
}