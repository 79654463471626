import "./UserInfoWidget.css";
import defaultAvatar from "../../assets/images/person.svg";
import {ImageUtil} from "../../util/ImageUtil";
import Rosetta from "../../rosetta/Rosetta";

export const UserInfoWidgetActions = {
    SIGNOUT : "signout"
}

export const UserInfoWidget = (props) => {

    const {user} = props;
    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    return (
        <div className={"user-info-widget"}>
            <div className={"card"}>
                <div className={"card-body"}>

                    <div className={"info-area"}>
                        <div className={"info-avatar"} style={{backgroundImage : ImageUtil.background(defaultAvatar)}} />

                        <div className={"info-content"}>
                            <div className={"info-content-name"}>{user ? Rosetta.string("common.name_format", { given_name : user.firstName, family_name : user.lastName }) : "?"}</div>
                            <div className={"info-content-role"}>{user ? user.role : "?"}</div>
                            <div className={"info-content-action"}>
                                <button className={"btn btn-outline-danger btn-sm"} onClick={() => handleCallback(UserInfoWidgetActions.SIGNOUT)}>{Rosetta.string("settings.sign_out")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}