import {useEffect, useState} from "react";
import {Chronos} from "../../chronos/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {LoadingSpinner} from "../loading/LoadingSpinner";
import ArrayUtil from "../../util/ArrayUtil";
import Rosetta from "../../rosetta/Rosetta";
import {ImageUtil} from "../../util/ImageUtil";
import slotIcon from "../../assets/images/account.svg";

import "./SiteMapComponent.css";

import editIcon from "../../assets/images/edit.svg";
import copyIcon from "../../assets/images/copy.svg";
import addIcon from "../../assets/images/add.svg";
import bedDoubleIcon from "../../assets/images/bed_double.svg";
import bedSingleIcon from "../../assets/images/bed_single.svg";

export const SiteMapComponentActions = {
    BED_CLICKED : "sitemap.bed_click",
    INVENTORY_SHOWN : "sitemap.inventory_shown",
    DOCUMENTS_SHOWN : "sitemap.documents_shown",
    SITE_EDIT : "sitemap.site_edit",
    SITE_COPY : "sitemap.site_copy",
    SITE_ADD : "sitemap.site_add",
    UNIT_EDIT : "sitemap.unit_edit",
    UNIT_COPY : "sitemap.unit_copy",
    UNIT_ADD : "sitemap.unit_add",
    BEDROOM_EDIT : "sitemap.bedroom_edit",
    BEDROOM_COPY : "sitemap.bedroom_copy",
    BEDROOM_ADD : "sitemap.bedroom_add",
    BED_EDIT : "sitemap.bed_edit",
    DATA_EMPTY : "sitemap.data_empty"
}

export const SiteMapComponentModes = {
    SELECT : 1,
    EDIT : 2
}

export const SiteMapComponent = (props) => {

    const DISPLAY_MODE_NORMAL = 1;
    const DISPLAY_MODE_SMALL = 2;

    const {mode} = props;

    const {siteId} = props;
    const {startDate} = props;
    const {endDate} = props;
    const {callback} = props;
    const {returnObject} = props;
    const {forceRefresh} = props;

    const [availabilityData, setAvailabilityData] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [displayMode, setDisplayMode] = useState(DISPLAY_MODE_NORMAL);

    useEffect(() => {
        getMapDataFromNetwork();
    }, []);

    useEffect(() => {
        getMapDataFromNetwork();
    }, [startDate, endDate, siteId, forceRefresh])

    function handleCallback(action, data) {
        console.log("CALLBACK?");
        if (callback) {
            callback(action, data);
        }
    }

    /**
     * Sort Beds so that beds appear in neat, even rows where possible.
     *
     * Rows can consist of 3 "slots", a Single bed uses a single slot. A double bed uses two slots.
     * We therefore
     *
     * @param beds
     * @returns {*[]}
     */
    function sortBeds(beds) {
        let out = [];

        let rows = [];

        for (let i = 0; i < beds.length; i++) {
            let bedCapacity = Math.min(2, parseInt(beds[i].capacity));

            let inserted = false;
            for (let x = 0; x < rows.length; x++) {
                // Iterate through rows, find a row with available space
                if (rows[x].capacity + bedCapacity <= 3) {
                    // If this bed can fit, great. Add it to that row.
                    rows[x].beds.push(beds[i]);
                    rows[x].capacity += bedCapacity; // Make sure this is accurate.
                    inserted = true;
                    break; // Stop the bed looking for more spaces
                }
            }

            // If we couldn't find space, create a new row
            if (!inserted) {
                rows.push({
                    capacity : bedCapacity,
                    beds : [beds[i]]
                });
            }
        }

        // Now flatten the rows into one list of beds.
        // It shouldn't matter if there is an uneven number to make up  row
        // since over-sized items will be forced onto a new row naturally
        // when rendered.
        for (let i = 0; i < rows.length; i++) {
            for (let x = 0; x < rows[i].beds.length; x++) {
                out.push(rows[i].beds[x]);
            }
        }

        return out;
    }

    function getMapDataFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {};

        if (siteId) {
            data.siteIds = JSON.stringify([siteId]);
        }

        if (startDate) {
            if (!isNaN(startDate)) {
                data.startDate = startDate;
            } else {
                try {
                    data.startDate = Chronos.with(new Date(startDate)).seconds();
                } catch (e) {
                    console.log(e);
                }
            }
        }

        if (endDate) {
            if (!isNaN(endDate)) {
                data.endDate = endDate;
            } else {
                try {
                    data.endDate = Chronos.with(new Date(endDate)).seconds();
                } catch (e) {
                    console.log(e);
                }
            }
        }

        data.outputMode = "map";

        if (mode === SiteMapComponentModes.EDIT) {
            data.queryMode = "map";
        }

        Axios.post(ENDPOINTS.availability.getBedAvailability, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length === 0) {
                        handleCallback(SiteMapComponentActions.DATA_EMPTY);
                    }
                    setAvailabilityData(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
            });
    }

    function toggleDisplayMode() {
        let outMode = DISPLAY_MODE_NORMAL;
        if (displayMode === DISPLAY_MODE_NORMAL) {
            outMode = DISPLAY_MODE_SMALL;
        }
        setDisplayMode(outMode);
    }

    // RENDER

    let siteMapElems = (
        <div className={"row"}>
            <div className={"col-12"}>
                <LoadingSpinner />
            </div>
        </div>
    );

    if (!networkInFlight && availabilityData) {
        siteMapElems = availabilityData.map((site) => {
            let siteInventory = [];
            let siteDocuments = site.documents;
            if (!siteDocuments) {
                siteDocuments = [];
            }

            const unitElems = site.units.map((unit) => {
                let unitActions = [];
                let unitButtons = [];

                if (unit.hasOwnProperty("inventory")) {
                    siteInventory = ArrayUtil.appendArray(siteInventory, unit.inventory, false);

                    let buttonElem = (
                        <button className={"btn btn-light"} onClick={() => handleCallback(SiteMapComponentActions.INVENTORY_SHOWN, { siteId : site.id, unitId : unit.id, inventory : unit.inventory })}>
                            {Rosetta.string("availability.inventory_count", {count: unit.inventory.length}, unit.inventory.length)}
                        </button>
                    );

                    if (mode === undefined || mode === SiteMapComponentModes.SELECT) {
                        if (unit.inventory.length > 0) {
                            unitActions.push(buttonElem);
                        }
                    } else {
                        unitButtons.push(
                            <div className={"row mb-2"}>
                                <div className={"col-12"}>
                                    {buttonElem}
                                </div>
                            </div>
                        );
                    }
                }

                if (mode === SiteMapComponentModes.EDIT) {
                    unitActions.push(
                        <button className={"btn btn-light header-action"} onClick={() => handleCallback(SiteMapComponentActions.UNIT_EDIT, unit)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(editIcon)}} /></button>
                    )
                    unitActions.push(
                        <button className={"btn btn-light ms-1 header-action"} onClick={() => handleCallback(SiteMapComponentActions.UNIT_COPY, unit)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(copyIcon)}} /></button>
                    )
                    unitActions.push(
                        <button className={"btn btn-light ms-1 header-action"} onClick={() => handleCallback(SiteMapComponentActions.UNIT_ADD, unit)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(addIcon)}} /></button>
                    )
                }

                const unitBedroomElems = unit.bedrooms.map((bedroom) => {
                    const bedElems = sortBeds(bedroom.beds).map((bed) => {
                        const outOfUse = parseInt(bed.outOfUse) === 0

                        let sizeClass = "col-12";
                        if (displayMode === DISPLAY_MODE_NORMAL) {
                            if (parseInt(bed.capacity) === 1) {
                                sizeClass = "col-4";
                            } else if (parseInt(bed.capacity) === 2) {
                                sizeClass = "col-6";
                            }
                        } else {
                            // Make uniform for small mode
                            sizeClass = "col-6";
                        }

                        let colourClass = "";
                        let clickCallback = () => handleCallback(SiteMapComponentActions.BED_CLICKED, returnObject ? bed : bed.bedId);
                        if (outOfUse) {
                            if (parseInt(bed.capacity) === parseInt(bed.occupiedCount)) {
                                colourClass = " bg-danger";
                            } else if (parseInt(bed.occupiedCount) === 0) {
                                colourClass = " bg-success";
                            } else if (parseInt(bed.capacity) > parseInt(bed.occupiedCount)) {
                                colourClass = " bg-warning";
                            }
                        } else {
                            colourClass = "bg-secondary";
                            // clickCallback = undefined;
                        }

                        let slotElems = [];
                        for (let i = 0; i < Math.max(1, bed.capacity); i++) {
                            let slotIconElem = [];
                            if (bed.occupiedCount > i) {
                                slotIconElem = (<div className={"ratio ratio-1x1 bed-slot-icon"} style={{backgroundImage : ImageUtil.background(slotIcon)}} />);
                            }

                            slotElems.push(
                                <div className={"bed-slot"} onClick={() => handleCallback(SiteMapComponentActions.BED_EDIT, bed)}>
                                    {slotIconElem}
                                </div>
                            )
                        }

                        let bedIcon = null;
                        if (parseInt(bed.bedTypeId) === 1) {
                            bedIcon = bedSingleIcon;
                        } else {
                            bedIcon = bedDoubleIcon;
                        }

                        let slotContainer = [];
                        let popoverSlots = [];
                        if (slotElems.length > 0) {
                            if (displayMode === DISPLAY_MODE_NORMAL) {
                                slotContainer = (
                                    <div className={"bed-slots"}>
                                        {slotElems}
                                    </div>
                                )
                            } else {
                                popoverSlots = (
                                    <div className={"bed-slots"}>
                                        {slotElems}
                                    </div>
                                );
                            }
                        }

                        return (
                            <div className={sizeClass + " mb-2"} key={"bed-" + bed.id}>
                                <div className={"bedroom-bed " + colourClass} onClick={clickCallback}>
                                    {slotContainer}
                                    <div className={"bed-name"}>{bed.name}</div>
                                    <div className={"bed-icon"} style={{"background-image" : ImageUtil.background(bedIcon)}} />

                                    <div className={"info-popover"} onClick={clickCallback}>
                                        <div className={"speech-body"}>
                                            <div><strong>{bed.name}</strong></div>
                                            <div>{bed.bedTypeName}</div>
                                            {popoverSlots}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    });

                    let bedroomActions = [];
                    if (mode === SiteMapComponentModes.EDIT) {
                        bedroomActions.push(
                            <button className={"btn btn-outline-dark header-action"} onClick={() => handleCallback(SiteMapComponentActions.BEDROOM_EDIT, bedroom)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(editIcon)}} /></button>
                        )
                        bedroomActions.push(
                            <button className={"btn btn-outline-dark ms-1 header-action"} onClick={() => handleCallback(SiteMapComponentActions.BEDROOM_COPY, bedroom)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(copyIcon)}} /></button>
                        )
                        bedroomActions.push(
                            <button className={"btn btn-outline-dark ms-1 header-action"} onClick={() => handleCallback(SiteMapComponentActions.BEDROOM_ADD, bedroom)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(addIcon)}} /></button>
                        )
                    }

                    let unitColumnClass = "col-12 col-md-12 col-lg-6";
                    // if (displayMode === DISPLAY_MODE_SMALL) {
                    //     unitColumnClass = "col-12 col-md-6 col-lg-4";
                    // }

                    return (
                        <div className={unitColumnClass}>
                            <div className={"card"}>
                                <div className={"card-header"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            {bedroom.name}
                                        </div>

                                        <div className={"col-12"}>
                                            {bedroomActions}
                                        </div>
                                    </div>
                                </div>
                                <div className={"card-body"}>
                                    <div className={"row"}>
                                        {bedElems}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                });

                let siteColumnClass = "col-12 col-md-12 col-lg-6";
                if (displayMode === DISPLAY_MODE_SMALL) {
                    siteColumnClass = "col-12 col-md-6 col-lg-3";
                }

                return (
                    <div className={siteColumnClass}>
                        <div className={"card"}>
                            <div className={"card-header text-bg-info"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-8"}>
                                        {unit.name}
                                    </div>

                                    <div className={"col-12 col-md-4 text-end"}>
                                        {unitActions}
                                    </div>
                                </div>

                            </div>
                            <div className={"card-body"}>
                                {unitButtons}

                                <div className={"row"}>
                                    {unitBedroomElems}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });

            let siteActions = [];
            let siteButtons = [];
            if (mode === undefined || mode === SiteMapComponentModes.SELECT) {
                if (siteInventory.length > 0) {
                    siteActions = (
                        <button className={"btn btn-light"} onClick={() => handleCallback(SiteMapComponentActions.INVENTORY_SHOWN, { inventory: siteInventory })}>
                            {Rosetta.string("availability.inventory_count", {count: siteInventory.length}, siteInventory.length)}
                        </button>
                    )
                }
            } else {
                siteActions.push(
                    <button className={"btn btn-light header-action"} onClick={() => handleCallback(SiteMapComponentActions.SITE_EDIT, site)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(editIcon)}} /></button>
                )
                siteActions.push(
                    <button className={"btn btn-light ms-1 header-action"} onClick={() => handleCallback(SiteMapComponentActions.SITE_COPY, site)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(copyIcon)}} /></button>
                )
                siteActions.push(
                    <button className={"btn btn-light ms-1 header-action"} onClick={() => handleCallback(SiteMapComponentActions.SITE_ADD, site)}><span className={"btn-icon"} style={{backgroundImage : ImageUtil.background(addIcon)}} /></button>
                )

                siteButtons = (
                    <div className={"row mb-2"}>
                        <div className={"col-12"}>
                            <button className={"btn btn-outline-dark"} onClick={() => handleCallback(SiteMapComponentActions.INVENTORY_SHOWN, { inventory: siteInventory })}>
                                {Rosetta.string("availability.inventory_count", {count: siteInventory.length}, siteInventory.length)}
                            </button>

                            <button className={"btn btn-outline-dark ms-2"} onClick={() => handleCallback(SiteMapComponentActions.DOCUMENTS_SHOWN, { siteId : site.id, documents : siteDocuments })}>
                                {Rosetta.string("availability.document_count", {count: siteDocuments.length}, siteDocuments.length)}
                            </button>
                        </div>
                    </div>
                );
            }

            return (
                <div className={"row"} key={"site-" + site.id}>
                    <div className={"col-12 text-end"}>
                        <button className={"btn btn-outline-dark"} onClick={() => toggleDisplayMode()}>{Rosetta.string(displayMode === DISPLAY_MODE_NORMAL ? "availability.size_toggle_small" : "availability.size_toggle_large")}</button>
                    </div>

                    <div className={"col-12 mt-2"}>

                        <div className={"card"}>
                            <div className={"card-header text-bg-primary"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        {site.name}
                                    </div>

                                    <div className={"col-12 col-md-6 text-end"}>
                                        {siteActions}
                                    </div>
                                </div>
                            </div>

                            <div className={"card-body"}>
                                {siteButtons}

                                <div className={"row"}>
                                    {unitElems}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        });
    }

    let classExtra = "";
    if (displayMode === DISPLAY_MODE_SMALL) {
        classExtra = " map-small";
    }

    return (
        <div className={"site-map-component" + classExtra}>
            {siteMapElems}
        </div>
    );

}