import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import Rosetta from "../../../../rosetta/Rosetta";
import {Chronos} from "../../../../chronos/Chronos";
import {NavLink} from "react-router-dom";

import "./SiteDocumentViewerModal.css";
import {useEffect} from "react";
import WindowUtil from "../../../../util/WindowUtil";

export const SiteDocumentViewerModal = (props) => {

    const {shown} = props;
    const {siteId} = props;
    const {documents} = props;
    const {callback} = props;

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formatDate(date) {
        try {
            return Chronos.withTimestamp(date).format("dd/MM/yyyy HH:mm");
        } catch (e) {
            console.log(e);
        }
        return "";
    }

    if (!shown) return [];

    let listContent = [];

    if (documents && documents.length > 0) {
        listContent = documents.map((document) => (
            <div className={"list-group-item"}>
                <div className={"date-display"}>{formatDate(document.dateCreated)} - {Rosetta.string("common.name_format", {given_name : document.firstName, family_name : document.lastName })}</div>
                <div><strong>{document.title}</strong></div>
                <div>{document.description}</div>
                <div className={"text-center"}>
                    <a className={"btn btn-primary"} href={document.filePath}>{Rosetta.string("common.view")}</a>
                </div>
            </div>
        ))

        listContent = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"list-group"}>
                        {listContent}
                    </div>
                </div>
            </div>
        )
    } else {
        listContent = (
            <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
        )
    }

    let footerContent = (
        <div className={"text-end"}>
            <NavLink to={"/filing/#t=files"} className={"btn btn-primary"}>{Rosetta.string("site.documents_add")}</NavLink>
        </div>
    )

    return (
        <Offcanvas
            shown={shown}
            title={Rosetta.string("site.documents_title")}
            gravity={OffcanvasGravity.END}
            footerContent={footerContent}
            callback={handleCallback}>

            <div className={"site-document-viewer-modal"}>
                {listContent}
            </div>

        </Offcanvas>
    )

}