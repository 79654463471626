import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Validator from "../../../../util/Validator";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {Toast} from "../../../toast/TokyoToaster";
import {Chronos} from "../../../../chronos/Chronos";
import WindowUtil from "../../../../util/WindowUtil";
import {
    WorkerPlacementSelectionActions,
    WorkerPlacementSelectionList
} from "../../../user/WorkerPlacementSelectionList";
import {CommonUtil} from "../../../../util/CommonUtil";

export const AvailabilityWorkerBookingModal = (props) => {

    const {shown} = props;
    const {id} = props;
    const {unitBedroomBedId} = props;
    const {callback} = props;

    const [record, setRecord] = useState(null);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [listData, setListData] = useState({});

    const [error, setError] = useState(null);

    const [fetchNetworkInFlight, setFetchNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            if (id) {
                fetchOccupancyFromNetwork();
            }
            WindowUtil.lockBodyScroll();
        } else {
            setRecord(null);
            setStartDate(undefined);
            setEndDate(undefined);
            setSelectedUser(null);
            setForceDismiss(false);
            setError(null);
            setSubmitNetworkInFlight(false);
            setFetchNetworkInFlight(false);

            WindowUtil.unlockBodyScroll();
        }
    }, [shown]);

    useEffect(() => {
        if (record) {
            setStartDate(Chronos.withTimestampSeconds(record.startDate).format("yyyy-MM-dd"));
            setEndDate(Chronos.withTimestampSeconds(record.endDate).format("yyyy-MM-dd"));
            setSelectedUser({
                id : record.userId,
                firstName : record.firstName,
                lastName : record.lastName
            });
        }
    }, [record]);

    useEffect(() => {
        const ld = CommonUtil.cloneObject(listData);
        if (startDate && endDate) {
            ld.startDate = Chronos.with(new Date(startDate)).seconds();
            ld.endDate = Chronos.with(new Date(endDate)).seconds();
        } else {
            delete ld.startDate;
            delete ld.endDate;
        }
        setListData(ld);
    }, [startDate, endDate])

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function userSelectionDidCallback(action, data) {
        if (action === WorkerPlacementSelectionActions.SELECT) {
            setSelectedUser(data);
        }
    }

    function fetchOccupancyFromNetwork() {
        if (fetchNetworkInFlight) return;
        setFetchNetworkInFlight(true);

        Axios.post(ENDPOINTS.availability.getBedOccupancy, { id })
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setRecord(resp.data.record);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setFetchNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFetchNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("UBF1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    function submitOccupancyOverNetwork() {
        if (submitNetworkInFlight) return;

        setError(null);

        const validationResult = Validator.validateCreateFormData({
            id : (record ? record.id : undefined),
            unitBedroomBedId,
            startDate, endDate,
            userId : (selectedUser ? selectedUser.id : undefined)
        }, [
            Validator.rule("id", "int", "", "id", true),
            Validator.rule("unitBedroomBedId", "int", "", "unitBedroomBedId", true),
            Validator.rule("userId", "int", Rosetta.string("occupancy_editor.validation_worker"), "userId"),
            Validator.rule("startDate", "timestamp", Rosetta.string("occupancy_editor.start_date"), "startDate"),
            Validator.rule("endDate", "timestamp", Rosetta.string("occupancy_editor.end_date"), "endDate")
        ]);

        if (Chronos.before(new Date(endDate), new Date(startDate))) {
            setError(Rosetta.string("occupancy_editor.validation_date_before"));
            return;
        }

        if (!validationResult.success) {
            setError(validationResult.error);
            return;
        }

        setSubmitNetworkInFlight(true);

        Axios.post(ENDPOINTS.availability.submitBedOccupancy, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("occupancy_editor.submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                setError(API.defaultError("UBO1000C"));
            });
    }

    if (!shown) return [];

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>{error}</div>
                </div>
            </div>
        );
    }

    let footerContent = (
        <div className={"row"}>
            <div className={"col-12 text-center"}>
                <button className={"btn btn-success"} onClick={() => submitOccupancyOverNetwork()}>{Rosetta.string("common.submit")}</button>
                <button className={"btn btn-danger ms-2"} onClick={() => setForceDismiss(true)}>{Rosetta.string("common.cancel")}</button>
            </div>
        </div>
    );

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("occupancy_editor.title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            footerContent={footerContent}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    {Rosetta.string("occupancy_editor.subtitle")}
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("occupancy_editor.start_date")}</label>
                    <input type={"date"} className={"form-control"} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("occupancy_editor.end_date")}</label>
                    <input type={"date"} className={"form-control"} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <label>{Rosetta.string("occupancy_editor.select_worker")}</label>
                <WorkerPlacementSelectionList
                    selection={selectedUser}
                    data={listData}
                    callback={userSelectionDidCallback} />
            </div>

            {errorElem}

        </Offcanvas>
    )

}