import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {CommonUtil} from "../../../../util/CommonUtil";
import {FilterText} from "../../../filter/FilterText";
import {API, ENDPOINTS} from "../../../../network/API";
import {Column} from "../../../table/TableComponent";
import {StringUtil} from "../../../../util/StringUtil";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {Chronos} from "../../../../chronos/Chronos";
import Axios from "axios";
import {Navigator} from "../../../../util/Navigator";
import {CollapsableFilterComponent} from "../../../common/CollapsableFilterComponent";

export const FilingInspectionsComponent = (props) => {

    const [filterSelections, setFilterSelections] = useState({});

    const [filterData, setFData] = useState({});
    const [filterNetworkInFlight, setFilterNetworkInFlight] = useState(false);

    const [tableData, setTableData] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [tableOrdering, setTableOrdering] = useState(undefined);

    const filterColumnClass = "col-6 col-md-4 col-lg-4";

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, []);

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, [filterSelections]);

    useEffect(() => {
        let data = {
            siteIds : getFilterData("siteId", null, true),
            unitIds : getFilterData("unitId", null, true),
            bedroomIds : getFilterData("bedroomId", null, true),
            bedIds : getFilterData("bedId", null, true),
            keyword : getFilterData("keyword", undefined),
            statusIds : getFilterData("statusId", null, true),
            locationIds : getFilterData("locationId", null, true)
        };

        let createdDate = getFilterData("dateRaised", null);
        if (createdDate) {
            data.createdDate = Chronos.with(new Date(createdDate)).seconds();
        }

        let completedDate = getFilterData("dateCompleted", null);
        if (completedDate) {
            data.completedDate = Chronos.with(new Date(completedDate)).seconds();
        }

        setTableData(data);
    }, [filterSelections]);

    function setFilterData(keyName, value) {
        const fSelections = CommonUtil.cloneObject(filterSelections);
        fSelections[keyName] = value;
        setFilterSelections(fSelections);
    }

    function getFilterData(keyName, defaultValue, wrapArray) {
        if (wrapArray === undefined) {
            wrapArray = false;
        }

        let out = CommonUtil.getOrDefault(filterSelections, keyName, defaultValue);
        if (out && wrapArray) {
            if (out !== "-1") {
                out = JSON.stringify([out]);
            } else {
                out = undefined;
            }
        }
        return out;
    }

    function generateFilterSelect(keyName, label, options, optionIdKey, optionLabelKey) {
        if (optionIdKey === undefined) {
            optionIdKey = "id";
        }

        if (optionLabelKey === undefined) {
            optionLabelKey = "name";
        }

        if (!options || !Array.isArray(options)) {
            options = [];
        }

        return (
            <div className={filterColumnClass}>
                <label>{label}</label>
                <select className={"form-select"} value={getFilterData(keyName)} onChange={(e) => setFilterData(keyName, e.target.value)}>
                    <option value={"-1"}>{Rosetta.string("common.please_select")}</option>
                    {options.map((option) => (
                        <option value={option[optionIdKey]}>{option[optionLabelKey]}</option>
                    ))}
                </select>
            </div>
        )
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data);
        } else if (action === AutoTableActions.ROW_CLICKED) {
            if (data) {
                Navigator.navigate("/inspection/" + data.id);
            }
        } else if (action === AutoTableActions.SORT_CHANGE) {
            setTableOrdering(data);
        }
    }

    function formatTableDate(date) {
        try {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return "---";
    }

    function fetchFilterDataFromNetwork() {
        if (filterNetworkInFlight) return;
        setFilterNetworkInFlight(true);

        let data = {
            expanded : true,
            siteIds : getFilterData("siteId", undefined, true),
            unitIds : getFilterData("unitId", undefined, true),
            bedroomIds : getFilterData("bedroomId", undefined, true),
            bedIds : getFilterData("bedId", undefined, false)
        }

        Axios.post(ENDPOINTS.inspection.getFilterData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFData(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setFilterNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterNetworkInFlight(false);
            });
    }

    const issuesOptions = [
        { id : 1, name : Rosetta.string("common.yes") },
        { id : 0, name : Rosetta.string("common.no") }
    ];

    return (
        <div className={"animate-screen-content"}>

            <div className={"row"}>
                <div className={"col-12"}>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <CollapsableFilterComponent>
                                <div className={"row justify-content-center"}>
                                    <div className={filterColumnClass}>
                                        <label>&nbsp;</label>
                                        <FilterText callback={(text) => setFilterData("keyword", text)} />
                                    </div>

                                    {generateFilterSelect("statusId", Rosetta.string("filing.filter_status"), CommonUtil.getOrDefault(filterData, "statuses", []))}
                                    {generateFilterSelect("locationId", Rosetta.string("filing.filter_location"), CommonUtil.getOrDefault(filterData, "locations", []))}
                                    {generateFilterSelect("issues", Rosetta.string("filing.filter_issues_raised"), issuesOptions)}

                                    <div className={filterColumnClass}>
                                        <label>{Rosetta.string("filing.filter_date_raised")}</label>
                                        <input type={"date"} className={"form-control"} value={getFilterData("dateRaised", "")} onChange={(e) => setFilterData("dateRaised", e.target.value)} />
                                    </div>

                                    <div className={filterColumnClass}>
                                        <label>{Rosetta.string("filing.filter_date_complete")}</label>
                                        <input type={"date"} className={"form-control"} value={getFilterData("dateCompleted", "")} onChange={(e) => setFilterData("dateCompleted", e.target.value)} />
                                    </div>

                                    {generateFilterSelect("siteId", Rosetta.string("filing.filter_site"), CommonUtil.getOrDefault(filterData, "sites", []))}
                                    {generateFilterSelect("unitId", Rosetta.string("filing.filter_unit"), CommonUtil.getOrDefault(filterData, "units", []))}
                                    {generateFilterSelect("bedroomId", Rosetta.string("filing.filter_bedroom"), CommonUtil.getOrDefault(filterData, "bedrooms", []))}
                                    {generateFilterSelect("bedId", Rosetta.string("filing.filter_bed"), CommonUtil.getOrDefault(filterData, "beds", []))}
                                </div>
                            </CollapsableFilterComponent>

                            <div className={"row mt-4 justify-content-end"}>
                                <div className={"col-4 col-lg-3"}>
                                    <label>{Rosetta.string("common.table_rows")}</label>
                                    <select className={"form-select"} value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>

                            <div className={"row mt-4"}>
                                <div className={"col-12"}>

                                    <AutoTable
                                        url={ENDPOINTS.inspection.getInspections}
                                        data={tableData}
                                        page={page}
                                        pageSize={pageSize}
                                        ordering={tableOrdering}
                                        callback={tableDidCallback}>

                                        <Column name={"description"} title={Rosetta.string("filing.table_inspection_title")} sortable={true} render={(data, row) => {
                                            return StringUtil.ellipsise(data, 24);
                                        }} />
                                        <Column name={"inspectionStatus"} title={Rosetta.string("filing.table_inspection_status")} sortable={true} className={"text-center"} />
                                        <Column name={"siteName"} title={Rosetta.string("filing.table_inspection_site")} sortable={true} className={"text-center"} />
                                        <Column name={"issuesRaised"} title={Rosetta.string("filing.table_inspection_issues_raised")} className={"text-center"} />
                                        <Column name={"dateCreated"} title={Rosetta.string("filing.table_inspection_date")} className={"text-center"} sortable={true} render={(data, row) => {
                                            return formatTableDate(data);
                                        }} />
                                        <Column name={"requiredDate"} title={Rosetta.string("filing.table_inspection_completion")} className={"text-center"} sortable={true} render={(data, row) => {
                                            return formatTableDate(data);
                                        }} />

                                    </AutoTable>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>
    )

}