import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {CommonUtil} from "../../util/CommonUtil";
import {Container, DateTime, DropDown, Former, FormerActions, FormerValues} from "../form/Former";
import Rosetta from "../../rosetta/Rosetta";

export const SiteFilterFormActions = {
    DATA_CHANGED : "sitefilterform.change"
}

export const SiteFilterForm = (props) => {

    const {callback} = props;
    const {showDates} = props;
    const {formState} = props;

    const [filterData, setFilterData] = useState(null);
    const [filterNetworkInFlight, setFilterNetworkInFlight] = useState(false);

    const [selectionData, setSelectionData] = useState({});

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, []);

    useEffect(() => {
        fetchFilterDataFromNetwork();
        handleCallback(SiteFilterFormActions.DATA_CHANGED, selectionData);
    }, [selectionData]);

    function setFilterProperty(key, value) {
        const fd = CommonUtil.cloneObject(selectionData);

        let hasChanged = true;
        if (fd.hasOwnProperty(key) && fd[key]) {
            hasChanged = fd[key] !== value;
        }

        if (hasChanged) {
            if (key === "siteId") {
                delete fd.unitId;
                delete fd.bedroomId;
                delete fd.bedId;
            } else if (key === "unitId") {
                delete fd.bedroomId;
                delete fd.bedId;
            } else if (key === "bedroomId") {
                delete fd.bedId;
            }
        }

        fd[key] = value;

        setSelectionData(fd);
    }

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.CHANGE) {
            setFilterProperty(data.name, data.value);
        }
    }

    function fetchFilterDataFromNetwork() {
        if (filterNetworkInFlight) return;
        setFilterNetworkInFlight(true);

        let data = {
            siteId : CommonUtil.getOrDefault(selectionData, "siteId", undefined),
            unitId : CommonUtil.getOrDefault(selectionData, "unitId", undefined),
            bedroomId : CommonUtil.getOrDefault(selectionData, "bedroomId", undefined),
            bedId : CommonUtil.getOrDefault(selectionData, "bedId", undefined)
        };

        Axios.post(ENDPOINTS.site.getSiteFilterData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFilterData(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setFilterNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterNetworkInFlight(false);
            });
    }

    let dateElems = [];
    if (showDates) {
        dateElems.push(
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <DateTime name={"startDate"} label={Rosetta.string("site.editor_start_date")} className={"form-control"} />
                </Container>
            </Container>
        )

        dateElems.push(
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <DateTime name={"endDate"} label={Rosetta.string("site.editor_end_date")} className={"form-control"} />
                </Container>
            </Container>
        )
    }

    console.log("Form State:");
    console.log(formState);

    return (
        <div className={"site-filter-form"}>
            <Former
                state={formState ? formState : {}}
                callback={formDidCallback}>

                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <DropDown name={"siteId"} label={Rosetta.string("site.filter_site")} className={"form-select"} value={CommonUtil.getOrDefault(selectionData, "siteId", FormerValues.NOVALUE)}>
                            {
                                CommonUtil.getOrDefault(filterData, "sites", []).map((site) => (
                                    <option value={site.id}>{site.name}</option>
                                ))
                            }
                        </DropDown>
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown name={"unitId"} label={Rosetta.string("site.filter_unit")} className={"form-select"} value={CommonUtil.getOrDefault(selectionData, "unitId", FormerValues.NOVALUE)}>
                            {
                                CommonUtil.getOrDefault(filterData, "units", []).map((unit) => (
                                    <option value={unit.id}>{unit.name}</option>
                                ))
                            }
                        </DropDown>
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown name={"bedroomId"} label={Rosetta.string("site.filter_bedroom")} className={"form-select"} value={CommonUtil.getOrDefault(selectionData, "bedroomId", FormerValues.NOVALUE)}>
                            {
                                CommonUtil.getOrDefault(filterData, "bedrooms", []).map((bedroom) => (
                                    <option value={bedroom.id}>{bedroom.name}</option>
                                ))
                            }
                        </DropDown>
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown name={"bedId"} label={Rosetta.string("site.filter_bed")} className={"form-select"} value={CommonUtil.getOrDefault(selectionData, "bedId", FormerValues.NOVALUE)}>
                            {
                                CommonUtil.getOrDefault(filterData, "beds", []).map((bed) => (
                                    <option value={bed.id}>{bed.name}</option>
                                ))
                            }
                        </DropDown>
                    </Container>
                </Container>

                {dateElems}

            </Former>

        </div>
    )

}