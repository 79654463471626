import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import ArrayUtil from "../../../../util/ArrayUtil";

import "./MessageListScreen.css";
import {StringUtil} from "../../../../util/StringUtil";

import indicatorIcon from "../../../../assets/images/arrow_right.svg";
import {ImageUtil} from "../../../../util/ImageUtil";
import {PlaceholderText} from "../../../placeholder/PlaceholderText";
import {PlaceholderBlock} from "../../../placeholder/PlaceholderBlock";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {Chronos} from "../../../../chronos/Chronos";
import {Navigator} from "../../../../util/Navigator";

export const MessageListScreen = (props) => {

    const MODE_PREPEND = "prepend";
    const MODE_APPEND = "append";

    const pageSize = 20;

    const [messages, setMessages] = useState([]);
    const [messageCount, setMessageCount] = useState(0);

    const [page, setPage] = useState(1);
    const [shouldGetMoreData, setShouldGetMoreData] = useState(true);

    const [messageNetworkInFlight, setMessageNetworkInFlight] = useState(false);

    const [navigateId, setNavigateId] = useState(null);

    useEffect(() => {
        fetchMessagesFromNetwork();
    }, []);

    useEffect(() => {
        fetchMessagesFromNetwork(MODE_APPEND)
    }, [page]);

    useEffect(() => {
        if (navigateId !== null) {
            setTimeout(() => {
                Navigator.navigate("/message/" + navigateId);
            }, 200);
        }
    }, [navigateId]);

    function moveToDetail(id) {
        if (!navigateId) {
            setNavigateId(id);
        }
    }

    function fetchMessagesFromNetwork(mode) {
        if (!mode) {
            mode = MODE_APPEND;
        }

        if (messageNetworkInFlight || (mode === MODE_APPEND && !shouldGetMoreData)) return;
        setMessageNetworkInFlight(true);

        let data = {
            page,
            pageSize,
            ordering : JSON.stringify({ name : "dateCreated", direction : "desc" })
        };

        Axios.post(ENDPOINTS.message.getMessages, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (mode === MODE_APPEND) {
                        if (resp.data.data.length > 0) {
                            setMessages(ArrayUtil.appendArray(messages, resp.data.data, true));
                            setMessageCount(resp.data.count);
                        }

                        if (resp.data.data.length <= pageSize) {
                            setShouldGetMoreData(false);
                        }
                    } else {
                        if (resp.data.data.length > 0) {
                            setMessages(ArrayUtil.prependArray(messages, resp.data.data, true));
                            setMessageCount(messageCount + resp.data.data.length);
                        }
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setMessageNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setMessageNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ML1000C"));
            })
    }

    let messageElems = [];

    if (messages.length > 0) {
        for (let message of messages) {
            let readIcon = [];
            if (parseInt(message.read) === 0) {
                readIcon = (<div className={"circle"} />)
            }

            let date = Chronos.withTimestampSeconds(message.dateCreated).format(Rosetta.string("common.date_time_format_condensed", undefined, undefined, "dd/MM/yy HH:mm"));

            messageElems.push(
                <div className={"message-item"} onClick={() => moveToDetail(message.id)}>
                    <div className={"read-indicator"}>{readIcon}</div>
                    <div className={"main-content"}>
                        <div className={"message-date"}>{date}</div>
                        <div className={"message-title"}>{message.title}</div>
                        <div className={"message-body"}>{StringUtil.ellipsise(message.message, 90)}</div>
                    </div>
                    <div className={"action-indicator"} style={{backgroundImage : ImageUtil.background(indicatorIcon)}} />
                </div>
            )
        }

        if (messageNetworkInFlight) {
            messageElems.push(
                <div className={"text-center mt-4"}>
                    <LoadingSpinner />
                </div>
            )
        } else {
            if (shouldGetMoreData) {
                messageElems.push(
                    <div className={"text-center mt-4"} onClick={() => setPage(page + 1)}>
                        <div className={"btn btn-primary"}>{Rosetta.string("common.load_more")}</div>
                    </div>
                )
            } else {
                messageElems.push(
                    <div className={"message-end"}>
                        {Rosetta.string("messages.end_of_messages")}
                    </div>
                )
            }
        }
    } else if (messageNetworkInFlight) {
        for (let i = 0; i < 3; i++) {
            messageElems.push(
                <div className={"message-item"}>
                    <div className={"read-indicator"}></div>
                    <div className={"main-content"}>
                        <div className={"message-title"}><PlaceholderText/></div>
                        <div className={"message-body"}><PlaceholderBlock/></div>
                    </div>
                    <div className={"action-indicator"} style={{backgroundImage: ImageUtil.background(indicatorIcon)}}/>
                </div>
            )
        }
    } else {
        if (messageElems.length === 0) {
            messageElems.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
                    </div>
                </div>
            )
        }
    }

    let contentMainClassExtra = "";
    if (navigateId !== null) {
        contentMainClassExtra = " message-is-dismissing";
    }

    return (
        <div className={"app-screen message-list-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("messages.title")} />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center animate-screen-content" + contentMainClassExtra} key={messages.length > 0 ? "default_key_1" : "default_key_0"}>
                <div className={"col-12 col-md-8 col-lg-6"}>

                    {messageElems}

                </div>
            </div>

        </div>
    )

}