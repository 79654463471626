import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {CommonUtil} from "../../../../util/CommonUtil";
import {Chronos} from "../../../../chronos/Chronos";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {ImageUtil} from "../../../../util/ImageUtil";

import "./WorkerReportDetailScreen.css";
import {Lightbox, LightboxActions, LightboxImage} from "../../../lightbox/Lightbox";
import {WorkerReport} from "../../../../data/WorkerReport";
import {UserSelectionModal, UserSelectionModalActions, UserSelectionModalModes} from "../../../user/UserSelectionModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {Toast} from "../../../toast/TokyoToaster";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {DataManager} from "../../../../data/DataManager";
import {AppUser} from "../../../../util/AppUser";

export const WorkerReportDetailScreen = (props) => {

    const {id} = useParams();

    const [report, setReport] = useState(null);
    const [reportNetworkInFlight, setReportNetworkInFlight] = useState(false);
    const [assignNetworkInFlight, setAssignNetworkInFlight] = useState(false);

    const [assignedUsers, setAssignedUsers] = useState([]);
    const [requireDate, setRequireDate] = useState(null);

    const [lightboxShown, setLightboxShown] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [lightboxImage, setLightboxImage] = useState(null);

    const [userSelectionShown, setUserSelectionShown] = useState(false);

    const removedUserAssigmentIds = useRef([]);

    useEffect(() => {
        if (id) {
            fetchReportFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (report) {
            if (report.hasOwnProperty("users")) {
                let useUsers = [];
                if (report.users.length > 0) {
                    useUsers = report.users;
                }
                setAssignedUsers(useUsers);
            }

            if (report.hasOwnProperty("requiredDate")) {
                let reqDate = null;
                if (report.requiredDate) {
                    reqDate = Chronos.withTimestampSeconds(report.requiredDate).format("yyyy-MM-dd");
                }
                setRequireDate(reqDate);
            }
        }
    }, [report]);

    function launchLightbox(selectedImage) {
        let images = [];
        if (report.hasOwnProperty("images") && report.images.length > 0) {
            images = report.images.map((image, index) => {
                return LightboxImage(
                    image.imagePath,
                    image.thumbPath,
                    (index + 1)
                )
            })
        }

        setLightboxImage(selectedImage);
        setLightboxImages(images);
        setLightboxShown(true);
    }

    function lightboxDidCallback(action, data) {
        if (action === LightboxActions.CLOSE) {
            setLightboxShown(false);
            setLightboxImages([]);
            setLightboxImage(null);
        }
    }

    function userSelectionDidCallback(action, data) {
        if (action === UserSelectionModalActions.SUBMIT) {
            if (data) {
                let exAssignedUsers = [...assignedUsers];

                let shouldAdd = true;
                for (let user of data) {
                    for (let i = 0; i < exAssignedUsers.length; i++) {
                        if (parseInt(exAssignedUsers[i].userId) === parseInt(user.id)) {
                            shouldAdd = false;
                            break;
                        }
                    }

                    if (shouldAdd) {
                        exAssignedUsers.push({
                            userId: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            roleName: user.roleName
                        });
                    }
                }
                setAssignedUsers(exAssignedUsers);
            }
        } else if (action === OffcanvasActions.CLOSE) {
            setUserSelectionShown(false);
        }
    }

    function removeUserAssignment(user) {
        if (user) {
            let exAssignedUsers = [...assignedUsers];

            for (let i = 0; i < exAssignedUsers.length; i++) {
                if (parseInt(exAssignedUsers[i].userId) === parseInt(user.userId)) {
                    exAssignedUsers.splice(i, 1);
                    break;
                }
            }

            if (user.hasOwnProperty("id") && user.id) {
                removedUserAssigmentIds.current.push(user.id);
            }

            setAssignedUsers(exAssignedUsers);
        }
    }

    function findImagesByType(images, type) {
        let out = [];

        for (let i = 0; i < images.length; i++) {
            if (parseInt(images[i].workerReportImageTypeId) === parseInt(type)) {
                out.push(images[i]);
            }
        }

        return out;
    }

    function promptResetReport() {
        AlertModal.showModal(
            Rosetta.string("user_reports.detail_reset_confirm_title"),
            Rosetta.string("user_reports.detail_reset_confirm_message"),
            [
                AlertModal.button(Rosetta.string("common.accept"), () => {
                    AlertModal.dismissModal();
                    submitReportResetOverNetwork();
                }, "danger"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function fetchReportFromNetwork() {
        if (reportNetworkInFlight) return;
        setReportNetworkInFlight(true);

        const data = {
            ids : JSON.stringify([id]),
            page : 1,
            pageSize : 1,
            fetchImages : true,
            fetchUsers : true
        };

        Axios.post(ENDPOINTS.workerReport.getWorkerReports, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setReport(resp.data.data[0]);
                    } else {
                        AlertModal.showError(Rosetta.string("user_reports.fetch_no_record_found"))
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setReportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setReportNetworkInFlight(false);
                AlertModal.showError(API.defaultError("WRD1000C"));
            });
    }

    function submitUserAssignmentOverNetwork(ignoreWarnings) {
        if (ignoreWarnings === undefined) ignoreWarnings = false;

        if (assignNetworkInFlight) return;

        let selfUser = null;
        let loggedInUser = DataManager.getUser();

        let assignedUserIds = assignedUsers.map((user) => {
            if (parseInt(user.userId) === parseInt(loggedInUser.id)) {
                selfUser = user;
            }

            return user.userId;
        });

        // If the currently logged-in User is in the Assigned User list, show this message.
        // Logic to handle this is on the server.
        if (selfUser !== null && !ignoreWarnings) {
            AlertModal.showModal(
                Rosetta.string("user_reports.detail_self_assignment_title"),
                Rosetta.string("user_reports.detail_self_assignment_message"),
                [
                    AlertModal.button(Rosetta.string("common.accept"), () => {
                        AlertModal.dismissModal();
                        // Call function again, ignore warnings this time.
                        submitUserAssignmentOverNetwork(true);
                    }, "success"),
                    AlertModal.button(Rosetta.string("common.decline"), () => {
                        AlertModal.dismissModal();
                        // Remove user from list
                        removeUserAssignment(selfUser);
                    })
                ]
            );
            return;
        }

        setAssignNetworkInFlight(true);

        const data = {
            workerReportId : id,
            assignedUserIds
        };

        if (requireDate !== null) {
            data.requiredDate = Chronos.with(new Date(requireDate)).seconds();
        }

        Axios.post(ENDPOINTS.workerReport.assignUsersToReport, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.workerReport !== null) {
                        setReport(resp.data.workerReport);
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("user_reports.detail_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setAssignNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAssignNetworkInFlight(false);
                AlertModal.showError(API.defaultError("URDA1000C"));
            });
    }

    function submitReportResetOverNetwork() {
        if (reportNetworkInFlight) return;
        setReportNetworkInFlight(true);

        const data = {
            workerReportId : id
        };

        Axios.post(ENDPOINTS.workerReport.resetWorkerReport, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.workerReport !== null) {
                        setReport(resp.data.workerReport);
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("user_reports.detail_reset_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setReportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setReportNetworkInFlight(false);
                AlertModal.showError(API.defaultError("WRRS1000C"));
            });
    }

    // RENDER

    const showContent = !reportNetworkInFlight && report;

    let photoElem = [];
    let deadlineDateElem = [];
    let completionDateElem = [];

    let statusElem = [];

    if (report) {
        if (report.hasOwnProperty("images") && report.images.length > 0) {
            photoElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("user_reports.detail_images")}</label>
                        <div className={"image-thumbs"}>
                            {report.images.map((image) => {
                                if (parseInt(image.workerReportImageTypeId) === WorkerReport.imageTypes.WORKER) {
                                    return (
                                        <div
                                            className={"image-thumb image-preview"}
                                            style={{backgroundImage: ImageUtil.background(image.imagePath)}}
                                            onClick={() => {
                                                launchLightbox(image);
                                            }}
                                        />
                                    )
                                }

                                return [];
                            })}
                        </div>
                    </div>
                </div>
            )
        }

        if (report.hasOwnProperty("requiredDate") && report.requiredDate !== null) {
            deadlineDateElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("user_reports.detail_deadline")}</label>
                        <div className={"form-control"}>
                            <ContentOrPlaceholder showContent={showContent}>
                                {report ? Chronos.withTimestampSeconds(report.requiredDate).format("dd/MM/yyyy HH:mm") : ""}
                            </ContentOrPlaceholder>
                        </div>
                    </div>
                </div>
            )
        }

        if (report.hasOwnProperty("completionDate") && report.completionDate !== null) {
            completionDateElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("user_reports.detail_completed_date")}</label>
                        <div className={"form-control"}>
                            <ContentOrPlaceholder showContent={showContent}>
                                {report ? Chronos.withTimestampSeconds(report.completionDate).format("dd/MM/yyyy HH:mm") : ""}
                            </ContentOrPlaceholder>
                        </div>
                    </div>
                </div>
            )
        }

        let reportStatusId = parseInt(report.workerReportStatusId);
        if (reportStatusId === WorkerReport.statuses.RAISED) {
            let userElems = (
                <div className={"empty-message"}>{Rosetta.string("user_reports.detail_assigned_users_none")}</div>
            )
            if (assignedUsers.length > 0) {
                userElems = assignedUsers.map((user) => (
                    <li className={"list-group-item d-flex justify-content-between align-items-center"}>
                        <div className={"ms-2 me-auto"}>
                            <div className={"fw-bold"}>{Rosetta.string("common.name_format", { given_name : user.firstName, family_name : user.lastName })}</div>
                            <div>{user.roleName}</div>
                        </div>
                        <span className={"badge bg-danger rounded-pill clickable"} onClick={() => removeUserAssignment(user)}>{Rosetta.string("common.remove")}</span>
                    </li>
                ));

                userElems = (
                    <ul className={"list-group"}>
                        {userElems}
                    </ul>
                )
            }

            let saveButton = (<button className={"btn btn-success"} onClick={() => submitUserAssignmentOverNetwork()}>{Rosetta.string("common.submit")}</button>);
            if (assignNetworkInFlight) {
                saveButton = (
                    <LoadingSpinner small={true} />
                )
            }

            statusElem = (
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>

                                        <div className={"card"}>
                                            <div className={"card-header"}>
                                                <div className={"row align-items-center"}>
                                                    <div className={"col-12 col-md-6"}>
                                                        {Rosetta.string("user_reports.detail_assigned_users")}
                                                    </div>

                                                    <div className={"col-12 col-md-6 text-end"}>
                                                        <button className={"btn btn-primary"} onClick={() => setUserSelectionShown(true)}>{Rosetta.string("user_reports.detail_assigned_users_add")}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"card-body"}>

                                                {userElems}

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("user_reports.detail_required_date")}</label>
                                        <input type={"date"} className={"form-control"} value={requireDate} onChange={(e) => setRequireDate(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-12 text-center"}>
                                        {saveButton}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            )
        } else {
            let completionDateElem = [];
            let actions = [];
            let completionDetailsElem = [];

            if (report.completionDate !== null) {
                completionDateElem = (
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <label>{Rosetta.string("user_reports.detail_completed_date")}</label>
                            <div className={"form-control"}>{Chronos.withTimestampSeconds(report.completionDate).format("dd/MM/yyyy HH:mm")}</div>
                        </div>
                    </div>
                )
            }

            let statusId = parseInt(report.workerReportStatusId);

            if (statusId === WorkerReport.statuses.ACCEPTED || statusId === WorkerReport.statuses.IN_PROGRESS) {
                // Only show a reset button at this point
                actions = (
                    <div className={"row mt-4"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-primary"} onClick={() => promptResetReport()}>
                                {Rosetta.string("user_reports.detail_action_reset")}
                            </button>
                        </div>
                    </div>
                )
            } else if (statusId === WorkerReport.statuses.COMPLETE) {
                // Show full details
                let completionImagesElem = [];

                let completionImages = findImagesByType(report.images, WorkerReport.imageTypes.COMPLETION);
                if (completionImages.length > 0) {
                    completionImagesElem = (
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("user_reports.detail_images")}</label>
                                <div className={"image-thumbs"}>
                                    {completionImages.map((image) => (
                                        <div
                                            className={"image-thumb image-preview"}
                                            style={{backgroundImage: ImageUtil.background(image.imagePath)}}
                                            onClick={() => {
                                                launchLightbox(image);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                }

                completionDetailsElem = (
                    <>
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <strong>{Rosetta.string("user_reports.detail_completion_title")}</strong>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("user_reports.detail_completion_comments")}</label>
                                <div className={"form-control"}>{(report.completionNotes ? report.completionNotes : "")}</div>
                            </div>
                        </div>

                        {completionImagesElem}
                    </>
                )
            }


            statusElem = (
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>

                        <div className={"card"}>
                            <div className={"card-header"}>
                                {Rosetta.string("user_reports.detail_task_status")}
                            </div>

                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("user_reports.detail_status")}</label>
                                        <div className={"form-control"}>{report ? report.workerReportStatus : ""}</div>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("user_reports.detail_assigned_users")}</label>
                                        <div className={"form-control"}>{(report.completionUserId ? Rosetta.string("common.name_format", { given_name : report.completionUserFirstName, family_name : report.completionUserLastName}) : "" )}</div>
                                    </div>
                                </div>

                                {completionDateElem}

                                {completionDetailsElem}

                                {actions}

                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }

    return (
        <div className={"worker-report-detail-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("user_reports.title_reports")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"animate-screen-content"}>

                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-md-8 col-lg-6"}>

                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_status")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {report ? report.workerReportStatus : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_location")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {report ? CommonUtil.implode([ report.siteName, report.unitName, report.bedroomName, report.bedName ]) : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_comment")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {report ? report.description : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    {photoElem}

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_reported_by")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {report ? Rosetta.string("common.name_format", { given_name : report.firstName, family_name : report.lastName }) : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_date")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {report ? Chronos.withTimestampSeconds(report.dateCreated).format("dd/MM/yyyy HH:mm") : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    {deadlineDateElem}

                                    {completionDateElem}

                                </div>
                            </div>

                        </div>
                    </div>

                    {statusElem}

                </div>

            </div>

            <Lightbox
                shown={lightboxShown}
                images={lightboxImages}
                initialImage={lightboxImage}
                callback={lightboxDidCallback} />

            <UserSelectionModal
                shown={userSelectionShown}
                userRoleIds={AppUser.getNonWorkerRoles()}
                mode={UserSelectionModalModes.MULTI}
                callback={userSelectionDidCallback} />

        </div>
    )

}