import {UrlUtil} from "./UrlUtil";

export const VideoUtil = {
    getYouTubeVideoId : (uri) => {
        let params = UrlUtil.getQueryParameters(uri);
        if (params.hasOwnProperty("v")) {
            return params.v;
        }
        return null;
    }
};