import {useParams} from "react-router-dom";
import {useState} from "react";
import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {Chronos} from "../../../../chronos/Chronos";
import {StringUtil} from "../../../../util/StringUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";

import "./MessageDetailScreen.css";

export const MessageDetailScreen = (props) => {

    const {messageId} = useParams();

    const [message, setMessage] = useState(null);
    const [messageNetworkInFlight, setMessageNetworkInFlight] = useState(false);

    useState(() => {
        if (messageId) {
            fetchMessageFromNetwork();
        }
    }, []);

    function fetchMessageFromNetwork() {
        if (messageNetworkInFlight) return;
        setMessageNetworkInFlight(true);

        let data = {
            page : 1,
            pageSize : 1,
            ids : [messageId]
        };

        Axios.post(ENDPOINTS.message.getMessages, data)
            .then((r) => {
                let error = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        const message = resp.data.data[0];
                        setMessage(message);

                        // Check message is unread. If so, mark read now.
                        if (parseInt(message.read) === 0) {
                            readMessageOverNetwork();
                        }
                    } else {
                        error = API.defaultError("MD1001C");
                    }
                } else {
                    error = API.formatError(resp);
                }

                if (error !== null) {
                    AlertModal.showError(error);
                }

                setMessageNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setMessageNetworkInFlight(false);
                AlertModal.showError(API.defaultError("MD1000C"));
            })
    }

    function readMessageOverNetwork() {
        const data = {
            messageIds : JSON.stringify([messageId])
        };

        Axios.post(ENDPOINTS.message.readMessages, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    console.log("MESSAGE MARKED READ");
                } else {
                    console.log("FAILED TO MARK READ.");
                    console.log(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log("EXCEPTION WHEN MARKING READ");
                console.log(e);
            });
    }

    // RENDER

    const showContent = !messageNetworkInFlight && message !== null;

    return (
        <div className={"app-screen message-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("messages.title")} />
                </div>
            </div>

            <div className={"row justify-content-center message-screen-did-appear"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <div className={"row"}>
                        <div className={"col-12 message-date"}>
                            <ContentOrPlaceholder showContent={showContent}>
                                {message ? Chronos.withTimestampSeconds(message.dateCreated).format(Rosetta.string("common.date_format_long", undefined, undefined, "dd MMMM yyyy HH:mm")) : "" }
                            </ContentOrPlaceholder>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 message-title"}>
                            <ContentOrPlaceholder showContent={showContent}>
                                {message ? message.title : ""}
                            </ContentOrPlaceholder>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 message-body"}>
                            <ContentOrPlaceholder showcontent={showContent}>
                                {message ? StringUtil.wrapLines(message.message, "p") : ""}
                            </ContentOrPlaceholder>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}