import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import {Button, Container, Former, FormerActions, Text} from "../../../form/Former";
import Rosetta from "../../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Toast} from "../../../toast/TokyoToaster";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import WindowUtil from "../../../../util/WindowUtil";

export const SiteEditorModal = (props) => {

    const {shown} = props;
    const {siteId} = props;
    const {callback} = props;

    const [site, setSite] = useState(null)
    const [siteNetworkInFlight, setSiteNetworkInFlight] = useState(false);

    const [formState, setFormState] = useState({});

    const [forceDismiss, setForceDismiss] = useState(false);

    const [deleting, setDeleting] = useState(false);
    const [deleteInput, setDeleteInput] = useState("");

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            if (siteId) {
                fetchSiteFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();

            setSite(null);
            setSiteNetworkInFlight(false);
            setForceDismiss(false);
            setDeleteInput("");
            setDeleting(false);
        }
    }, [shown]);

    useEffect(() => {
        let formState = {};
        if (site) {
            formState = site;
        }
        setFormState(formState);
    }, [site]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formDidCallback(action, data) {
        if (FormerActions.SUBMIT) {
            if (data.success) {
                submitSiteOverNetwork(data.data);
            }
        }
    }

    function fetchSiteFromNetwork() {
        if (siteNetworkInFlight) return;
        setSiteNetworkInFlight(true);

        const data = {
            siteId,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.site.getSites, data)
            .then((r) => {
                let errorMessage = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setSite(resp.data.data[0]);
                    } else {
                        errorMessage = API.defaultError("SEF1001C");
                    }
                } else {
                    errorMessage = API.formatError(resp);
                }

                if (errorMessage !== null) {
                    AlertModal.showError(errorMessage);
                }

                setSiteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSiteNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SEF1000C"));
            });
    }

    function submitSiteOverNetwork(data) {
        if (siteNetworkInFlight || !data) return;
        setSiteNetworkInFlight(true);

        if (site) {
            data.id = site.id;
        }

        Axios.post(ENDPOINTS.site.submitSite, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setForceDismiss(true);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("site.editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSiteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSiteNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SES1000C"));
            });
    }

    function deleteSiteOverNetwork() {
        if (siteNetworkInFlight) return;
        setSiteNetworkInFlight(true);

        const data = {
            siteId : site.id
        };

        Axios.post(ENDPOINTS.site.deleteSite, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("site.editor_delete_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSiteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSiteNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SDS1000C"));
            });
    }

    if (!shown) return [];

    let submitButton = (<Button label={Rosetta.string("common.save")} className={"btn btn-success"} />);
    if (siteNetworkInFlight) {
        submitButton = (<LoadingSpinner small={true} inline={true} />)
    }

    let deleteButton = [];
    let deleteElem = [];
    if (site) {
        deleteButton = (
            <button className={"btn btn-danger"}
                    onClick={() => setDeleting(!deleting)}>{Rosetta.string("common.delete")}</button>
        )

        if (deleting) {
            let deleteSubmitButton = (<button className={"btn btn-light"} onClick={() => deleteSiteOverNetwork()}>{Rosetta.string("site.editor_delete_submit")}</button>);
            if (deleteInput !== site.name) {
                deleteSubmitButton = (<button className={"btn btn-light disabled"}>{Rosetta.string("site.editor_delete_submit")}</button>);
            }

            deleteElem = (
                <div className={"row mt-4 text-center"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-danger"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <strong>{Rosetta.string("site.editor_delete_title")}</strong>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {Rosetta.string("site.editor_delete_message", {site_name: site ? site.name : ""})}
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <input type={"text"} className={"form-control text-center"} value={deleteInput} onChange={(e) => setDeleteInput(e.target.value)} placeholder={site ? site.name : ""}/>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {deleteSubmitButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("site.editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <Former
                state={formState}
                callback={formDidCallback}>

                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Text name={"name"} label={Rosetta.string("site.editor_name")} className={"form-control"} mandatory={true} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text name={"addressLine1"} label={Rosetta.string("site.editor_address1")} className={"form-control"} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text name={"addressLine2"} label={Rosetta.string("site.editor_address2")} className={"form-control"} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text name={"addressTown"} label={Rosetta.string("site.editor_city")} className={"form-control"} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text name={"addressCounty"} label={Rosetta.string("site.editor_county")} className={"form-control"} />
                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text name={"addressPostcode"} label={Rosetta.string("site.editor_postcode")} className={"form-control"} />
                    </Container>
                </Container>

                <Container className={"row mt-4"}>
                    <Container className={"col-12 text-center"}>
                        {submitButton}
                    </Container>
                </Container>

            </Former>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {deleteButton}
                </div>
            </div>

            {deleteElem}

        </Offcanvas>


    )

}