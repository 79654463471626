import Rosetta from "../rosetta/Rosetta";
import {CommonUtil} from "./CommonUtil";

export const TimeUtil = {

    YEAR : 31536000,
    MONTH : 2628000,
    WEEK : 604800,
    DAY : 86400,
    HOUR : 3600,
    MINUTE : 60,

    prettyFormatTimeDuration : (seconds, options) => {
        let out = [];

        let top = null;
        let includeSeconds = true;

        if (options !== undefined) {
            if (options.hasOwnProperty("top")) {
                top = options.top;
            }

            if (options.hasOwnProperty("includeSeconds")) {
                includeSeconds = options.includeSeconds;
            }
        }

        if (seconds >= TimeUtil.YEAR) {
            let years = Math.floor(seconds / TimeUtil.YEAR);
            out.push(Rosetta.string("time.years", {years}, years));
            seconds -= (TimeUtil.YEAR * years);
        }

        if (seconds >= TimeUtil.MONTH) {
            let months = Math.floor(seconds / TimeUtil.MONTH);
            out.push(Rosetta.string("time.months", {months}, months));
            seconds -= (TimeUtil.MONTH * months);
        }

        if (seconds >= TimeUtil.WEEK) {
            let weeks = Math.floor(seconds / TimeUtil.WEEK);
            out.push(Rosetta.string("time.weeks", {weeks}, weeks));
            seconds -= (TimeUtil.WEEK * weeks);
        }

        if (seconds >= TimeUtil.DAY) {
            let days = Math.floor(seconds / TimeUtil.DAY);
            out.push(Rosetta.string("time.days", {days}, days));
            seconds -= (TimeUtil.DAY * days);
        }

        if (seconds >= TimeUtil.HOUR) {
            let hours = Math.floor(seconds / TimeUtil.HOUR);
            out.push(Rosetta.string("time.hours", {hours}, hours));
            seconds -= (TimeUtil.HOUR * hours);
        }

        if (seconds >= TimeUtil.MINUTE) {
            let minutes = Math.floor(seconds / TimeUtil.MINUTE);
            out.push(Rosetta.string("time.minutes", {minutes}, minutes));
            seconds -= (TimeUtil.MINUTE * minutes);
        }

        if ((includeSeconds || out.length === 0) && seconds > 0) {
            out.push(Rosetta.string("time.seconds", seconds, seconds));
        }

        if (top !== null) {
            if (top > out.length) {
                out = out.slice(0, top);
            }
        }

        return CommonUtil.implode(out, ", ");
    },

    parseTimeDuration : (string, defaultValue) => {
        let out = "";

        if (string && string.length > 1) {
            let numeric = parseInt(string.substring(0, string.length - 1));
            let endStr = string.substring(string.length - 1, string.length);

            switch (endStr) {
                case "D":
                    out += Rosetta.string("time.days", { days : numeric}, numeric);
                    break;
                case "M":
                    out += Rosetta.string("time.months", { months : numeric }, numeric);
                    break;
                case "W":
                    out += Rosetta.string("time.weeks", { weeks : numeric }, numeric);
                    break;
                case "Y":
                    out += Rosetta.string("time.years", { years : numeric }, numeric);
                    break;
                default:
                    out += "";
            }
        }

        if (out === "" && defaultValue) {
            out = defaultValue;
        }

        return out;
    },

}