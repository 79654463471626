import {Button, Container, File, Former, FormerActions, Text} from "../../../form/Former";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";

export const WorkerReportEntryDescriptionScreen = (props) => {

    const {callback} = props;
    const {images} = props;

    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        if (images) {
            console.log("SET INITIAL STATE");
            let newState = {
                images
            };
            setInitialState(newState);
        }
    }, []);

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                if (callback) {
                    callback("submit", data.data);
                }
            }
        }
    }

    return (
        <div className={"worker-report-entry-description-screen"}>
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>

                    <div className={"row mb-4"}>
                        <div className={"col-12"}>
                            <h3>{Rosetta.string("worker_report.step_2_title")}</h3>
                        </div>
                    </div>

                    <Former
                        callback={formDidCallback}
                        state={initialState}>

                        <Container className={"row"}>
                            <Container className={"col-12"}>
                                <File
                                    name={"images"}
                                    multiSelect={true}
                                    preview={"image"}
                                    previewRowLength={3}
                                    className={"text-center"}
                                    buttonClassName={"btn btn-primary"}
                                    previewClassName={"image-preview center-crop ratio ratio-1x1 me-1 mb-1"}
                                    buttonLabel={Rosetta.string("worker_report.step_2_photo_take_another")} />
                            </Container>
                        </Container>

                        <Container className={"row mt-2"}>
                            <Container className={"col-12"}>
                                <Text
                                    name={"description"}
                                    label={Rosetta.string("worker_report.step_2_description")}
                                    className={"form-control"}
                                    multiline={true}
                                    mandatory={true} />
                            </Container>
                        </Container>

                        <Container className={"row mt-4"}>
                            <Container className={"col-12 text-center"}>
                                <Button
                                    className={"btn btn-primary"}
                                    label={Rosetta.string("worker_report.step_2_submit")} />
                            </Container>
                        </Container>

                    </Former>

                </div>
            </div>
        </div>
    )

}