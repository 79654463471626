import Rosetta from "../../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {useEffect, useState} from "react";
import {Chronos} from "../../../../chronos/Chronos";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {SegmentTab, SegmentTabBar, SegmentTabBarActions} from "../../../segmenttab/SegmentTabBar";
import {SiteMapComponent, SiteMapComponentActions} from "../../../site/SiteMapComponent";
import {SiteFilterForm, SiteFilterFormActions} from "../../../site/SiteFilterForm";
import {CommonUtil} from "../../../../util/CommonUtil";
import {UnitInventoryViewerModal} from "../availability/UnitInventoryViewerModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {Toast} from "../../../toast/TokyoToaster";
import {
    BookingReplacementConfirmationActions,
    BookingReplacementConfirmationModal
} from "./BookingReplacementConfirmationModal";
import {UserMessagesModal, UserMessagesModalActions} from "../messages/UserMessagesModal";

import "./BookingDetailScreen.css";
import {GatewayUtil} from "../../../../util/GatewayUtil";
import {DateUtil} from "../../../../util/DateUtil";
import {BookingBedOccupancyEditorActions, BookingBedOccupancyEditorModal} from "./BookingBedOccupancyEditorModal";

export const BookingDetailScreen = () => {

    const {id} = useParams()

    const VIEW_MODE_FORM = 1;
    const VIEW_MODE_MAP = 2;

    const [placement, setPlacement] = useState();
    const [placementNetworkInFlight, setPlacementNetworkInFlight] = useState(false);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [viewMode, setViewMode] = useState(VIEW_MODE_FORM);

    const [availabilityData, setAvailabilityDate] = useState({});
    const [availabilityNetworkInFlight, setAvailabilityNetworkInFlight] = useState(false);
    const [availabilityResult, setAvailabilityResult] = useState(null);

    const [inventoryModalShown, setInventoryModalShown] = useState(false);
    const [inventoryData, setInventoryData] = useState(null);

    const [replaceAvailabilityShown, setReplaceAvailabilityShown] = useState(false);
    const [replaceAvailabilityData, setReplaceAvailabilityData] = useState(null);
    const [replaceAvailabilityBedId, setReplaceAvailabilityBedId] = useState(undefined);

    const [userMessagesShown, setUserMessagesShown] = useState(false);
    const [userMessageUser, setUserMessageUser] = useState(null);

    const [occupancyEditorShown, setOccupancyEditorShown] = useState(false);
    const [occupancyEditorBed, setOccupancyEditorBed] = useState(undefined);
    const [occupancyEditorStartDate, setOccupancyEditorStartDate] = useState(undefined);
    const [occupancyEditorEndDate, setOccupancyEditorEndDate] = useState(undefined);
    const [occupancyEditorPlacementId, setOccupancyEditorPlacementId] = useState(undefined);

    useEffect(() => {
        fetchPlacementFromNetwork();
    }, []);

    useEffect(() => {
        const requiredKeys = ["siteId", "unitId", "bedroomId", "bedId"];

        let processNetwork = true;
        for (const key of requiredKeys) {
            if (!availabilityData.hasOwnProperty(key) || !availabilityData[key]) {
                processNetwork = false;
                break;
            }
        }

        if (processNetwork) {
            fetchBedAvailabilityFromNetwork();
        }
    }, [availabilityData]);

    function formatDate(date) {
        try {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return "";
    }

    function tabBarDidCallback(action, data) {
        if (action === SegmentTabBarActions.SELECT) {
            setViewMode(data);
        }
    }

    function siteMapDidCallback(action, data) {
        if (action === SiteMapComponentActions.INVENTORY_SHOWN) {
            let inventoryArray = data;
            if (data.hasOwnProperty("inventory")) {
                inventoryArray = data.inventory;
            }
            showInventoryModal(inventoryArray);
        } else if (action === SiteMapComponentActions.BED_CLICKED) {
            if (placement) {
                if (parseInt(data.bedId) === parseInt(placement.unitBedroomBedId)) {
                    AlertModal.showModal(
                        Rosetta.string("booking.detail_assign_already_assigned_prompt"),
                        Rosetta.string("booking.detail_assing_already_assigned_message"),
                        [
                            AlertModal.button(Rosetta.string("common.ok"), () => {
                                AlertModal.dismissModal();
                            })
                        ]
                    )
                    return;
                }
            }

            setOccupancyEditorBed(data);
            setOccupancyEditorPlacementId(placement.id);
            setOccupancyEditorStartDate(placement.startDate);
            setOccupancyEditorEndDate(placement.endDate);
            setOccupancyEditorShown(true);

            // AlertModal.showModal(
            //     Rosetta.string("booking.detail_assign_confirm_prompt"),
            //     Rosetta.string("booking.detail_assign_confirm_message"),
            //     [
            //         // TODO Prompt for Start/End Date
            //
            //         AlertModal.button(
            //             Rosetta.string("booking.detail_assign_confirm_prompt"),
            //             () => {
            //                 // TODO Start/End Dates
            //                 submitBedOccupancyOverNetwork(data.bedId);
            //                 AlertModal.dismissModal();
            //             },
            //             "success"
            //         ),
            //         AlertModal.button(
            //             Rosetta.string("common.cancel"),
            //             () => {
            //                 AlertModal.dismissModal();
            //             }
            //         )
            //     ]
            // )

        }
    }

    function formDidCallback(action, data) {
        if (action === SiteFilterFormActions.DATA_CHANGED) {
            setAvailabilityDate(data);
        }
    }

    function showInventoryModal(data) {
        setInventoryData(data);
        setInventoryModalShown(true);
    }

    function inventoryDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setInventoryModalShown(false);
        }
    }

    function showReplaceAvailabilityModal(data) {
        setReplaceAvailabilityData(data);
        setReplaceAvailabilityShown(true);
    }

    function replaceAvailabilityModalDidCallback(action, data) {
        if (action === BookingReplacementConfirmationActions.CLOSE) {
            setReplaceAvailabilityShown(false);

            if (replaceAvailabilityBedId && data) {
                // TODO Start/End Dates
                submitBedOccupancyOverNetwork(replaceAvailabilityBedId, undefined, undefined, data.unitBedroomBedOccupancyId);
            }
        }
    }

    function showUserMessagesDialog() {
        setUserMessageUser({
            id : placement ? placement.userId : undefined,
            firstName : placement ? placement.firstName : "",
            lastName : placement ? placement.lastName : ""
        });
        setUserMessagesShown(true);
    }

    function userMessagesDidCallback(action, data) {
        if (UserMessagesModalActions.CLOSE) {
            setUserMessagesShown(false);
        }
    }

    function formDidSubmit() {
        if (availabilityData.hasOwnProperty("bedId") && availabilityData.bedId) {
            let startDate = placement.startDate;
            let endDate = placement.endDate;

            try {
                if (availabilityData.startDate !== undefined) {
                    let startDateParse = Chronos.parse(availabilityData.startDate).seconds();
                    if (!isNaN(startDateParse)) {
                        startDate = startDateParse;
                    }
                }
                if (availabilityData.endDate !== undefined) {
                    let endDateParse = Chronos.parse(availabilityData.endDate).seconds();
                    if (!isNaN(endDateParse)) {
                        endDate = endDateParse;
                    }
                }
            } catch (e) {
                console.log(e);
            }

            submitBedOccupancyOverNetwork(availabilityData.bedId, startDate, endDate);
        }
    }

    function goToGatewayPlacement() {
        if (placement) {
            GatewayUtil.goToGateway("placement/details/" + placement.gatewayId);
        }
    }

    function occupancyEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setOccupancyEditorShown(false);
        } else if (action === BookingBedOccupancyEditorActions.SUBMIT) {
            submitBedOccupancyOverNetwork(
                data.bed.bedId,
                data.startDate,
                data.endDate,
                undefined,
                data.placementId
            );
        }
    }

    function fetchPlacementFromNetwork() {
        if (placementNetworkInFlight) return;
        setPlacementNetworkInFlight(true);

        let data = {
            id
        }

        Axios.post(ENDPOINTS.booking.getPlacement, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPlacement(resp.data.placement);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setPlacementNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPlacementNetworkInFlight(false);
                AlertModal.showError(API.defaultError("PDF1000C"));
            })
    }

    function fetchBedAvailabilityFromNetwork() {
        if (availabilityNetworkInFlight) return;
        setAvailabilityNetworkInFlight(true);

        let startDate = placement.startDate;
        let endDate = placement.endDate;

        if (availabilityData.startDate !== undefined) {
            try {
                startDate = Chronos.parse(availabilityData.startDate).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        if (availabilityData.endDate !== undefined) {
            try {
                endDate = Chronos.parse(availabilityData.endDate).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        const data = CommonUtil.cloneObject(availabilityData);
        data.startDate = startDate;
        data.endDate = endDate;

        Axios.post(ENDPOINTS.site.getSiteBedAvailability, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setAvailabilityResult(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setAvailabilityNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAvailabilityNetworkInFlight(false);
            });
    }

    function submitBedOccupancyOverNetwork(bedId, startDate, endDate, replaceOccupationId, userPlacementId) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        if (startDate === undefined) {
            startDate = placement.startDate;
        }

        if (endDate === undefined) {
            endDate = placement.endDate;
        }

        if (isNaN(startDate)) {
            try {
                startDate = Chronos.parse(startDate).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        if (isNaN(endDate)) {
            try {
                endDate = Chronos.parse(endDate).seconds();
            } catch(e) {
                console.log(e);
            }
        }

        console.log("START DATE", startDate);
        console.log("END DATE", endDate);

        const data = {
            userId : placement.userId,
            unitBedroomBedId : bedId,
            startDate : startDate,
            endDate : endDate,
            replaceOccupationId,
            userPlacementId
        }

        // Flag API to handle a replacement process if selected bed is filled
        // if (placement.unitBedroomBedOccupancyId) {
        //     data.id = placement.unitBedroomBedOccupancyId;
        //     data.allowReplace = true;
        // }

        Axios.post(ENDPOINTS.availability.submitBedOccupancy, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.requiresAction) {
                        console.log("SUBMISSION REQUIRES ACTION");
                        setReplaceAvailabilityBedId(bedId);
                        showReplaceAvailabilityModal(resp.data.availability);
                    } else {
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("booking.detail_assignment_confirm"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                        fetchPlacementFromNetwork();
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("BDS2000C"));
            })
    }

    // RENDER

    const showContent = !placementNetworkInFlight && placement;

    let pageTitle = Rosetta.string("booking.booking_title");

    let bookingElem = [];

    let placementElems = [];
    let placementClass = "col-12 col-lg-10 col-xl-8";

    if (showContent) {
        if (placement) {
            let placementTitle = Rosetta.string("booking.detail_book");
            // if (placement.unitBedroomBedOccupancyId) {
            //     placementTitle = Rosetta.string("booking.detail_replace");
            // }

            let placementContent = [];
            if (viewMode === VIEW_MODE_FORM) {
                let conflictElems = [];

                if (availabilityResult && availabilityResult.length > 0) {
                    conflictElems = availabilityResult.map((availability) => (
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>

                                <div className={"card card-danger"}>
                                    <div className={"card-header"}>
                                        {Rosetta.string("booking.detail_conflict_title")}
                                    </div>
                                    <div className={"card-body"}>
                                        {Rosetta.string("booking.detail_conflict_message", { start_date : formatDate(availability.nextStartDate), end_date : formatDate(availability.nextEndDate) })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))
                }

                let submitButton = (<button className={"btn btn-success disabled"}>{Rosetta.string("common.save")}</button>);
                if (availabilityData.hasOwnProperty("bedId") && availabilityData.bedId) {
                    submitButton = (<button className={"btn btn-success"} onClick={() => formDidSubmit()}>{Rosetta.string("common.save")}</button>);
                }

                placementContent = (
                    <>
                        <SiteFilterForm
                            showDates={true}
                            formState={{
                                startDate : availabilityData.startDate ? availabilityData.startDate : DateUtil.secondsToDateString(placement.startDate),
                                endDate : availabilityData.endDate ? availabilityData.endDate : DateUtil.secondsToDateString(placement.endDate)
                            }}
                            callback={formDidCallback} />

                        {conflictElems}

                        <div className={"row mt-4"}>
                            <div className={"col-12 text-center"}>
                                {submitButton}
                            </div>
                        </div>
                    </>

                )
            } else {
                placementClass = "col-12";
                placementContent = (
                    <SiteMapComponent
                        startDate={placement.startDate}
                        endDate={placement.endDate}
                        returnObject={true}
                        callback={siteMapDidCallback} />
                )
            }

            placementElems = [
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <h3>{placementTitle}</h3>
                    </div>
                </div>,


                <div className={"row mt-4 justify-content-center"}>
                    <div className={placementClass + " text-center"}>

                        <SegmentTabBar
                            className={"hops-segment-tab-bar"}
                            selected={viewMode}
                            callback={tabBarDidCallback}>

                            <SegmentTab id={VIEW_MODE_FORM} label={Rosetta.string("booking.detail_view_form")} />
                            <SegmentTab id={VIEW_MODE_MAP} label={Rosetta.string("booking.detail_view_map")} />

                        </SegmentTabBar>

                    </div>

                    <div className={placementClass}>

                        <div className={"card"}>
                            <div className={"card-body"}>
                                {placementContent}
                            </div>
                        </div>

                    </div>
                </div>
            ]
        }

        if (placement.hasOwnProperty("occupancy")) { // && placement.occupancy.length > 0
            let occupancyElems = [];

            let placementTime = parseInt(placement.endDate) - parseInt(placement.startDate);
            let totalTime = 0;

            let lastEndDate = parseInt(placement.startDate);
            let greatestEndDate = 0;

            let occupancyIndex = 0;

            for (let occupancy of placement.occupancy) {
                occupancyIndex++;

                let formattedLastDate = "???";
                let formattedStartDate = "???";
                let formattedEndDate = "???";

                greatestEndDate = Math.max(occupancy.endDate, greatestEndDate);

                try {
                    formattedLastDate = Chronos.withTimestampSeconds(lastEndDate).format("dd/MM/yy");
                    formattedStartDate = Chronos.withTimestampSeconds(occupancy.startDate).format("dd/MM/yy");
                    formattedEndDate = Chronos.withTimestampSeconds(occupancy.endDate).format("dd/MM/yy");
                } catch (e) {
                    console.log(e);
                }

                if (lastEndDate !== null) {
                    let daysBetween = Chronos.withTimestampSeconds(occupancy.startDate).difference(Chronos.withTimestampSeconds(lastEndDate).getDate(), Chronos.DAYS);
                    if (daysBetween > 0 && occupancy.endDate >= greatestEndDate) {
                        occupancyElems.push(
                            <div className={"placement-occupancy"}>
                                <div className={"occupancy-container placement-occupancy-message warning"}>
                                    {Rosetta.string("booking.detail_occupancy_missing", { days : daysBetween, startDate : formattedLastDate, endDate : formattedStartDate })}
                                </div>

                                <div className={"timeline-line unfilled"} />
                                <div className={"timeline-indicator unfilled"} />
                            </div>
                        )
                    }
                }

                let dateTitle = Rosetta.string("booking.detail_occupancy_date_format", { startDate : formattedStartDate, endDate : formattedEndDate });

                occupancyElems.push(
                    <div className={"placement-occupancy"}>
                        <div className={"occupancy-date"}>
                            {dateTitle}
                        </div>
                        <div className={"occupancy-container"}>
                            <div className={"booking-heading"}>{Rosetta.string("booking.detail_assignment_booking_title", { number : occupancyIndex })}</div>
                            <div className={"site-unit-title"}>{occupancy.siteName} - {occupancy.unitName}</div>
                            <div className={"detail-line"}><strong>{Rosetta.string("booking.detail_assignment_details_bedroom")}</strong>: {occupancy.unitBedroomName}</div>
                            <div className={"detail-line"}><strong>{Rosetta.string("booking.detail_assignment_details_bed")}</strong>: {occupancy.unitBedroomBedName}</div>
                        </div>

                        <div className={"timeline-line"} />
                        <div className={"timeline-indicator"} />
                    </div>
                )

                let marginStart = 0;
                let endDate = 0;

                if (occupancy.startDate < placement.startDate) {
                    marginStart = Math.max(0, placement.startDate - occupancy.startDate);
                }

                if (occupancy.endDate > placement.endDate) {
                    endDate = Math.max(0, occupancy.endDate - placement.endDate);
                }

                let applicableTime = (occupancy.endDate - occupancy.startDate) - marginStart - endDate;

                console.log("AT: " + applicableTime + " :: MS: " + marginStart + " :: ED: " + endDate);

                totalTime += applicableTime;
                lastEndDate = occupancy.endDate;
            }

            let daysBetween = Chronos.withTimestampSeconds(placement.endDate).difference(Chronos.withTimestampSeconds(lastEndDate).getDate(), Chronos.DAYS);
            if (daysBetween > 0 && lastEndDate >= greatestEndDate) {
                let formattedLastDate = "???";
                let formattedEndDate = "???";
                try {
                    formattedLastDate = Chronos.withTimestampSeconds(lastEndDate).format("dd/MM/yy");
                    formattedEndDate = Chronos.withTimestampSeconds(placement.endDate).format("dd/MM/yy");
                } catch (e) {
                    console.log(e);
                }

                occupancyElems.push(
                    <div className={"placement-occupancy"}>
                        <div className={"occupancy-container placement-occupancy-message warning"}>
                            {Rosetta.string("booking.detail_occupancy_missing", { days : daysBetween, startDate : formattedLastDate, endDate : formattedEndDate })}
                        </div>

                        <div className={"timeline-line unfilled"} />
                        <div className={"timeline-indicator unfilled"} />
                    </div>
                )
            }

            if (totalTime < placementTime) {
                let remainingTime = placementTime - totalTime;
                let currentTime = new Date().getTime() / 1000;
                let remainChronos = Chronos.withTimestampSeconds(currentTime + remainingTime).difference(new Date(), Chronos.DAYS) + 1;

                // console.log("RT: " + remainingTime);
                // console.log("CT: " + currentTime);
                // console.log("RC: " + remainChronos);

                occupancyElems.push(
                    <div className={"placement-occupancy"}>
                        <div className={"occupancy-container placement-occupancy-message warning"}>
                            {Rosetta.string("booking.detail_occupancy_remaining", { days : remainChronos})}
                        </div>

                        <div className={"timeline-line unfilled"} />
                        <div className={"timeline-indicator unfilled"} />
                    </div>
                )
            } else {
                occupancyElems.push(
                    <div className={"placement-occupancy"}>
                        <div className={"occupancy-container placement-occupancy-message success"}>
                            {Rosetta.string("booking.detail_occupancy_complete")}
                        </div>

                        <div className={"timeline-line"} />
                        <div className={"timeline-indicator"} />
                    </div>
                )
            }

            if (occupancyElems.length > 0) {
                bookingElem = (
                    <div className={"row justify-content-center booking-occupancy"}>
                        <div className={"col-12 text-center"}>
                            <h3>{Rosetta.string("booking.occupancy_title")}</h3>
                        </div>

                        <div className={"col-12 col-md-6 col-lg-4 mt-4"}>
                            {occupancyElems}
                        </div>
                    </div>
                );
            }
        }
    }

    return (
        <div className={"booking-detail-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{pageTitle}</h1>
                        </div>
                    </div>
                </div>

                <div className={"row justify-content-center mt-4"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>

                        <div className={"card"}>
                            <div className={"card-header bg-light text-right"}>
                                <button className={"btn btn-primary"} onClick={() => showUserMessagesDialog()}>{Rosetta.string("booking.detail_view_messages")}</button>
                                <button className={"btn btn-primary ms-1"} onClick={() => goToGatewayPlacement()}>{Rosetta.string("booking.detail_view_gateway")}</button>
                            </div>

                            <div className={"card-body"}>

                                <div className={"row justify-content-center"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("booking.detail_first_name")}</label>
                                        <div className={"form-control"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                { placement ? placement.firstName : "" }
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>


                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("booking.detail_last_name")}</label>
                                        <div className={"form-control"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                { placement ? placement.lastName : "" }
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                                        <label>{Rosetta.string("booking.detail_dob")}</label>
                                        <div className={"form-control"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                { placement ? formatDate(placement.dateOfBirth) : "" }
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                                        <label>{Rosetta.string("booking.detail_nationality")}</label>
                                        <div className={"form-control"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                { placement ? placement.nationality : "" }
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                                        <label>{Rosetta.string("booking.detail_language")}</label>
                                        <div className={"form-control"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                { placement ? placement.preferredLanguage : "" }
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                                        <label>{Rosetta.string("booking.detail_start_date")}</label>
                                        <div className={"form-control"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                { placement ? formatDate(placement.startDate) : "" }
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                                        <label>{Rosetta.string("booking.detail_end_date")}</label>
                                        <div className={"form-control"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                {placement ? formatDate(placement.endDate) : "" }
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                {bookingElem}

                {placementElems}

            </div>

            <UnitInventoryViewerModal
                shown={inventoryModalShown}
                inventory={inventoryData}
                callback={inventoryDidCallback} />

            <BookingReplacementConfirmationModal
                shown={replaceAvailabilityShown}
                data={replaceAvailabilityData}
                callback={replaceAvailabilityModalDidCallback}/>

            <UserMessagesModal
                shown={userMessagesShown}
                user={userMessageUser}
                callback={userMessagesDidCallback} />

            <BookingBedOccupancyEditorModal
                shown={occupancyEditorShown}
                bed={occupancyEditorBed}
                placementId={occupancyEditorPlacementId}
                startDate={occupancyEditorStartDate}
                endDate={occupancyEditorEndDate}
                callback={occupancyEditorDidCallback} />

        </div>
    )

}

/*
<div className={"col-12 col-md-6 col-lg-4 mt-2"}>
    <label>{Rosetta.string("booking.detail_couple_with")}</label>
    <div className={"form-control"}>
        <ContentOrPlaceholder showContent={showContent}>
            TODO
        </ContentOrPlaceholder>
    </div>
</div>
 */