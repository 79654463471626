import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import Rosetta from "../../../../rosetta/Rosetta";
import {Button, Container, Former, FormerActions, Text} from "../../../form/Former";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Toast} from "../../../toast/TokyoToaster";
import WindowUtil from "../../../../util/WindowUtil";

export const UnitBedroomEditorModal = (props) => {

    const {shown} = props;
    const {unitId} = props;
    const {bedroomId} = props;
    const {callback} = props;

    const [bedroom, setBedroom] = useState();
    const [bedroomNetworkInFlight, setBedroomNetworkInFlight] = useState(false);

    const [formState, setFormState] = useState({});

    const [forceDismiss, setForceDismiss] = useState(false);

    const [deleting, setDeleting] = useState(false);
    const [deleteInput, setDeleteInput] = useState("");

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            if (bedroomId) {
                fetchBedroomFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();

            setBedroom(undefined);
            setBedroomNetworkInFlight(false);
            setForceDismiss(false);
            setDeleteInput("");
            setDeleting(false);
        }
    }, [shown]);

    useEffect(() => {
        let newState = {};
        if (bedroom) {
            newState = bedroom;
        }
        setFormState(newState);
    }, [bedroom]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitFormOverNetwork(data.data);
            }
        }
    }

    function fetchBedroomFromNetwork() {
        if (bedroomNetworkInFlight) return;
        setBedroomNetworkInFlight(true);

        const data = {
            bedroomIds : JSON.stringify([bedroomId]),
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.unit.getUnitBedrooms, data)
            .then((r) => {
                let errorMessage = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setBedroom(resp.data.data[0]);
                    } else {
                        errorMessage = API.defaultError("UBF1001C");
                    }
                } else {
                    errorMessage = API.formatError(resp);
                }

                if (errorMessage !== null) {
                    AlertModal.showError(errorMessage);
                }

                setBedroomNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setBedroomNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UBF1000C"));
            });
    }

    function submitFormOverNetwork(data) {
        if (bedroomNetworkInFlight || !unitId) return;
        setBedroomNetworkInFlight(true);

        data.unitId = unitId;

        if (bedroom) {
            data.id = bedroom.id;
        }

        Axios.post(ENDPOINTS.unit.submitUnitBedroom, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setForceDismiss(true);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("bedroom.editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setBedroomNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setBedroomNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UBS1000C"));
            });
    }

    function deleteBedroomOverNetwork() {
        if (bedroomNetworkInFlight) return;
        setBedroomNetworkInFlight(true);

        const data = {
            unitId,
            unitBedroomId : bedroomId
        };

        Axios.post(ENDPOINTS.unit.deleteUnitBedroom, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("bedroom.editor_delete_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setBedroomNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setBedroomNetworkInFlight(false);
                AlertModal.showError(API.defaultError("USB1000C"));
            })
    }

    // RENDER

    if (!shown) return;

    let submitButton = (<Button label={Rosetta.string("common.save")} className={"btn btn-success"} />);
    if (bedroomNetworkInFlight) {
        submitButton = (<LoadingSpinner inline={true} small={true} />);
    }

    let deleteButton = [];
    let deleteElem = [];
    if (bedroom) {
        deleteButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-danger"} onClick={() => setDeleting(!deleting)}>{Rosetta.string("common.delete")}</button>
                </div>
            </div>
        )

        if (deleting) {
            let deleteSubmitButton = (<button className={"btn btn-light"} onClick={() => deleteBedroomOverNetwork()}>{Rosetta.string("bedroom.editor_delete_submit")}</button>)
            if (deleteInput !== bedroom.name) {
                deleteSubmitButton = (<button className={"btn btn-light disabled"}>{Rosetta.string("bedroom.editor_delete_submit")}</button>)
            }

            deleteElem = (
                <div className={"row mt-4 text-center"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-danger"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <strong>{Rosetta.string("bedroom.editor_delete_title")}</strong>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {Rosetta.string("bedroom.editor_delete_message", {name: bedroom ?  bedroom.name : ""})}
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <input type={"text"} className={"form-control text-center"} value={deleteInput} onChange={(e) => setDeleteInput(e.target.value)} placeholder={bedroom ? bedroom.name : ""} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {deleteSubmitButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("bedroom.title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <Former
                state={formState}
                callback={formDidCallback}>

                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Text name={"name"} label={Rosetta.string("bedroom.editor_name")} className={"form-control"} />
                    </Container>
                </Container>

                <Container className={"row mt-4"}>
                    <Container className={"col-12 text-center"}>
                        {submitButton}
                    </Container>
                </Container>

            </Former>

            {deleteButton}

            {deleteElem}

        </Offcanvas>
    )

}