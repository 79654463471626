import Rosetta from "../../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import {AlertModal} from "../../../../alertmodal/AlertModal";
import {LoadingSpinner} from "../../../../loading/LoadingSpinner";

export const DynamicLabelsLandingScreen = (props) => {

    const [labels, setLabels] = useState([]);
    const [labelsNetworkInFlight, setLabelsNetworkInFlight] = useState(false);

    const [organisations, setOrganisations] = useState([{id : 1, name : "Test Organisation"}, {id : 2, name : "Test Org 2"}]);
    const [organisationId, setOrganisationId] = useState(undefined);

    useEffect(() => {
        fetchLabelsFromNetwork();
    }, []);

    useEffect(() => {
        fetchLabelsFromNetwork();
    }, [organisationId]);

    function setLabelValue(keyName, value) {
        let out = [...labels];

        for (let i = 0; i < out.length; i++) {
            if (out[i].keyName === keyName) {
                out[i].value = value;
                break;
            }
        }

        setLabels(out);
    }

    function fetchLabelsFromNetwork() {
        if (labelsNetworkInFlight) return;
        setLabelsNetworkInFlight(true);

        const data = {
            organisationId
        };

        Axios.post(ENDPOINTS.dynamicLabel.getDynamicLabels, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setLabels(resp.data.data);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setLabelsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setLabelsNetworkInFlight(false);
                AlertModal.showError(API.defaultError("DLF1000C"));
            })
    }

    function submitLabelsOverNetwork() {
        if (labelsNetworkInFlight) return;
        setLabelsNetworkInFlight(true);

        const data = {
            organisationId,
            data : JSON.stringify(labels)
        };

        Axios.post(ENDPOINTS.dynamicLabel.submitDynamicLabels, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {

                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setLabelsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setLabelsNetworkInFlight(false);
                AlertModal.showError(API.defaultError("DLS1000C"));
            })
    }

    // RENDER

    let labelElems = [];

    if (labelsNetworkInFlight) {
        labelElems = (
            <div className={"col-12 text-center"}>
                <LoadingSpinner inline={true} />
            </div>
        )
    } else {
        if (labels && labels.length > 0) {
            labels.forEach((label) => {
                labelElems.push(
                    <div className={"col-12 col-md-6 mt-2"}>
                        <label>{label.defaultValue}</label>
                        <input type={"text"} className={"form-control"} value={label.value} onChange={(e) => setLabelValue(label.keyName, e.target.value)}/>
                    </div>
                )
            })

            labelElems.push(
                <div className={"col-12 text-center mt-4"}>
                    <button className={"btn btn-success"} onClick={() => submitLabelsOverNetwork()}>{Rosetta.string("common.save")}</button>
                </div>
            )
        } else {
            labelElems.push(
                <div className={"col-12"}>
                    <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
                </div>
            )
        }
    }

    return (
        <div className={"dynamic-label-landing-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("dynamic_labels.title")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"animate-screen-content"}>

                    <div className={"row mt-2"}>
                        <div className={"col-12"}>

                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <label>{Rosetta.string("dynamic_labels.filter_organisation")}</label>
                                            <select className={"form-select"} value={organisationId} onChange={(e) => setOrganisationId(e.target.value)}>
                                                {
                                                    organisations.map((organisation) => (
                                                        <option value={organisation.id}>{organisation.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        {labelElems}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}