import React from 'react';

import "./SegmentTabBar.css";

export class SegmentTab extends React.Component {}

export const SegmentTabBarActions = {
    SELECT : "select"
};

export const SegmentTabBar = (props) => {

    const {className} = props;

    function handleCallback(id) {
        if (props.callback !== undefined) {
            props.callback(SegmentTabBarActions.SELECT, id);
        }
    }

    // TODO Convert to children?
    let tabs = [];

    if (props.children !== undefined) {
        React.Children.forEach(props.children, (child) => {
            if (child.type === SegmentTab) {
                let classExtra = "";

                if (child.props.id !== undefined && props.selected !== undefined) {
                    if (child.props.id === props.selected) {
                        classExtra = " active";
                    }
                }

                tabs.push(
                    <span className={"segment-tab" + classExtra} onClick={() => handleCallback(child.props.id)}>{child.props.label}</span>
                );
            }
        });
    }

    let classNameExtra = "";
    if (className) {
        classNameExtra += " " + className;
    }

    return (
        <div className={"segment-tab-bar" + classNameExtra}>
            <div className={"segment-container"}>
                {tabs}
            </div>
        </div>
    )


}