import Rosetta from "../../../../rosetta/Rosetta";
import {SegmentTab, SegmentTabBar, SegmentTabBarActions} from "../../../segmenttab/SegmentTabBar";
import {useEffect, useState} from "react";

import {AvailabilityTableComponent} from "./AvailabilityTableComponent";
import {AvailabilityMapActions, AvailabilityMapComponent} from "./AvailabilityMapComponent";
import {useLocation} from "react-router-dom";
import {Navigator} from "../../../../util/Navigator";
import {UnitInventoryViewerModal} from "./UnitInventoryViewerModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";

export const AvailabilityLandingScreen = () => {

    const VIEW_MODE_TABLE = 1;
    const VIEW_MODE_MAP = 2;

    const HASH_MODE_TABLE = "table";
    const HASH_MODE_MAP = "map";

    const [viewMode, setViewMode] = useState(VIEW_MODE_MAP);

    const [inventoryShown, setInventoryShown] = useState(false);
    const [inventory, setInventory] = useState([]);

    const location = useLocation();

    useEffect(() => {
        let tab = Navigator.getHashParam("tab", null);
        if (tab !== null) {
            let viewMode = getTabFromUriName(tab);
            if (viewMode) {
                setViewMode(viewMode);
            }
        }
    }, [location]);

    function mapDidCallback(action, data) {
        if (action === AvailabilityMapActions.INVENTORY_SHOWN) {
            showInventory(data);
        }
    }

    function getTabFromUriName(name) {
        switch (name) {
            case HASH_MODE_TABLE : return VIEW_MODE_TABLE;
            case HASH_MODE_MAP: return VIEW_MODE_MAP;
            default: return null;
        }
    }

    function getTabNameFromMode(mode) {
        switch (mode) {
            case VIEW_MODE_TABLE: return HASH_MODE_TABLE;
            case VIEW_MODE_MAP: return HASH_MODE_MAP;
            default: return null;
        }
    }

    function viewTabDidCallback(action, data) {
        if (action === SegmentTabBarActions.SELECT) {
            //setViewMode(data);
            let hashName = getTabNameFromMode(data);
            if (hashName !== null) {
                Navigator.setHashParams({
                    tab : hashName
                });
            }
        }
    }

    function showInventory(inventory) {
        setInventory(inventory);
        setInventoryShown(true);
    }

    function inventoryDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setInventoryShown(false);
        }
    }

    let mainContent = [];
    if (viewMode === VIEW_MODE_TABLE) {
        mainContent = (<AvailabilityTableComponent />);
    } else {
        mainContent = (<AvailabilityMapComponent callback={mapDidCallback} />);
    }

    return (
        <>
            <div className={"container-fluid availability-landing-screen"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("availability.title")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12"}>
                        <SegmentTabBar
                            className={"hops-segment-tab-bar"}
                            selected={viewMode}
                            callback={viewTabDidCallback}>
                            <SegmentTab id={VIEW_MODE_MAP} label={Rosetta.string("availability.view_map")} />
                            <SegmentTab id={VIEW_MODE_TABLE} label={Rosetta.string("availability.view_table")} />
                        </SegmentTabBar>
                    </div>
                </div>

                <div className={"animate-screen-content"} key={"view-" + viewMode}>
                    {mainContent}
                </div>

            </div>

            <UnitInventoryViewerModal
                shown={inventoryShown}
                inventory={inventory}
                callback={inventoryDidCallback} />
        </>

    )

}