import {useEffect, useState} from "react";
import {FilterText} from "../../../filter/FilterText";
import {CommonUtil} from "../../../../util/CommonUtil";
import Rosetta from "../../../../rosetta/Rosetta";
import {API, ENDPOINTS} from "../../../../network/API";
import {Column} from "../../../table/TableComponent";
import {StringUtil} from "../../../../util/StringUtil";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {Chronos} from "../../../../chronos/Chronos";
import Axios from "axios";
import {FilingFileUploadModal} from "./FilingFileUploadModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {EventUtil} from "../../../../util/EventUtil";
import {Toast} from "../../../toast/TokyoToaster";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {CollapsableFilterComponent} from "../../../common/CollapsableFilterComponent";

export const FilingFilesComponent = (props) => {

    const [filterData, setFilterData] = useState({});
    const [filterNetworkInFlight, setFilterNetworkInFlight] = useState(false);

    const [filterSelection, setFilterSelection] = useState({});

    const [tableData, setTableData] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [tableOrdering, setTableOrdering] = useState(undefined);

    const [fileModalShown, setFileModalShown] = useState(false);
    const [fileModalId, setFileModalId] = useState(undefined);
    const [fileReupload, setFileReupload] = useState(undefined);

    const [deleteNetworkInFlight, setDeleteNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, []);

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, [filterSelection]);

    useEffect(() => {
        const out = {
            keyword : getFilter("keyword", undefined),
            siteIds : getFilter("siteId", undefined, true),
            unitIds : getFilter("unitId", undefined, true)
        };

        let createdStart = getFilter("dateCreatedStart", undefined);
        let createdEnd = getFilter("dateCreatedEnd", undefined);
        if (createdStart && createdEnd) {
            try {
                createdStart = Chronos.with(new Date(createdStart)).seconds();
                createdEnd = Chronos.with(new Date(createdEnd)).seconds();

                if (createdEnd >= createdStart) {
                    out.createdDateStart = createdStart;
                    out.createdDateEnd = createdEnd;
                }
            } catch (e) {
                console.log(e);
            }
        }

        let expiryStart = getFilter("dateExpiryStart", undefined);
        let expiryEnd = getFilter("dateExpiryEnd", undefined);
        if (expiryStart && expiryEnd) {
            expiryStart = Chronos.with(new Date(expiryStart)).seconds();
            expiryEnd = Chronos.with(new Date(expiryEnd)).seconds();

            if (expiryEnd >= expiryStart) {
                out.expiryDateStart = expiryStart;
                out.expiryDateEnd = expiryEnd;
            }
        }

        setTableData(out);
    }, [filterSelection]);

    function refreshTable() {
        const td = CommonUtil.cloneObject(tableData);
        td._refreshMutation = Math.random();
        setTableData(td);
    }

    function setFilter(keyName, value) {
        const fs = CommonUtil.cloneObject(filterSelection);
        fs[keyName] = value;
        setFilterSelection(fs);
    }

    function getFilter(keyword, defaultValue, returnArray) {
        if (returnArray === undefined) {
            returnArray = false;
        }

        if (filterSelection[keyword] !== undefined) {
            let out = filterSelection[keyword];
            if (out && returnArray) {
                if (out !== "-1") {
                    return JSON.stringify([out]);
                }
                return undefined;
            }

            return out;
        }
        return defaultValue;
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data);
        } else if (action === AutoTableActions.ROW_CLICKED) {
            //fileModalWasRequested(data.id);
            window.open(data.filePath);
        } else if (action === AutoTableActions.SORT_CHANGE) {
            setTableOrdering(data);
        }
    }

    function fileModalWasRequested(siteDocumentId, reupload) {
        if (reupload === undefined) {
            reupload = false;
        }

        setFileModalId(siteDocumentId);
        setFileReupload(reupload);
        setFileModalShown(true);
    }

    function fileModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setFileModalShown(false);

            if (data) {
                refreshTable();
            }
        }
    }

    function formatTableDate(date) {
        try {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return "---";
    }

    function promptFileDeletion(file) {
        AlertModal.showModal(
            Rosetta.string("filing.file_delete_title"),
            Rosetta.string("filing.file_delete_message", {title : file.title}),
            [
                AlertModal.button(
                    Rosetta.string("filing.file_delete_title"),
                    () => {
                        deleteDocumentOverNetwork(file.id);
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchFilterDataFromNetwork() {
        if (filterNetworkInFlight) return;
        setFilterNetworkInFlight(true);

        let data = {
            siteIds : getFilter("siteId", undefined, true)
        }

        Axios.post(ENDPOINTS.inspection.getFilterData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFilterData(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setFilterNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterNetworkInFlight(false);
            });
    }

    function deleteDocumentOverNetwork(id) {
        if (deleteNetworkInFlight) return;
        setDeleteNetworkInFlight(true);

        let data = {
            id
        };

        Axios.post(ENDPOINTS.siteDocument.deleteSiteDocument, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    refreshTable();
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("filing.file_delete_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setDeleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDeleteNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("SDA1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    return (
        <>

            <div className={"filing-files-component animate-screen-content"}>

                <div className={"row"}>
                    <div className={"col-12"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <CollapsableFilterComponent>
                                    <div className={"row mt-2 justify-content-center"}>
                                        <div className={"col-12 col-md-6 col-lg-4"}>
                                            <label>&nbsp;</label>
                                            <FilterText callback={(text) => setFilter("keyword", text)} />
                                        </div>
                                    </div>

                                    <div className={"row justify-content-center"}>
                                        <div className={"col-12 col-md-6 col-lg-4"}>
                                            <label>{Rosetta.string("filing.filter_date_added")}</label>
                                            <div className={"input-group"}>
                                                <input type={"date"} className={"form-control"} value={getFilter("dateCreatedStart", "")} onChange={(e) => setFilter("dateCreatedStart", e.target.value)} />
                                                <input type={"date"} className={"form-control"} value={getFilter("dateCreatedEnd", "")} onChange={(e) => setFilter("dateCreatedEnd", e.target.value)} />
                                            </div>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4"}>
                                            <label>{Rosetta.string("filing.filter_date_expiry")}</label>
                                            <div className={"input-group"}>
                                                <input type={"date"} className={"form-control"} value={getFilter("dateExpiryStart", "")} onChange={(e) => setFilter("dateExpiryStart", e.target.value)} />
                                                <input type={"date"} className={"form-control"} value={getFilter("dateExpiryEnd", "")} onChange={(e) => setFilter("dateExpiryEnd", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row justify-content-center"}>
                                        <div className={"col-12 col-md-6 col-lg-4"}>
                                            <label>{Rosetta.string("filing.filter_site")}</label>
                                            <select className={"form-select"} value={getFilter("siteId", "-1")} onChange={(e) => setFilter("siteId", e.target.value)}>
                                                <option value={"-1"}>{Rosetta.string("common.please_select")}</option>
                                                {CommonUtil.getOrDefault(filterData, "sites", []).map((site) => (
                                                    <option value={site.id}>{site.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4"}>
                                            <label>{Rosetta.string("filing.filter_unit")}</label>
                                            <select className={"form-select"} value={getFilter("unitId", "-1")} onChange={(e) => setFilter("unitId", e.target.value)}>
                                                <option value={"-1"}>{Rosetta.string("common.please_select")}</option>
                                                {CommonUtil.getOrDefault(filterData, "units", []).map((unit) => (
                                                    <option value={unit.id}>{unit.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </CollapsableFilterComponent>

                                <div className={"row mt-2 justify-content-end"}>
                                    <div className={"col-4 col-md-3 text-end"}>
                                        <button className={"btn btn-primary"} onClick={() => fileModalWasRequested()}>{Rosetta.string("filing.action_upload")}</button>
                                    </div>
                                </div>

                                <div className={"row justify-content-end"}>
                                    <div className={"col-4 col-lg-3"}>
                                        <label>{Rosetta.string("common.table_rows")}</label>
                                        <select className={"form-select"} value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col-12"}>

                                        <AutoTable
                                            url={ENDPOINTS.siteDocument.getSiteDocuments}
                                            data={tableData}
                                            page={page}
                                            pageSize={pageSize}
                                            ordering={tableOrdering}
                                            callback={tableDidCallback}>

                                            <Column name={"title"} title={Rosetta.string("filing.table_files_title")} sortable={true} render={(data, row) => {
                                                return StringUtil.ellipsise(data, 50);
                                            }} />
                                            <Column name={"siteName"} title={Rosetta.string("filing.table_files_site")} className={"text-center"} sortable={true} />
                                            <Column name={"unitName"} title={Rosetta.string("filing.table_files_unit")} className={"text-center"} sortable={true} />
                                            <Column name={"dateCreated"} title={Rosetta.string("filing.table_files_date_added")} className={"text-center"} sortable={true} render={(data, row) => {
                                                return formatTableDate(data);
                                            }} />
                                            <Column name={"expiryDate"} title={Rosetta.string("filing.table_files_date_expiry")} className={"text-center"} sortable={true} render={(data, row) => {
                                                if (data) {
                                                    data = parseInt(data);
                                                    let badgeClass = " badge-secondary";
                                                    let warningDate = Chronos.now().add(1, Chronos.WEEKS).seconds();
                                                    if (warningDate >= data) {
                                                        badgeClass = " badge-warning";

                                                        let dangerDate = Chronos.now().seconds();
                                                        if (dangerDate >= data) {
                                                            badgeClass = " badge-danger";
                                                        }
                                                    }
                                                    return (<span className={"badge fs-6 " + badgeClass}>{formatTableDate(data)}</span>);
                                                }
                                                return Rosetta.string("filing.table_files_date_expiry_none");
                                            }} />
                                            <Column name={"id"} className={"text-end"} render={(data, row) => (
                                                <>
                                                    <button className={"btn btn-primary"} onClick={(e) => { EventUtil.cancel(e); fileModalWasRequested(row.id, true)} }>{Rosetta.string("filing.table_files_action_reupload")}</button>
                                                    <button className={"btn btn-danger ms-1"} onClick={(e) => { EventUtil.cancel(e); promptFileDeletion(row) }}>{Rosetta.string("filing.table_files_action_delete")}</button>
                                                </>
                                            )} />

                                        </AutoTable>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>



            </div>

            <FilingFileUploadModal
                shown={fileModalShown}
                siteDocumentId={fileModalId}
                reupload={fileReupload}
                callback={fileModalDidCallback} />

        </>
    )

}