import {DataManager} from "../data/DataManager";

export const AppUser = {
    roles : {
        GATEWAY_ADMIN : 1,
        GATEWAY_READ_ONLY : 2,
        EMPLOYER_ADMIN : 3,
        SOURCE_ADMIN : 4,
        WORKER : 5,
        FARM_AUDITOR : 6,
        AGENT_AUDITOR : 7,
        EMPLOYER_READ_ONLY : 8,
        SOURCE_READ_ONLY : 9,
        EMPLOYER_LEAD_ADMIN : 10,
        SOURCE_LEAD_ADMIN : 11
    },
    isInRole : (userRoleIds) => {
        let out = false;

        const user = DataManager.getUser();

        console.log("USER ROLE: " + user.roleID);

        if (user) {
            if (!Array.isArray(userRoleIds)) {
                userRoleIds = [userRoleIds];
            }

            for (let roleId of userRoleIds) {
                if (parseInt(roleId) === parseInt(user.roleID)) {
                    out = true;
                    break;
                }
            }
        }

        console.log("In roles? " + out);

        return out;
    },
    getNonWorkerRoles() {
        return [
            AppUser.roles.GATEWAY_ADMIN,
            AppUser.roles.GATEWAY_READ_ONLY,
            AppUser.roles.EMPLOYER_ADMIN,
            AppUser.roles.EMPLOYER_READ_ONLY,
            AppUser.roles.EMPLOYER_LEAD_ADMIN,
            AppUser.roles.SOURCE_ADMIN,
            AppUser.roles.SOURCE_READ_ONLY,
            AppUser.roles.SOURCE_LEAD_ADMIN,
            AppUser.roles.FARM_AUDITOR,
            AppUser.roles.AGENT_AUDITOR
        ]
    }
}