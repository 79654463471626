import {useEffect, useState} from "react";

import "./UserAccountModal.css";

import {ImageUtil} from "../../../util/ImageUtil";
import {DataManager} from "../../../data/DataManager";
import Rosetta from "../../../rosetta/Rosetta";

import userIcon from "../../../assets/images/account.svg";
import signOutIcon from "../../../assets/images/arrow_left.svg";

export const UserAccountModalActions = {
    ACTION_SIGN_OUT : "useraccount.signout",
    ACTION_CLOSE : "useraccount.close"
};

export const UserAccountModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [user, setUser] = useState(null);
    const [top, setTop] = useState(0);

    useEffect(() => {
        setUser(DataManager.getUser());
        if (shown) {
            calculateTop();
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function calculateTop() {
        const mainHeaders = document.getElementsByClassName("main-header");
        if (mainHeaders.length > 0) {
            const header = mainHeaders[0];
            if (header) {
                setTop(header.offsetHeight);
            }
        }
    }

    if (!shown) return [];

    let userName = "";
    if (user) {
        userName = Rosetta.string("common.name_format", {
            given_name : user.firstName,
            family_name : user.lastName
        });
    }

    return (
        <div className={"user-account-modal"}>
            <div className={"user-account-backdrop"} onClick={() => handleCallback(UserAccountModalActions.ACTION_CLOSE)} />

            <div className={"user-account-modal-dialog"} style={{top : top}}>
                <div className={"user-account-modal-dialog-user-widget"}>
                    <div className={"user-widget-icon"} style={{backgroundImage : ImageUtil.background(userIcon)}} />
                    <div className={"user-widget-name"}>
                        {userName}
                    </div>
                </div>

                <div className={"user-account-modal-dialog-action"} onClick={() => handleCallback(UserAccountModalActions.ACTION_SIGN_OUT)}>
                    <div className={"action-icon"} style={{backgroundImage : ImageUtil.background(signOutIcon)}} />
                    <div className={"action-text"}>{Rosetta.string("common.sign_out")}</div>
                </div>
            </div>
        </div>
    )


}