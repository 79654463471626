import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import {Button, Container, Former, FormerActions, Text} from "../../../form/Former";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import Rosetta from "../../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {Toast} from "../../../toast/TokyoToaster";
import WindowUtil from "../../../../util/WindowUtil";

export const UnitEditorModal = (props) => {

    const {shown} = props;
    const {siteId} = props;
    const {unitId} = props;
    const {callback} = props;

    const [unit, setUnit] = useState(undefined);
    const [unitNetworkInFlight, setUnitNetworkInFlight] = useState(false);

    const [formState, setFormState] = useState({});

    const [forceDismiss, setForceDismiss] = useState(false);

    const [deleting, setDeleting] = useState(false);
    const [deleteInput, setDeleteInput] = useState("");

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            if (unitId) {
                fetchUnitFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();

            setUnit(undefined);
            setUnitNetworkInFlight(false);
            setFormState({});
            setForceDismiss(false);
            setDeleteInput("");
            setDeleting(false);
        }
    }, [shown]);

    useEffect(() => {
        let state = {};
        if (unit) {
            state = unit;
        }
        setFormState(state);
    }, [unit]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitUnitOverNetwork(data.data);
            }
        }
    }

    function fetchUnitFromNetwork() {
        if (unitNetworkInFlight) return;
        setUnitNetworkInFlight(true);

        const data = {
            unitIds : JSON.stringify([unitId])
        };

        Axios.post(ENDPOINTS.unit.getUnits, data)
            .then((r) => {
                let errorMessage = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setUnit(resp.data.data[0]);
                    } else {
                        errorMessage = API.defaultError("USF1001C");
                    }
                } else {
                    errorMessage = API.formatError(resp);
                }

                if (errorMessage !== null) {
                    AlertModal.showError(errorMessage);
                }

                setUnitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUnitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("USF1000C"));
            })
    }

    function submitUnitOverNetwork(data) {
        if (unitNetworkInFlight || !siteId) return;
        setUnitNetworkInFlight(true);

        if (siteId) {
            data.siteId = siteId;
        }

        if (unit) {
            data.id = unit.id;
        }

        Axios.post(ENDPOINTS.unit.submitUnit, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setForceDismiss(true);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("unit.editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUnitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUnitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("USS1000C"));
            })
    }

    function deleteUnitOverNetwork() {
        if (unitNetworkInFlight) return;
        setUnitNetworkInFlight(true);

        const data = {
            siteId,
            unitId
        };

        Axios.post(ENDPOINTS.unit.deleteUnit, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("unit.editor_delete_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUnitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUnitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SDU1000C"));
            })
    }

    // RENDER

    if (!shown) return;

    let submitButton = (<Button label={Rosetta.string("common.save")} className={"btn btn-success"} />);
    if (unitNetworkInFlight) {
        submitButton = (<LoadingSpinner inline={true} small={true} />);
    }

    let deleteButton = [];
    let deleteElem = [];

    if (unit) {
        deleteButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-danger"} onClick={() => setDeleting(!deleting)}>{Rosetta.string("common.delete")}</button>
                </div>
            </div>
        )

        if (deleting) {
            let deleteSubmitButton = (<button className={"btn btn-light"} onClick={() => deleteUnitOverNetwork()}>{Rosetta.string("unit.editor_delete_submit")}</button>)
            if (deleteInput !== unit.name) {
                deleteSubmitButton = (<button className={"btn btn-light disabled"}>{Rosetta.string("unit.editor_delete_submit")}</button>)
            }

            deleteElem = (
                <div className={"row mt-4 text-center"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-danger"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <strong>{Rosetta.string("unit.editor_delete_title")}</strong>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {Rosetta.string("unit.editor_delete_message", {name: unit ? unit.name : ""})}
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <input type={"text"} className={"form-control text-center"} value={deleteInput} onChange={(e) => setDeleteInput(e.target.value)} placeholder={unit ? unit.name : ""} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {deleteSubmitButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("unit.title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <Former
                state={formState}
                callback={formDidCallback}>

                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Text name={"name"} label={Rosetta.string("unit.editor_name")} className={"form-control"} mandatory={true} />
                    </Container>
                </Container>

                <Container className={"row mt-4"}>
                    <Container className={"col-12 text-center"}>
                        {submitButton}
                    </Container>
                </Container>

            </Former>

            {deleteButton}

            {deleteElem}

        </Offcanvas>
    )

}