/* It stretches. Do you get it? We have fun here. */
export const Armstrong = {
    _callbacks : [],
    breakpoints : {
        xsmall : 0,
        small : 576,
        medium : 768,
        large : 992,
        xlarge : 1200,
        xxlarge : 1400
    },
    _breakpointValues : () => [
        [Armstrong.breakpoints.xsmall, Armstrong.breakpoints.small],
        [Armstrong.breakpoints.small, Armstrong.breakpoints.medium],
        [Armstrong.breakpoints.medium, Armstrong.breakpoints.large],
        [Armstrong.breakpoints.large, Armstrong.breakpoints.xlarge],
        [Armstrong.breakpoints.xlarge, Armstrong.breakpoints.xxlarge],
        [Armstrong.breakpoints.xxlarge, null]
    ],
    init : () => {
        window.addEventListener("resize", (e) => {
            Armstrong._processCallbacks(window.innerWidth, window.innerHeight);
        }, true);
    },
    getWidth : () => {
        return window.innerWidth;
    },
    getHeight : () => {
        return window.innerHeight;
    },
    addCallback : (callback) => {
        Armstrong._callbacks.push(callback);

        Armstrong._processCallbacks(Armstrong.getWidth(), Armstrong.getHeight(), callback);

        return callback;
    },
    removeCallback : (callback) => {
        for (let i = Armstrong._callbacks.length - 1; i >= 0; i--) {
            if (Armstrong._callbacks[i] === callback) {
                Armstrong._callbacks.splice(i, 1);
                break;
            }
        }
    },
    _processCallbacks : (width, height, callback) => {
        const ranges = Armstrong._breakpointValues();
        let breakpointValue = null;

        for (const range of ranges) {
            if (width >= range[0] && (range[1] === null || width <= range[1])) {
                breakpointValue = range[0];
                break;
            }
        }

        if (callback === undefined) {
            for (let callback of Armstrong._callbacks) {
                if (breakpointValue !== null) {
                    try {
                        callback(breakpointValue, width, height);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        } else {
            callback(breakpointValue, width, height);
        }
    }

}