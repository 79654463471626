import axios from 'axios';
import Axios from 'axios';
import Rosetta from "../rosetta/Rosetta";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            } else if (token === null) {
                delete axios.defaults.headers.common["Authorization"];
            }
        },
        setOrganisationId : (organisationId) => {
            if (organisationId) {
                axios.defaults.headers.common["x-organisation-id"] = organisationId;
            }  else {
                delete axios.defaults.headers.common["x-organisation-id"];
            }
        },
        setAuthFailureCallback : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        post : (url, data) => new Promise((resolve) => {
            Axios.post(url, data)
                .then((r) => {
                    const resp = API.parse(r);
                    resolve(resp);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        }),
        get : (url) => new Promise((resolve) => {
            Axios.get(url)
                .then((r) => {
                    const resp = API.parse(r);
                    resolve(resp);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        }),
        parse : (resp) => {
            let out = {
                success : false,
                data : null,
                error : {
                    desc : Rosetta.string("common.error_common_uncoded"),
                    code : -1
                }
            };

            if (resp !== null) {
                out.success = resp.data.success;
                if (out.success) {
                    if (resp.data.hasOwnProperty("data")) {
                        out.data = resp.data.data;
                    } else {
                        out.data = resp.data;
                    }
                } else {
                    if (resp.data.error !== undefined) {
                        out.error.desc = resp.data.error.desc;
                        out.error.code = resp.data.error.code;
                    }
                }
            }

            return out;
        },
        formatError : (resp) => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        objectToPostData : (object) => {
            let keys = Object.keys(object);
            let formData = new FormData();

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = object[key];
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                formData.append(key, value);
            }

            return formData;
        },
        defaultError : (errorCode) => {
            return Rosetta.string("common.error_common_unknown", { error_code : errorCode });
        }
    }
}) (axios);

export const ENDPOINTS = {
    auth : {
        login : "auth/login"
    },
    message : {
        getMessages : "message/getMessages",
        readMessages : "message/readMessages",
        submitMessage : "message/submitMessage"
    },
    workerReport : {
        submitWorkerReport : "workerReport/submitWorkerReport",
        uploadWorkerReportImage : "/workerReport/uploadWorkerReportImage",
        getWorkerReports : "/workerReport/getWorkerReports",
        getReportFilterData : "/workerReport/getReportFilterData",
        assignUsersToReport : "/workerReport/assignUsersToReport",
        resetWorkerReport : "/workerReport/resetWorkerReport",
        exportWorkerReports : "/workerReport/exportWorkerReports"
    },
    inspection : {
        submitInspection : "inspection/submitInspection",
        uploadInspectionImage : "inspection/uploadInspectionImage",
        getFilterData : "inspection/getInspectionFilterData",
        getInspections : "inspection/getInspections",
        resetInspection : "inspection/resetInspection",
        assignUsersToInspection : "inspection/assignUsersToInspection",
        getInspectionTemplates : "inspection/getInspectionTemplates",
        submitInspectionTemplate : "inspection/submitInspectionTemplate",
        getInspectionTemplateTypes : "inspection/getInspectionTemplateTypes",
        getInspectionTemplateTriggers : "inspection/getInspectionTemplateTriggers",
        exportInspections : "/inspection/exportInspections",
        deleteInspectionTemplate : "/inspection/deleteInspectionTemplate",
        deleteInspection : "/inspection/deleteInspection"
    },
    task : {
        getTaskOptionData : "/task/getTaskOptionData",
        getDashboardTaskList : "/task/getDashboardTaskList"
    },
    welcome : {
        getVideos : "welcome/getVideos"
    },
    site : {
        getSites : "/site/getSites",
        getSiteSummary : "/site/getSiteSummary",
        getSiteBedAvailability : "/site/getSiteBedAvailability",
        getSiteFilterData : "/site/getSiteFilterData",
        submitSite : "/site/submitSite",
        copySite : "/site/copySite",
        getEvacuationList : "/site/getEvacuationList",
        exportEvacuationList : "/site/exportEvacuationList",
        deleteSite : "/site/deleteSite"
    },
    siteDocument : {
        getSiteDocuments : "siteDocument/getSiteDocuments",
        submitSiteDocument : "siteDocument/submitSiteDocument",
        deleteSiteDocument : "siteDocument/deleteSiteDocument"
    },
    unit : {
        getUnits : "unit/getUnits",
        submitUnit : "unit/submitUnit",
        getUnitBedrooms : "unit/getUnitBedrooms",
        submitUnitBedroom : "unit/submitUnitBedroom",
        getUnitBedroomBeds : "unit/getUnitBedroomBeds",
        submitUnitBedroomBed : "unit/submitUnitBedroomBed",
        getBedTypes : "unit/getBedTypes",
        copyUnit : "unit/copyUnit",
        copyBedroom : "unit/copyBedroom",
        submitUnitInventory : "unit/submitUnitInventory",
        deleteUnit : "unit/deleteUnit",
        deleteUnitBedroom : "unit/deleteUnitBedroom",
        deleteUnitBedroomBed : "unit/deleteUnitBedroomBed"
    },
    availability : {
        getBedAvailability : "/availability/getBedAvailability",
        getFilterData : "/availability/getFilterData",
        getBedDetail : "/availability/getBedDetail",
        getBedOccupation : "/availability/getBedOccupation",
        getBedOccupancy : "/availability/getBedOccupancy",
        submitBedOccupancy : "/availability/submitBedOccupancy",
        setBedOutOfUse : "/availability/setBedOutOfUse",
        removeBedOccupancy : "/availability/removeBedOccupancy"
    },
    user : {
        getUsers : "/user/getUsers",
        getUserEmployers : "/user/getUserEmployers",
        getGenders : "/user/getGenders"
    },
    booking : {
        getBookingUsers : "/booking/getBookingUsers",
        getPlacement : "/booking/getPlacement"
    },
    dynamicLabel : {
        getDynamicLabels : "/dynamicLabel/getDynamicLabels",
        submitDynamicLabels : "/dynamicLabel/submitDynamicLabels"
    }
};
