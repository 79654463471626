import {useHistory, useNavigate} from "react-router-dom";

export const Navigator = {
    history : null,
    nav : null,
    navigate : (url) => {
        if (Navigator.nav !== null) {
            Navigator.nav(url)
        }
    },
    goBack : () => {
        if (Navigator.history !== null) {
            Navigator.history.back();
        }
    },
    /**
     * Returns a URL without any Hash values
     * @returns {string}
     */
    getCleanUrl : () => {
        let url = window.location.href.split("#");
        return url[0];
    },
    /**
     * Returns an object representation of the current hash values encoded into the window location
     * @returns {{}}
     */
    getHashParams : () => {
        let out = {};

        let urlSplit = window.location.href.split("#");
        if (urlSplit.length > 1) {
            let hashSplit = urlSplit[1].split("&");
            for (let i = 0; i < hashSplit.length; i++) {
                let hashKV = hashSplit[i].split("=");
                if (hashKV.length > 1) {
                    out[hashKV[0]] = hashKV[1];
                } else {
                    out[hashKV[0]] = null;
                }
            }
        }

        return out;
    },
    /**
     * Gets the value of a hash key-value pair in the URL
     * @param keyName           Key name to find
     * @param defaultValue      Default value
     * @returns {*}             Value or default value
     */
    getHashParam : (keyName, defaultValue) => {
        let params = Navigator.getHashParams();
        if (params.hasOwnProperty(keyName)) {
            return params[keyName];
        }
        return defaultValue;
    },
    setHashParams(params, url) {
        if (url === undefined) {
            url = "";
        }

        let hashString = "";

        if (params) {
            let keyNames = Object.keys(params);
            for (const key of keyNames) {
                if (hashString !== "") {
                    hashString += "&";
                }
                hashString += key + "=" + encodeURI(params[key]);
            }
            if (hashString !== "") {
                hashString = "#" + hashString;
            }
        }

        Navigator.navigate(url + hashString);
    }
}

export function InitNavigator() {
    Navigator.nav = useNavigate();
    Navigator.history = window.history;
    return [];
}