import {useState} from "react";
import Rosetta from "../../../../rosetta/Rosetta";

import "./AvailabilityMapComponent.css";

import {Navigator} from "../../../../util/Navigator";
import {SiteMapComponent, SiteMapComponentActions} from "../../../site/SiteMapComponent";

export const AvailabilityMapActions = {
    INVENTORY_SHOWN : "availability_map.inventory_shown"
}

export const AvailabilityMapComponent = (props) => {

    const {callback} = props;

    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);

    function siteMapDidCallback(action, data) {
        if (action === SiteMapComponentActions.BED_CLICKED) {
            moveToBedDetail(data);
        } else if (action === SiteMapComponentActions.INVENTORY_SHOWN) {
            handleCallback(AvailabilityMapActions.INVENTORY_SHOWN, data)
        }
    }

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function moveToBedDetail(bedId) {
        if (bedId) {
            Navigator.navigate("/bed/" + bedId);
        }
    }


    return (
        <>
            <div className={"availability-map-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row justify-content-center"}>

                                    <div className={"col-6 col-md-4 col-lg-3"}>
                                        <label>{Rosetta.string("availability.filter_start_date")}</label>
                                        <input type={"date"} className={"form-control"} value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                                    </div>

                                    <div className={"col-6 col-md-4 col-lg-3"}>
                                        <label>{Rosetta.string("availability.filter_end_date")}</label>
                                        <input type={"date"} className={"form-control"} value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className={"site-map-container"}>

                    <SiteMapComponent
                        startDate={filterStartDate}
                        endDate={filterEndDate}
                        callback={siteMapDidCallback} />

                </div>

            </div>
        </>

    )

}