import {useEffect, useRef, useState} from "react";
import {UserInfoWidget, UserInfoWidgetActions} from "./UserInfoWidget";
import {DataManager} from "../../data/DataManager";
import {NavigationList} from "./NavigationList";
import {Offcanvas, OffcanvasActions} from "../offcanvas/Offcanvas";
import Rosetta from "../../rosetta/Rosetta";
import {Armstrong} from "../../util/Armstrong";
import {MainComponent} from "../MainComponent";

export const CollapsableNavigationComponent = (props) => {

    const {items} = props;
    const {gravity} = props;
    const {expanded} = props;
    const {callback} = props;

    const [user, setUser] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);
    const [screenSize, setScreenSize] = useState(0);

    const sizeListener = useRef();

    useEffect(() => {
        setUser(DataManager.getUser());

        sizeListener.current = Armstrong.addCallback((size, width, height) => {
            setScreenSize(size);
        });

        return () => {
            Armstrong.removeCallback(sizeListener.current);
        }
    }, []);

    useEffect(() => {
        console.log("EXPANDED: " + expanded);
    }, [expanded]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function userWidgetDidCallback(action, data) {
        if (action === UserInfoWidgetActions.SIGNOUT) {
            handleCallback(action, data);
        }
    }

    function navigationDidCallback(action, data) {
        // TODO
    }

    function offcanvasDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setForceDismiss(false);
        }

        handleCallback(action, data);
    }

    let mainContent = (
        <>
            <UserInfoWidget
                user={user}
                callback={userWidgetDidCallback} />

            <NavigationList
                items={items}
                callback={navigationDidCallback} />
        </>
    )

    // forceDismiss={forceDismiss}

    if (screenSize < Armstrong.breakpoints.medium) {
        mainContent = (
            <Offcanvas
                shown={expanded}
                title={Rosetta.string("settings.title")}
                gravity={gravity}
                callback={offcanvasDidCallback}>

                {mainContent}

            </Offcanvas>
        )
    }

    return mainContent;

}