import "./WorkerReportEntrySuccessScreen.css";

import iconCircle from "../../../../assets/images/circle_thick.svg";
import iconTick from "../../../../assets/images/tick_thick.svg";
import {ImageUtil} from "../../../../util/ImageUtil";
import Rosetta from "../../../../rosetta/Rosetta";

export const WorkerReportEntrySuccessScreen = (props) => {

    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    return (
        <div className={"worker-report-entry-success-screen"}>

            <div className={"row justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"tick-icon"} style={{backgroundImage : ImageUtil.background(iconCircle)}}>
                                <div className={"tick-icon-content"} style={{backgroundImage : ImageUtil.background(iconTick)}} />
                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4 button-text-animate"}>
                        <div className={"col-12 text-center"}>
                            <h3>{Rosetta.string("worker_report.complete_title")}</h3>
                        </div>
                    </div>

                    <div className={"row mt-4 button-text-animate delay"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-primary"} onClick={() => handleCallback("done")}>{Rosetta.string("worker_report.complete_return")}</button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )

}