import Rosetta from "../../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {CommonUtil} from "../../../../util/CommonUtil";
import {Chronos} from "../../../../chronos/Chronos";
import {Lightbox, LightboxActions, LightboxImage} from "../../../lightbox/Lightbox";
import {UserSelectionModal, UserSelectionModalActions, UserSelectionModalModes} from "../../../user/UserSelectionModal";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {ImageUtil} from "../../../../util/ImageUtil";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {Inspection} from "../../../../data/Inspection";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {DataManager} from "../../../../data/DataManager";
import {Toast} from "../../../toast/TokyoToaster";
import {AppUser} from "../../../../util/AppUser";
import {Navigator} from "../../../../util/Navigator";

export const InspectionDetailScreen = (props) => {

    const {id} = useParams();

    const [inspection, setInspection] = useState(null);
    const [inspectionNetworkInFlight, setInspectionNetworkInFlight] = useState(false);
    const [assignNetworkInFlight, setAssignNetworkInFlight] = useState(false);

    const [assignedUsers, setAssignedUsers] = useState([]);
    const [requireDate, setRequireDate] = useState(null);

    const [lightboxShown, setLightboxShown] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [lightboxImage, setLightboxImage] = useState(null);

    const [userSelectionShown, setUserSelectionShown] = useState(false);

    const [deleteNetworkInFlight, setDeleteNetworkInFlight] = useState(false);

    useEffect(() => {
        if (id) {
            fetchInspectionFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (inspection) {
            if (inspection.hasOwnProperty("users")) {
                let useUsers = [];
                if (inspection.users.length > 0) {
                    useUsers = inspection.users;
                }
                setAssignedUsers(useUsers);
            }

            if (inspection.hasOwnProperty("requiredDate")) {
                let reqDate = null;
                if (inspection.requiredDate) {
                    reqDate = Chronos.withTimestampSeconds(inspection.requiredDate).format("yyyy-MM-dd");
                }
                setRequireDate(reqDate);
            }
        }
    }, [inspection]);

    function showQuestionImage(image, questionImages) {
        let images = [];
        if (questionImages !== undefined) {
            images = questionImages.map((img) => {
                return LightboxImage(
                    img.imagePath,
                    img.thumbPath
                );
            });
        }

        launchLightbox(LightboxImage(image.imagePath, image.thumbPath), images);
    }

    function launchLightbox(selectedImage, images) {
        if (images === undefined) {
            images = [];
            if (inspection.hasOwnProperty("images") && inspection.images.length > 0) {
                images = inspection.images.map((image, index) => {
                    return LightboxImage(
                        image.imagePath,
                        image.thumbPath,
                        (index + 1)
                    )
                })
            }
        }

        setLightboxImage(selectedImage);
        setLightboxImages(images);
        setLightboxShown(true);
    }

    function lightboxDidCallback(action, data) {
        if (action === LightboxActions.CLOSE) {
            setLightboxShown(false);
            setLightboxImages([]);
            setLightboxImage(null);
        }
    }

    function userSelectionDidCallback(action, data) {
        if (action === UserSelectionModalActions.SUBMIT) {
            if (data) {
                let exAssignedUsers = [...assignedUsers];

                let shouldAdd = true;
                for (let user of data) {
                    for (let i = 0; i < exAssignedUsers.length; i++) {
                        if (parseInt(exAssignedUsers[i].userId) === parseInt(user.id)) {
                            shouldAdd = false;
                            break;
                        }
                    }

                    if (shouldAdd) {
                        exAssignedUsers.push({
                            userId: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            roleName: user.roleName
                        });
                    }
                }
                setAssignedUsers(exAssignedUsers);
            }
        } else if (action === OffcanvasActions.CLOSE) {
            setUserSelectionShown(false);
        }
    }

    function removeUserAssignment(user) {
        if (user) {
            let exAssignedUsers = [...assignedUsers];

            for (let i = 0; i < exAssignedUsers.length; i++) {
                if (parseInt(exAssignedUsers[i].userId) === parseInt(user.userId)) {
                    exAssignedUsers.splice(i, 1);
                    break;
                }
            }

            setAssignedUsers(exAssignedUsers);
        }
    }

    function findImagesByType(images, type) {
        let out = [];

        for (let i = 0; i < images.length; i++) {
            if (parseInt(images[i].inspectionImageTypeId) === parseInt(type)) {
                out.push(images[i]);
            }
        }

        return out;
    }

    function promptResetInspection() {
        AlertModal.showModal(
            Rosetta.string("user_reports.detail_reset_confirm_title"),
            Rosetta.string("user_reports.detail_reset_confirm_message"),
            [
                AlertModal.button(Rosetta.string("common.accept"), () => {
                    AlertModal.dismissModal();
                    submitReportResetOverNetwork();
                }, "danger"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function promptInspectionDelete() {
        if (deleteNetworkInFlight) return;

        AlertModal.showModal(
            Rosetta.string("user_reports.inspection_delete_prompt_title"),
            Rosetta.string("user_reports.inspection_delete_prompt_message"),
            [
                AlertModal.button(
                    Rosetta.string("common.delete"),
                    () => {
                        AlertModal.dismissModal();
                        deleteInspectionOverNetwork();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchInspectionFromNetwork() {
        if (inspectionNetworkInFlight) return;
        setInspectionNetworkInFlight(true);

        let data = {
            ids : JSON.stringify([id]),
            page : 1,
            pageSize : 1,
            fetchImages : true,
            fetchUsers : true,
            fetchSubmissions : true
        };

        Axios.post(ENDPOINTS.inspection.getInspections, data)
            .then((r) => {
                let errorMessage = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setInspection(resp.data.data[0]);
                    } else {
                        errorMessage = Rosetta.string("user_reports.fetch_no_record_found");
                    }
                } else {
                    errorMessage = API.formatError(resp);
                }

                if (errorMessage !== null) {
                    AlertModal.showError(errorMessage);
                }

                setInspectionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setInspectionNetworkInFlight(false);
                AlertModal.showError(API.defaultError("IDF1000C"));
            });
    }

    function submitUserAssignmentOverNetwork(ignoreWarnings) {
        if (ignoreWarnings === undefined) ignoreWarnings = false;

        if (assignNetworkInFlight) return;

        let selfUser = null;
        let loggedInUser = DataManager.getUser();

        let assignedUserIds = assignedUsers.map((user) => {
            if (parseInt(user.userId) === parseInt(loggedInUser.id)) {
                selfUser = user;
            }

            return user.userId;
        });

        // If the currently logged-in User is in the Assigned User list, show this message.
        // Logic to handle this is on the server.
        if (selfUser !== null && !ignoreWarnings) {
            AlertModal.showModal(
                Rosetta.string("user_reports.detail_self_assignment_title"),
                Rosetta.string("user_reports.detail_self_assignment_message"),
                [
                    AlertModal.button(Rosetta.string("common.accept"), () => {
                        AlertModal.dismissModal();
                        // Call function again, ignore warnings this time.
                        submitUserAssignmentOverNetwork(true);
                    }, "success"),
                    AlertModal.button(Rosetta.string("common.decline"), () => {
                        AlertModal.dismissModal();
                        // Remove user from list
                        removeUserAssignment(selfUser);
                    })
                ]
            );
            return;
        }

        setAssignNetworkInFlight(true);

        const data = {
            inspectionId : id,
            assignedUserIds
        };

        if (requireDate !== null) {
            data.requiredDate = Chronos.with(new Date(requireDate)).seconds();
        }

        Axios.post(ENDPOINTS.inspection.assignUsersToInspection, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.inspection !== null) {
                        setInspection(resp.data.inspection);
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("user_reports.detail_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setAssignNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAssignNetworkInFlight(false);
                AlertModal.showError(API.defaultError("URDA1000C"));
            });
    }

    function submitReportResetOverNetwork() {
        if (inspectionNetworkInFlight) return;
        setInspectionNetworkInFlight(true);

        const data = {
            inspectionId : id
        };

        Axios.post(ENDPOINTS.inspection.resetInspection, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.inspection !== null) {
                        setInspection(resp.data.inspection);
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("user_reports.detail_reset_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setInspectionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setInspectionNetworkInFlight(false);
                AlertModal.showError(API.defaultError("IRS1000C"));
            });
    }

    function deleteInspectionOverNetwork() {
        if (deleteNetworkInFlight) return;
        setDeleteNetworkInFlight(true);

        let data = {
            inspectionId : id
        };

        Axios.post(ENDPOINTS.inspection.deleteInspection, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Navigator.navigate("/tasks/inspections/");
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("user_reports.inspection_delete_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDeleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("DIR1000C"));
                setDeleteNetworkInFlight(false);
            })
    }

    // RENDER

    const showContent = inspection && !inspectionNetworkInFlight;

    let descriptionElem = [];
    let photoElem = [];
    let deadlineDateElem = [];
    let completionDateElem = [];

    let statusElem = [];
    let submissionElems = [];

    if (inspection) {
        if (inspection.hasOwnProperty("description") && inspection.description) {
            descriptionElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("user_reports.detail_comment")}</label>
                        <div className={"form-control"}>
                            <ContentOrPlaceholder showContent={showContent}>
                                {inspection ? inspection.description : ""}
                            </ContentOrPlaceholder>
                        </div>
                    </div>
                </div>
            );
        }

        if (inspection.hasOwnProperty("images") && inspection.images.length > 0) {
            photoElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("user_reports.detail_images")}</label>
                        <div className={"image-thumbs"}>
                            {inspection.images.map((image) => {
                                if (parseInt(image.inspectionImageTypeId) === Inspection.imageTypes.STANDARD) {
                                    return (
                                        <div
                                            className={"image-thumb image-preview"}
                                            style={{backgroundImage: ImageUtil.background(image.imagePath)}}
                                            onClick={() => {
                                                launchLightbox(image);
                                            }}
                                        />
                                    )
                                }

                                return [];
                            })}
                        </div>
                    </div>
                </div>
            )
        }

        if (inspection.hasOwnProperty("requiredDate") && inspection.requiredDate !== null) {
            deadlineDateElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("user_reports.detail_deadline")}</label>
                        <div className={"form-control"}>
                            <ContentOrPlaceholder showContent={showContent}>
                                {inspection ? Chronos.withTimestampSeconds(inspection.requiredDate).format("dd/MM/yyyy HH:mm") : ""}
                            </ContentOrPlaceholder>
                        </div>
                    </div>
                </div>
            )
        }

        if (inspection.hasOwnProperty("completionDate") && inspection.completionDate !== null) {
            completionDateElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("user_reports.detail_completed_date")}</label>
                        <div className={"form-control"}>
                            <ContentOrPlaceholder showContent={showContent}>
                                {inspection ? Chronos.withTimestampSeconds(inspection.completionDate).format("dd/MM/yyyy HH:mm") : ""}
                            </ContentOrPlaceholder>
                        </div>
                    </div>
                </div>
            )
        }

        let statusId = parseInt(inspection.inspectionStatusId);
        if (statusId === Inspection.statuses.CREATED) {
            let userElems = (
                <div className={"empty-message"}>{Rosetta.string("user_reports.detail_assigned_users_none")}</div>
            )
            if (assignedUsers.length > 0) {
                userElems = assignedUsers.map((user) => (
                    <li className={"list-group-item d-flex justify-content-between align-items-center"}>
                        <div className={"ms-2 me-auto"}>
                            <div className={"fw-bold"}>{Rosetta.string("common.name_format", { given_name : user.firstName, family_name : user.lastName })}</div>
                            <div>{user.roleName}</div>
                        </div>
                        <span className={"badge bg-danger rounded-pill clickable"} onClick={() => removeUserAssignment(user)}>{Rosetta.string("common.remove")}</span>
                    </li>
                ));

                userElems = (
                    <ul className={"list-group"}>
                        {userElems}
                    </ul>
                )
            }

            let saveButton = (<button className={"btn btn-success"} onClick={() => submitUserAssignmentOverNetwork()}>{Rosetta.string("common.submit")}</button>);
            if (assignNetworkInFlight) {
                saveButton = (
                    <LoadingSpinner small={true} />
                )
            }

            statusElem = (
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>

                                        <div className={"card"}>
                                            <div className={"card-header"}>
                                                <div className={"row align-items-center"}>
                                                    <div className={"col-12 col-md-6"}>
                                                        {Rosetta.string("user_reports.detail_assigned_users")}
                                                    </div>

                                                    <div className={"col-12 col-md-6 text-end"}>
                                                        <button className={"btn btn-primary"} onClick={() => setUserSelectionShown(true)}>{Rosetta.string("user_reports.detail_assigned_users_add")}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"card-body"}>

                                                {userElems}

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("user_reports.detail_required_date")}</label>
                                        <input type={"date"} className={"form-control"} value={requireDate} onChange={(e) => setRequireDate(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-12 text-center"}>
                                        {saveButton}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            )
        } else {
            let completionDateElem = [];
            let actions = [];
            let completionDetailsElem = [];

            if (inspection.completionDate !== null) {
                completionDateElem = (
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <label>{Rosetta.string("user_reports.detail_completed_date")}</label>
                            <div className={"form-control"}>{Chronos.withTimestampSeconds(inspection.completionDate).format("dd/MM/yyyy HH:mm")}</div>
                        </div>
                    </div>
                )
            }

            let statusId = parseInt(inspection.inspectionStatusId);

            if (statusId === Inspection.statuses.ACCEPTED || statusId === Inspection.statuses.IN_PROGRESS) {
                // Only show a reset button at this point
                actions = (
                    <div className={"row mt-4"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-primary"} onClick={() => promptResetInspection()}>
                                {Rosetta.string("user_reports.detail_action_reset")}
                            </button>
                        </div>
                    </div>
                )
            } else if (statusId === Inspection.statuses.COMPLETE) {
                // Show full details
                let completionImagesElem = [];

                let completionImages = findImagesByType(inspection.images, Inspection.imageTypes.COMPLETION);
                if (completionImages.length > 0) {
                    completionImagesElem = (
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("user_reports.detail_images")}</label>
                                <div className={"image-thumbs"}>
                                    {completionImages.map((image) => (
                                        <div
                                            className={"image-thumb image-preview"}
                                            style={{backgroundImage: ImageUtil.background(image.imagePath)}}
                                            onClick={() => {
                                                launchLightbox(image);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                }
            }


            statusElem = (
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>

                        <div className={"card"}>
                            <div className={"card-header"}>
                                {Rosetta.string("user_reports.detail_task_status")}
                            </div>

                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("user_reports.detail_status")}</label>
                                        <div className={"form-control"}>{inspection ? inspection.inspectionStatus : ""}</div>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("user_reports.detail_assigned_users")}</label>
                                        <div className={"form-control"}>{(inspection.completionUserId ? Rosetta.string("common.name_format", { given_name : inspection.completionUserFirstName, family_name : inspection.completionUserLastName}) : "" )}</div>
                                    </div>
                                </div>

                                {completionDateElem}

                                {completionDetailsElem}

                                {actions}

                            </div>
                        </div>

                    </div>
                </div>
            )
        }

        if (inspection.hasOwnProperty("template") && inspection.hasOwnProperty("submissions")) {
            if (inspection.template && inspection.submissions.length > 0) {

                inspection.submissions.forEach((submission) => {
                    let submissionRoot = [];

                    if (submission.hasOwnProperty("answers") && submission.answers) {
                        submission.answers.forEach((answer) => {
                            let question = null;
                            for (const q of inspection.template.questions) {
                                if (parseInt(answer.inspectionTemplateQuestionId) === parseInt(q.id)) {
                                    question = q;
                                    break;
                                }
                            }

                            let option = null;
                            if (question) {
                                for (const opt of question.options) {
                                    if (parseInt(opt.id) === parseInt(answer.inspectionTemplateQuestionOptionId)) {
                                        option = opt;
                                        break;
                                    }
                                }
                            }

                            let answerImages = [];
                            let imageElems = [];

                            if (submission.hasOwnProperty("images") && submission.images) {
                                submission.images.forEach((image) => {
                                    if (
                                        (image.siteId !== null && parseInt(image.siteId) === parseInt(answer.siteId))
                                        || (image.unitId !== null && parseInt(image.unitId) === parseInt(answer.unitId))
                                        || (image.bedroomId !== null && parseInt(image.bedroomId) === parseInt(answer.bedroomId))
                                        || (image.bedId !== null && parseInt(image.bedId) === parseInt(answer.bedId))
                                        || (image.siteId === null && image.unitId === null && image.bedroomId === null && image.bedId === null
                                        && answer.siteId === null && answer.unitId === null && answer.bedroomId === null && answer.bedId === null)
                                    ) {
                                        answerImages.push(image);
                                    }
                                });

                                imageElems = answerImages.map((image) => (
                                    <div className={"col-6 col-md-4 col-lg-3"}>
                                        <div
                                            className={"ratio ratio-1x1 image-preview center-crop clickable"}
                                            style={{backgroundImage: ImageUtil.background(image.thumbPath)}}
                                            onClick={() => showQuestionImage(image, answerImages)}
                                        ></div>
                                    </div>
                                ))

                                if (imageElems.length > 0) {
                                    imageElems = (
                                        <div className={"row mt-2"}>
                                            {imageElems}
                                        </div>
                                    )
                                }
                            }

                            let questionFocus = null;
                            if (answer.siteName) {
                                questionFocus = Rosetta.string("user_reports.inspection_focus_site", { title : answer.siteName });
                            } else if (answer.unitName) {
                                questionFocus = Rosetta.string("user_reports.inspection_focus_unit", { title : answer.unitName });
                            } else if (answer.bedroomName) {
                                questionFocus = Rosetta.string("user_reports.inspection_focus_bedroom", { title : answer.bedroomName });
                            } else if (answer.bedName) {
                                questionFocus = Rosetta.string("user_reports.inspection_focus_bed", { title : answer.bedName });
                            }

                            if (questionFocus !== null) {
                                questionFocus = (<div><strong>{questionFocus}</strong></div>);
                            }

                            let questionText = answer.questionText;
                            if (question) {
                                questionText = question.title;
                            }

                            submissionRoot.push(
                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>

                                        <div className={"card"}>
                                            <div className={"card-header"}>
                                                {questionFocus}
                                                {questionText}
                                            </div>
                                            <div className={"card-body"}>
                                                <div className={"row"}>
                                                    <div className={"col-12"}>
                                                        { option ? option.title : "" }
                                                    </div>
                                                </div>

                                                {imageElems}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        });
                    }

                    submissionElems.push(
                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-md-8 col-lg-6"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <strong>{Rosetta.string("user_reports.inspection_submission_title", { name : Rosetta.string("common.name_format", {given_name : submission.firstName, family_name : submission.lastName})})}</strong>
                                        <div>{Rosetta.string("user_reports.inspection_submission_date", { date : Chronos.withTimestampSeconds(submission.dateCreated).format("dd MMMM yyyy HH:mm:ss")})}</div>
                                    </div>

                                    <div className={"col-12"}>
                                        {submissionRoot}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );

                });

            }
        }

    }

    let deleteButton = [];
    if (inspection && !deleteNetworkInFlight) {
        deleteButton = (
            <button className={"btn btn-danger mt-3"} onClick={() => promptInspectionDelete()}>{Rosetta.string("common.delete")}</button>
        )
    }

    return (
        <div className={"worker-report-detail-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-10"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("user_reports.title_inspections")}</h1>
                        </div>
                    </div>

                    <div className={"col-12 col-md-2 text-end"}>
                        {deleteButton}
                    </div>
                </div>

                <div className={"animate-screen-content"}>

                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-md-8 col-lg-6"}>

                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_status")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {inspection ? inspection.inspectionStatus : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_location")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {inspection ? CommonUtil.implode([ inspection.siteName, inspection.unitName, inspection.bedroomName, inspection.bedName ]) : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    {descriptionElem}

                                    {photoElem}

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_reported_by")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {inspection ? Rosetta.string("common.name_format", { given_name : inspection.firstName, family_name : inspection.lastName }) : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("user_reports.detail_date")}</label>
                                            <div className={"form-control"}>
                                                <ContentOrPlaceholder showContent={showContent}>
                                                    {inspection ? Chronos.withTimestampSeconds(inspection.dateCreated).format("dd/MM/yyyy HH:mm") : ""}
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    {deadlineDateElem}

                                    {completionDateElem}

                                </div>
                            </div>

                        </div>
                    </div>

                    {statusElem}

                    {submissionElems}

                </div>

            </div>

            <Lightbox
                shown={lightboxShown}
                images={lightboxImages}
                initialImage={lightboxImage}
                callback={lightboxDidCallback} />

            <UserSelectionModal
                shown={userSelectionShown}
                userRoleIds={AppUser.getNonWorkerRoles()}
                mode={UserSelectionModalModes.MULTI}
                callback={userSelectionDidCallback} />

        </div>
    )

}