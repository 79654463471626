import {useParams} from "react-router-dom";
import Rosetta from "../../../../rosetta/Rosetta";
import {Container, DateTime, DropDown, Former, FormerActions, Text} from "../../../form/Former";
import {useEffect, useRef, useState} from "react";
import { v4 as uuidv4 } from "uuid";

import "./InspectionTemplateEditorScreen.css";

import deleteIcon from "../../../../assets/images/delete.svg";
import moveUpIcon from "../../../../assets/images/arrow_up.svg";
import moveDownIcon from "../../../../assets/images/arrow_down.svg";
import {ImageUtil} from "../../../../util/ImageUtil";
import ArrayUtil from "../../../../util/ArrayUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {DateUtil} from "../../../../util/DateUtil";
import {Toast} from "../../../toast/TokyoToaster";

export const InspectionTemplateEditorScreen = () => {

    const {id} = useParams();

    const [mutation, setMutation] = useState(undefined);

    const [template, setTemplate] = useState();
    const [templateNetworkInFlight, setTemplateNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [inspectionTypes, setInspectionTypes] = useState([]);
    const [typeNetworkInFlight, setTypeNetworkInFlight] = useState(false);

    const [formState, setFormState] = useState({});
    const [questions, setQuestions] = useState([]);

    const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);
    const [deletedOptionIds, setDeletedOptionIds] = useState([]);

    const [inspectionTriggers, setInspectionTriggers] = useState([]);
    const [inspectionTriggerNetworkInFlight, setInspectionTriggerNetworkInFlight] = useState(false);
    const [selectedInspectionTriggerIds, setSelectedInspectionTriggerIds] = useState([]);

    useEffect(() => {
        fetchInspectionTypesFromNetwork();
        getInspectionTemplateTriggersFromNetwork();
        if (!isNaN(id) && id !== "new") {
            fetchTemplateFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (template) {
            let eQuestions = [];

            let newFormState = {
                name : template.name,
                inspectionTemplateTypeId : template.inspectionTemplateTypeId,
                inspectionFrequency : template.inspectionFrequency,
                alertTimeframe : template.alertTimeframe,
                startDate : DateUtil.secondsToDateString(template.startDate)
            };

            if (template.hasOwnProperty("questions") && template.questions.length > 0) {
                template.questions.forEach((question) => {
                    delete question.ordering;
                    question.localId = uuidv4();

                    if (question.hasOwnProperty("options") && question.options.length > 0) {
                        question.options.forEach((option) => {
                            delete option.ordering;
                            option.localId = uuidv4();
                        });
                    }

                    eQuestions.push(question);
                })
            }

            setFormState(newFormState);
            setQuestions(eQuestions);

            if (template.hasOwnProperty("templateTriggerIds")) {
                setSelectedInspectionTriggerIds(template.templateTriggerIds);
            }
        }
    }, [template]);

    const submitAction = useRef();

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitTemplateOverNetwork(data.data);
            }
        } else if (action === FormerActions.HANDSHAKE) {
            submitAction.current = data.submit;
        }
    }

    function forceFormUpdate() {
        setMutation(Math.random());
    }

    function addQuestion() {
        let eQuestions = [...questions];
        eQuestions.push({
            localId : uuidv4(),
            title : "",
            requiresPhoto : 0,
            options : []
        });
        setQuestions(eQuestions);
    }

    function removeQuestion(localId) {
        let eQuestions = [...questions];
        for (let i = 0; i < eQuestions.length; i++) {
            if (eQuestions[i].localId === localId) {
                if (eQuestions[i].hasOwnProperty("id")) {
                    let deleteIds = [...deletedQuestionIds];
                    deleteIds.push(eQuestions[i].id);
                    setDeletedQuestionIds(deleteIds);
                }

                eQuestions.splice(i, 1);
                break;
            }
        }
        setQuestions(eQuestions);
    }

    function moveQuestion(localId, direction) {
        let eQuestions = [...questions];
        for (let i = 0; i < eQuestions.length; i++) {
            if (eQuestions[i].localId === localId) {
                if (direction === "up") {
                    if ((i - 1) >= 0) {
                        ArrayUtil.arrayMoveMutable(eQuestions, i, i - 1);
                    }
                } else if (direction === "down") {
                    if ((i + 1) < eQuestions.length) {
                        ArrayUtil.arrayMoveMutable(eQuestions, i, i + 1);
                    }
                }
            }
        }
        setQuestions(eQuestions);
    }

    function setQuestionProperty(localId, propertyName, value) {
        let question = findQuestion(localId);
        if (question) {
            question[propertyName] = value;
            forceFormUpdate();
        }
    }

    function findQuestion(localId) {
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].localId === localId) {
                return questions[i];
            }
        }
        return null;
    }

    function addQuestionOption(questionLocalId) {
        let question = findQuestion(questionLocalId);
        if (question) {
            if (!question.hasOwnProperty("options") || !question.options) {
                question.options = [];
            }

            question.options.push({
                localId : uuidv4(),
                title : "",
                raisesReport : 0,
                reportTitle : ""
            });
            forceFormUpdate();
        }
    }

    function removeQuestionOption(questionLocalId, localId) {
        let question = findQuestion(questionLocalId);
        if (question && question.options) {
            for (let i = 0; i < question.options.length; i++) {
                if (question.options[i].localId === localId) {
                    if (question.options[i].hasOwnProperty("id")) {
                        const deleteIds = [...deletedOptionIds];
                        deleteIds.push(question.options[i].id);
                        setDeletedOptionIds(deleteIds);
                    }

                    question.options.splice(i, 1);
                    break;
                }
            }
            forceFormUpdate();
        }
    }

    function moveQuestionOption(questionLocalId, localId, direction) {
        let question = findQuestion(questionLocalId);
        if (question) {
            if (question && question.options) {
                for (let i = 0; i < question.options.length; i++) {
                    if (question.options[i].localId === localId) {
                        if (direction === "up") {
                            let newIndex = i - 1;
                            if (newIndex >= 0) {
                                ArrayUtil.arrayMoveMutable(question.options, i, newIndex);
                            }
                        } else if (direction === "down") {
                            let newIndex = i + 1;
                            if (newIndex < question.options.length) {
                                ArrayUtil.arrayMoveMutable(question.options, i, newIndex);
                            }
                        }
                        forceFormUpdate();
                    }
                }
            }
        }
    }

    function setOptionProperty(questionLocalId, localId, propertyName, value) {
        let option = findQuestionOption(questionLocalId, localId);
        if (option) {
            option[propertyName] = value;
            forceFormUpdate();
        }
    }

    function findQuestionOption(questionLocalId, localId) {
        let question = findQuestion(questionLocalId);
        if (question && question.hasOwnProperty("options")) {
            for (let i = 0; i < question.options.length; i++) {
                if (question.options[i].localId === localId) {
                    return question.options[i];
                }
            }
        }
        return null;
    }

    // TRIGGERS
    function isTriggerChecked(triggerId, returnIndex) {
        if (returnIndex === undefined) {
            returnIndex = false;
        }

        for (let i = 0; i < selectedInspectionTriggerIds.length; i++) {
            if (parseInt(selectedInspectionTriggerIds[i]) === parseInt(triggerId)) {
                if (returnIndex) {
                    return i;
                }
                return true;
            }
        }
        return false;
    }

    function setTriggerChecked(triggerId) {
        const triggerIds = [...selectedInspectionTriggerIds];

        const index = isTriggerChecked(triggerId, true);

        if (index === false) {
            triggerIds.push(triggerId);
        } else {
            triggerIds.splice(index, 1);
        }

        setSelectedInspectionTriggerIds(triggerIds);
    }

    function fetchInspectionTypesFromNetwork() {
        if (typeNetworkInFlight) return;
        setTypeNetworkInFlight(true);

        Axios.post(ENDPOINTS.inspection.getInspectionTemplateTypes, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setInspectionTypes(resp.data.inspectionTemplateTypes);
                } else {
                    console.log(API.formatError(resp));
                }
                setTypeNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setTypeNetworkInFlight(false);
            })
    }

    function fetchTemplateFromNetwork() {
        if (templateNetworkInFlight) return;
        setTemplateNetworkInFlight(true);

        let data = {
            inspectionTemplateIds : [id],
            page : 1,
            pageSize : 1,
            fetchQuestions : true,
            fetchTriggers : true
        };

        if (!isNaN(id) && id !== "new") {
            data.id = parseInt(id);
        }

        Axios.post(ENDPOINTS.inspection.getInspectionTemplates, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        let newTemplate = resp.data.data[0];
                        setTemplate(newTemplate);
                    } else {
                        // TODO ERROR Cannot find!
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setTemplateNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setTemplateNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ITF1000C"));
            })
    }

    function submitTemplateOverNetwork(formState) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        let useId = undefined;
        if (!isNaN(id) && id !== "new") {
            useId = id;
        }

        let data = {
            id : useId,
            name : formState.name,
            inspectionTemplateTypeId : formState.inspectionTemplateTypeId,
            inspectionFrequency : formState.inspectionFrequency,
            alertTimeframe : formState.alertTimeframe,
            startDate : formState.startDate,
            questions : JSON.stringify(questions),
            deletedQuestionIds : JSON.stringify(deletedQuestionIds),
            deletedOptionIds : JSON.stringify(deletedOptionIds),
            inspectionTemplateTriggerIds : JSON.stringify(selectedInspectionTriggerIds)
        };

        Axios.post(ENDPOINTS.inspection.submitInspectionTemplate, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setTemplate(resp.data.template);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("inspection_templates.editor_save_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ITS1000C"));
            });
    }

    function getInspectionTemplateTriggersFromNetwork() {
        if (inspectionTriggerNetworkInFlight) return;
        setInspectionTriggerNetworkInFlight(true);

        Axios.post(ENDPOINTS.inspection.getInspectionTemplateTriggers, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setInspectionTriggers(resp.data.inspectionTemplateTriggers);
                } else {
                    console.log(API.formatError(resp));
                }
                setInspectionTriggerNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                console.log(API.defaultError("ITTF1000C"));
            })
    }

    // RENDER

    const frequencies = [
        { value : "1D", label : Rosetta.string("inspection_templates.editor_frequency_daily") },
        { value : "1W", label : Rosetta.string("inspection_templates.editor_frequency_weekly") },
        { value : "1M", label : Rosetta.string("inspection_templates.editor_frequency_monthly") },
        { value : "6M", label : Rosetta.string("inspection_templates.editor_frequency_six_monthly") },
        { value : "1Y", label : Rosetta.string("inspection_templates.editor_frequency_annually") },
    ];

    const dayInSeconds = 86400;
    const timeframes = [];
    for (let i = 1; i <= 7; i++) {
        timeframes.push(
            { value : i * dayInSeconds, label : Rosetta.string("time.days", { days : i}, i) }
        )
    }

    let triggerElems = [];

    if (inspectionTriggers.length > 0) {
        triggerElems = inspectionTriggers.map((trigger) => (
            <div className={"col-12 col-md-6 col-lg-4"}>
                <label className={"list-group-item list-group-item-action"}>
                    <input type={"checkbox"} checked={isTriggerChecked(trigger.id)} onChange={() => setTriggerChecked(trigger.id)} />
                    &nbsp;
                    {trigger.name}
                </label>
            </div>
        ));

        if (triggerElems.length > 0) {
            triggerElems = (
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>

                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h3>{Rosetta.string("inspection_templates.editor_triggers")}</h3>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    {triggerElems}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }

    let questionElems = [];
    if (questions.length > 0) {
        questions.forEach((question, index) => {
            let answerElems = [];
            if (question.hasOwnProperty("options") && question.options.length > 0) {
                question.options.forEach((option, aIndex) => {
                    let reportTitleElem = [];

                    let raisesReport = parseInt(option.raisesReport) === 1;
                    if (raisesReport) {
                        reportTitleElem = (
                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("inspection_templates.editor_option_issue_title")}</label>
                                    <input type={"text"} className={"form-control"} value={option.reportTitle} onChange={(e) => setOptionProperty(question.localId, option.localId, "reportTitle", e.target.value)} />
                                </div>
                            </div>
                        )
                    }

                    answerElems.push(
                        <div className={"row"}>
                            <div className={"col-12"}>

                                <div className={"card card-default"}>
                                    <div className={"card-header d-flex align-items-center p-0"}>
                                        <div className={"card-title p-3"}>{Rosetta.string("inspection_templates.editor_option_header_title", { number : (aIndex + 1)}, aIndex + 1)}</div>
                                        <div className={"question-title-actions ml-auto"}>
                                            <div className={"question-title-action"} style={{backgroundImage : ImageUtil.background(moveUpIcon)}} onClick={() => moveQuestionOption(question.localId, option.localId, "up")} />
                                            <div className={"question-title-action"} style={{backgroundImage : ImageUtil.background(moveDownIcon)}} onClick={() => moveQuestionOption(question.localId, option.localId, "down")} />
                                            <div className={"question-title-action"} style={{backgroundImage : ImageUtil.background(deleteIcon)}} onClick={() => removeQuestionOption(question.localId, option.localId)} />
                                        </div>
                                    </div>

                                    <div className={"card-body"}>

                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <label>{Rosetta.string("inspection_templates.editor_option_title")}</label>
                                                <input type={"text"} className={"form-control"} value={option.title} onChange={(e) => setOptionProperty(question.localId, option.localId, "title", e.target.value)} />
                                            </div>
                                        </div>

                                        <div className={"row mt-2"}>
                                            <div className={"col-12"}>
                                                <label><input type={"checkbox"} checked={parseInt(option.raisesReport) === 1} onChange={(e) => setOptionProperty(question.localId, option.localId, "raisesReport", e.target.checked ? 1 : 0)} /> {Rosetta.string("inspection_templates.editor_option_raises_issue")}</label>
                                            </div>
                                        </div>

                                        {reportTitleElem}

                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })
            } else {
                answerElems = (
                    <div className={"empty-message"}>{Rosetta.string("inspection_templates.editor_question_answers_empty")}</div>
                )
            }

            questionElems.push(
                <div className={"row mt-2"} key={"q_" + question.localId}>
                    <div className={"col-12"}>

                        <div className={"card"}>
                            <div className={"card-header d-flex align-items-center p-0"}>
                                <div className={"card-title p-3"}>{Rosetta.string("inspection_templates.editor_question_title", { number : (index + 1)}, index + 1)}</div>
                                <div className={"question-title-actions ml-auto"}>
                                    <div className={"question-title-action"} style={{backgroundImage : ImageUtil.background(moveUpIcon)}} onClick={() => moveQuestion(question.localId, "up")} />
                                    <div className={"question-title-action"} style={{backgroundImage : ImageUtil.background(moveDownIcon)}} onClick={() => moveQuestion(question.localId, "down")} />
                                    <div className={"question-title-action"} style={{backgroundImage : ImageUtil.background(deleteIcon)}} onClick={() => removeQuestion(question.localId)} />
                                </div>
                            </div>

                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("inspection_templates.editor_question_text")}</label>
                                        <textarea className={"form-control"} value={question.title} onChange={(e) => setQuestionProperty(question.localId, "title", e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label><input type={"checkbox"} checked={parseInt(question.requiresPhoto) === 1} onChange={(e) => setQuestionProperty(question.localId, "requiresPhoto", e.target.checked ? 1 : 0)} /> {Rosetta.string("inspection_templates.editor_question_photo")}</label>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <h4>{Rosetta.string("inspection_templates.editor_question_answers")}</h4>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        {answerElems}
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12 text-center"}>
                                        <button className={"btn btn-primary"} onClick={() => addQuestionOption(question.localId)}>{Rosetta.string("inspection_templates.editor_option_add")}</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            )
        });
    } else {
        questionElems = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <div className={"empty-message"}>{Rosetta.string("inspection_templates.editor_section_questions_empty")}</div>
                </div>
            </div>
        )
    }

    return (
        <div className={"inspection-template-editor-screen"}>
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"content-header"}>
                            <h1>{Rosetta.string("inspection_templates.title")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"row justify-content-center mt-4"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>

                                        <Former
                                            state={formState}
                                            callback={formDidCallback}>

                                            <Container className={"row"}>
                                                <Container classsName={"col-12"}>
                                                    <Text name={"name"} label={Rosetta.string("inspection_templates.editor_title")} className={"form-control"} />
                                                </Container>
                                            </Container>

                                            <Container className={"row"}>
                                                <Container className={"col-12"}>
                                                    <DropDown name={"inspectionFrequency"} className={"form-select"} label={Rosetta.string("inspection_templates.editor_frequency")}>
                                                        {frequencies.map((frequency) => (
                                                            <option value={frequency.value}>{frequency.label}</option>
                                                        ))}
                                                    </DropDown>
                                                </Container>
                                            </Container>

                                            <Container className={"row"}>
                                                <Container className={"col-12"}>
                                                    <DropDown name={"alertTimeframe"} className={"form-select"} label={Rosetta.string("inspection_templates.editor_alert_timeframe")}>
                                                        {timeframes.map((timeframe) => (
                                                            <option value={timeframe.value}>{timeframe.label}</option>
                                                        ))}
                                                    </DropDown>
                                                </Container>
                                            </Container>

                                            <Container className={"row"}>
                                                <Container classsName={"col-12"}>
                                                    <DateTime name={"startDate"} label={Rosetta.string("inspection_templates.editor_start_date")} className={"form-control"} />
                                                </Container>
                                            </Container>

                                            <Container className={"row"}>
                                                <Container className={"col-12"}>
                                                    <DropDown name={"inspectionTemplateTypeId"} className={"form-select"} label={Rosetta.string("inspection_templates.editor_inspection_location")}>
                                                        {inspectionTypes.map((type) => (
                                                            <option value={type.id}>{type.name}</option>
                                                        ))}
                                                    </DropDown>
                                                </Container>
                                            </Container>

                                        </Former>


                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                {triggerElems}

                <div className={"row justify-content-center mt-4"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>

                        {questionElems}

                        <div className={"row mt-4"}>
                            <div className={"col-12 text-center"}>
                                <button className={"btn btn-primary"} onClick={() => addQuestion()}>{Rosetta.string("inspection_templates.editor_question_add")}</button>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>

                                <div className={"card"}>
                                    <div className={"card-body"}>

                                        <div className={"row"}>
                                            <div className={"col-12 text-center"}>
                                                <button className={"btn btn-primary"} onClick={() => submitAction.current()}>{Rosetta.string("common.save")}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )

}