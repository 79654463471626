import {useState} from "react";
import {WorkerReportEntryBasicScreen} from "./WorkerReportEntryBasicScreen";
import {CommonUtil} from "../../../../util/CommonUtil";
import {WorkerReportEntryDescriptionScreen} from "./WorkerReportEntryDescriptionScreen";
import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import imageCompression from "browser-image-compression";
import {WorkerReportEntrySuccessScreen} from "./WorkerReportEntrySuccessScreen";
import {UIBlocker} from "../../../loading/UIBlocker";

export const WorkerReportEntryLandingScreen = (props) => {

    const STEP_BASIC_DETAILS = 1;
    const STEP_DESCRIPTION = 2;
    const STEP_COMPLETE = 3;

    const [reportState, setReportState] = useState({});
    const [step, setStep] = useState(STEP_BASIC_DETAILS);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    function basicScreenDidCallback(action, data) {
        if (action === "submit") {
            let newState = CommonUtil.mergeObjects(reportState, data);
            setReportState(newState);
            setStep(STEP_DESCRIPTION);
        }
    }

    function descriptionScreenDidCallback(action, data) {
        if (action === "submit") {
            let newState = CommonUtil.mergeObjects(reportState, data);
            setReportState(newState);
            submitReportOverNetwork(newState);
        }
    }

    function completeDidCallback(action, data) {
        if (action === "done") {
            resetReport();
        }
    }

    function resetReport() {
        setReportState({});
        setStep(STEP_BASIC_DETAILS);
    }

    function submitReportOverNetwork(state) {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        if (state === undefined) {
            state = reportState;
        }

        let images = state.images;
        let data = CommonUtil.cloneObject(state);
        delete data.images;

        Axios.post(ENDPOINTS.workerReport.submitWorkerReport, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    uploadWorkerReportImage(resp.data.id, images, 0);
                } else {
                    AlertModal.showError(API.formatError(resp));
                    setNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("WRS1000C"));
            });
    }

    function uploadWorkerReportImage(id, images, index) {
        setNetworkInFlight(true);

        if (images.length === 0 || index >= images.length) {
            setStep(STEP_COMPLETE);
            setNetworkInFlight(false);
            return;
        }

        const image = images[index].file;

        imageCompression(image, {
                maxSizeMB : 2,
                maxWidthOrHeight : 1280,
                useWebWorker : true,
                initialQuality : 0.9
            })
            .then((compressedImage) => {
                let formData = new FormData();
                formData.append("workerReportId", id);
                formData.append("file", compressedImage);

                Axios.post(ENDPOINTS.workerReport.uploadWorkerReportImage, formData)
                    .then((r) => {
                        const resp = API.parse(r);
                        if (!resp.success) {
                            console.log("IMAGE UPLOAD FAILURE");
                            console.log(API.formatError(resp));
                        }
                        uploadWorkerReportImage(id, images, index + 1);
                    })
                    .catch((e) => {
                        console.log(e);
                        uploadWorkerReportImage(id, images, index + 1);
                    });
            })
            .catch((e) => {
                console.log(e);
                uploadWorkerReportImage(id, images, index + 1);
            })
    }

    let mainContent = [];

    if (step === STEP_BASIC_DETAILS) {
        mainContent = <WorkerReportEntryBasicScreen callback={basicScreenDidCallback} />
    } else if (step === STEP_DESCRIPTION) {
        mainContent = (
            <WorkerReportEntryDescriptionScreen
                callback={descriptionScreenDidCallback}
                images={(reportState ? reportState.images : undefined)} />
        )
    } else if (step === STEP_COMPLETE) {
        mainContent = (
            <WorkerReportEntrySuccessScreen callback={completeDidCallback} />
        )
    }

    return (
        <div className={"app-screen worker-report-entry-landing-screen"}>
            <div className={"row mb-4"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("worker_report.title")} />
                </div>
            </div>

            {mainContent}

            <UIBlocker shown={networkInFlight} />
        </div>
    )

}