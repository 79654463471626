import {BaseModal, BaseModalActions} from "../../../alertmodal/BaseModal";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {CommonUtil} from "../../../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {Toast} from "../../../toast/TokyoToaster";
import Validator from "../../../../util/Validator";

export const SiteCopyModalModes  = {
    SITE : 1,
    UNIT : 2,
    BEDROOM : 3
}

export const SiteCopyModal = (props) => {

    const {shown} = props;
    const {mode} = props;
    const {id} = props;
    const {name} = props;
    const {callback} = props;

    const {siteId} = props;
    const {unitId} = props;
    const {bedroomId} = props;

    const [filterData, setFilterData] = useState({});
    const [filtersNetworkInFlight, setFiltersNetworkInFlight] = useState(false);

    const [submissionNetworkInFlight, setSubmissionNetworkInFlight] = useState(false);

    const [copyName, setCopyName] = useState(name);
    const [selectionData, setSelectionData] = useState({});
    const [error, setError] = useState(null);

    const [callbackData, setCallbackData] = useState(undefined);

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            setCopyName(name);

            console.log("COPY SHOWN: " + siteId + ", " + unitId + ", " + bedroomId);

            setSelectionData({
                siteId,
                unitId,
                bedroomId
            });

            // fetchFilterDataFromNetwork();
        } else {
            setFilterData({});
            setSelectionData({});
            setCopyName("");
            setForceDismiss(false);
            setCallbackData(undefined);
        }
    }, [shown]);

    useEffect(() => {
        if (shown) {
            fetchFilterDataFromNetwork();
        }
    }, [selectionData]);

    function handleCallback(action, data) {
        if (callback) {
            if (!data) {
                data = callbackData;
            }
            callback(action, data);
        }
    }

    function getFilterSelection(key, defaultValue) {
        return CommonUtil.getOrDefault(selectionData, key, defaultValue);
    }

    function setFilterSelection(key, value) {
        let sd = CommonUtil.cloneObject(selectionData);
        sd[key] = value;
        setSelectionData(sd);
    }

    function getSelectionSiteId() {
        let out = CommonUtil.getOrDefault(selectionData, "siteId", undefined);
        if (!out) {
            let sites = CommonUtil.getOrDefault(filterData, "sites", []);
            if (sites.length > 0) {
                out = sites[0].id;
            }
        }
        return out;
    }

    function getSelectionUnitId() {
        let out = CommonUtil.getOrDefault(selectionData, "unitId", undefined);
        if (!out) {
            let units = CommonUtil.getOrDefault(filterData, "units", []);
            if (units.length > 0) {
                out = units[0].id;
            }
        }
        return out;
    }

    function getSelectionBedroomId() {
        let out = CommonUtil.getOrDefault(selectionData, "bedroomId", undefined);
        if (!out) {
            let bedrooms = CommonUtil.getOrDefault(filterData, "bedrooms", []);
            if (bedrooms.length > 0) {
                out = bedrooms[0].id;
            }
        }
        return out;
    }

    function fetchFilterDataFromNetwork() {
        if (filtersNetworkInFlight) return;
        setFiltersNetworkInFlight(true);

        const data = {
            siteId : CommonUtil.getOrDefault(selectionData, "siteId", undefined),
            unitId : CommonUtil.getOrDefault(selectionData, "unitId", undefined),
            bedroomIds : CommonUtil.getOrDefault(selectionData, "bedroomIds", undefined)
        };

        Axios.post(ENDPOINTS.inspection.getFilterData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFilterData(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setFiltersNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFiltersNetworkInFlight(false);
            });
    }

    function copyTargetOverNetwork() {
        if (submissionNetworkInFlight) return;
        setError(null);

        // Put together data...
        const data = {
            id,
            name : copyName,
            targetSiteId : getSelectionSiteId(),
            targetUnitId : getSelectionUnitId()
        };

        // Work out mode...
        const useMode = mode ? mode : SiteCopyModalModes.SITE;

        // Set basic validation
        let validationRules = [
            Validator.rule("name", "string", Rosetta.string("site_plans.copy_name"), "name", false)
        ];

        // Work out URL and specific Validation properties...
        let url = ENDPOINTS.site.copySite;
        if (useMode === SiteCopyModalModes.UNIT) {
            url = ENDPOINTS.unit.copyUnit;

            validationRules.push(Validator.rule("targetSiteId", "int", Rosetta.string("site_plans.copy_modal_title_site"), "siteId", false));
        }

        if (useMode === SiteCopyModalModes.BEDROOM) {
            url = ENDPOINTS.unit.copyBedroom;

            validationRules.push(Validator.rule("targetUnitId", "int", Rosetta.string("site_plans.copy_modal_title_unit"), "unitId", false));
        }

        // Now validate...
        const validationResult = Validator.validateCreateFormData(data, validationRules);
        if (!validationResult.success) {
            setError(validationResult.error);
            return;
        }

        // All good? Now set network in flight...
        setSubmissionNetworkInFlight(true);

        Axios.post(url, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let successMessageRes = "site_plans.copy_success_site";
                    if (useMode === SiteCopyModalModes.UNIT) {
                        successMessageRes = "site_plans.copy_success_unit";
                    } else if (useMode === SiteCopyModalModes.BEDROOM) {
                        successMessageRes = "site_plans.copy_success_bedroom";
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string(successMessageRes),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setCallbackData(true);
                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setSubmissionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmissionNetworkInFlight(false);
                setError(API.defaultError("SCM1000C_" + useMode));
            });
    }


    // RENDER

    if (!shown) return [];

    let useMode = mode;
    if (!useMode) {
        useMode = SiteCopyModalModes.SITE;
    }

    let titleSubject = "site_plans.copy_modal_title_site";


    if (useMode === SiteCopyModalModes.UNIT) {
        titleSubject = "site_plans.copy_modal_title_unit";
    } else if (useMode === SiteCopyModalModes.BEDROOM) {
        titleSubject = "site_plans.copy_modal_title_bedroom";
    }
    titleSubject = Rosetta.string(titleSubject);

    const bodyElems = [];
    if (useMode === SiteCopyModalModes.UNIT || useMode === SiteCopyModalModes.BEDROOM) {
        bodyElems.push(
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("site_plans.copy_modal_title_site")}</label>
                    <select className={"form-select"} value={getFilterSelection("siteId", null)} onChange={(e) => setFilterSelection("siteId", e.target.value)}>
                        {
                            CommonUtil.getOrDefault(filterData, "sites", []).map((site) => (
                                <option value={site.id}>{site.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        )
    }

    if (useMode === SiteCopyModalModes.BEDROOM) {
        bodyElems.push(
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("site_plans.copy_modal_title_unit")}</label>
                    <select className={"form-select"} value={getFilterSelection("unitId", null)} onChange={(e) => setFilterSelection("unitId", e.target.value)}>
                        {
                            CommonUtil.getOrDefault(filterData, "units", []).map((unit) => (
                                <option value={unit.id}>{unit.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        )
    }

    const modalTitle = Rosetta.string("site_plans.copy_modal_title", { subject : titleSubject });

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"alert-modal-dialog-title"}>
                {modalTitle}
            </div>

            <div className={"alert-modal-dialog-message"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        {Rosetta.string("site_plans.copy_modal_message")}
                    </div>

                    {errorElem}

                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <label>{Rosetta.string("site_plans.copy_name", { subject : titleSubject })}</label>
                            <input type={"text"} className={"form-control"} value={copyName} onChange={(e) => setCopyName(e.target.value)} />
                        </div>
                    </div>

                    {bodyElems}

                </div>

            </div>

            <div className={"alert-modal-dialog-buttons"}>
                <div className={"alert-dialog-button success"} onClick={() => copyTargetOverNetwork()}>{modalTitle}</div>
                <div className={"alert-dialog-button"} onClick={() => handleCallback(BaseModalActions.CLOSE)}>{Rosetta.string("common.cancel")}</div>
            </div>

        </BaseModal>
    )

}