import Rosetta from "../../../rosetta/Rosetta";
import {useState} from "react";
import {EventUtil} from "../../../util/EventUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {DataManager} from "../../../data/DataManager";
import Validator from "../../../util/Validator";
import {ImageUtil} from "../../../util/ImageUtil";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

import "./LoginScreen.css";

import loginBg from "../../../assets/images/hops-gateway-login-bg.jpeg";
import systemLogo from "../../../assets/images/two_knights_logo.png";

export const LoginScreen = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [networkInFlight, setNetworkInFlight] = useState(false);

    function formWasSubmitted(e) {
        EventUtil.cancel(e);

        submitLoginOverNetwork();
    }

    function onForgottenPasswordClicked() {
        AlertModal.showModal(
            Rosetta.string("login.forgotten_password"),
            Rosetta.string("login.forgotten_password_message"),
            [
                AlertModal.button(Rosetta.string("login.forgotten_password_action"), () => {
                    window.location.href = process.env.REACT_APP_GATEWAY_URL;
                    AlertModal.dismissModal();
                }, "success"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function submitLoginOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const validationResult = Validator.validateCreateFormData({
            username, password
        }, [
            Validator.rule("username", "string", Rosetta.string("login.username"), "username"),
            Validator.rule("password", "string", Rosetta.string("login.password"))
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        Axios.post(ENDPOINTS.auth.login, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    DataManager.setSessionToken(resp.data.sessionToken);
                    DataManager.setUser(resp.data.user);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("AL1000C"));
            });
    }

    let loginButton = (<button className={"btn btn-success login-button"}>{Rosetta.string("login.login")}</button>);
    if (networkInFlight) {
        loginButton = (<LoadingSpinner inline={true} small={true} />)
    }

    return (
        <div className={"app-screen login-screen"} style={{backgroundImage : ImageUtil.background(loginBg)}}>
            <div className={"container"}>
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-6 col-lg-4"}>

                        <div className={"login-container"}>
                            <form onSubmit={formWasSubmitted}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <span className={"login-title"}>{Rosetta.string("login.title")}</span>
                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("login.username")}</label>
                                        <input type={"text"} className={"form-control"} value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("login.password")}</label>
                                        <input type={"password"} className={"form-control"} value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col-12 text-center"}>
                                        {loginButton}
                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col-12 text-center"}>
                                        <span className={"forgotten-link"} onClick={() => onForgottenPasswordClicked()}>{Rosetta.string("login.forgotten_password")}</span>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div className={"system-logo"} style={{backgroundImage: ImageUtil.background(systemLogo)}} />
        </div>
    )

}