import {NavLink} from "react-router-dom";
import Rosetta from "../../../rosetta/Rosetta";

import "./AdminLTESidebar.css";
import {useEffect, useState} from "react";

import sha256 from 'crypto-js/sha256';
import {DataManager} from "../../../data/DataManager";
import {Navigator} from "../../../util/Navigator";

export const AdminLTESidebar = (props) => {

    const {brandTitle} = props;
    const {user} = props;
    const {items} = props;
    const {expanded} = props;

    const [expandedItems, setExpandedItems] = useState([]);

    useEffect(() => {
        restoreExpandedItems();
        handleBodyClassMutation();
    }, []);

    useEffect(() => {
        handleBodyClassMutation();
    }, [expanded]);

    function restoreExpandedItems() {
        let state = DataManager.getJsonItem(DataManager.keys.sidebarState, null);
        if (state) {
            setExpandedItems(state);
        }
    }

    function saveExpandedItems() {
        DataManager.setJsonItem(DataManager.keys.sidebarState, expandedItems);
    }

    function isItemExpanded(id, returnIndex) {
        if (returnIndex === undefined) {
            returnIndex = false;
        }

        for (let i = 0; i < expandedItems.length; i++) {
            let item = expandedItems[i];
            if (item.id === id) {
                if (returnIndex) {
                    return i;
                }
                return true;
            }
        }
        return false;
    }

    function setItemExpanded(id) {
        const expItems = [...expandedItems];
        const existIndex = isItemExpanded(id, true);
        if (existIndex !== false) {
            expItems.splice(existIndex, 1);
        } else {
            expItems.push({
                id
            });
        }
        setExpandedItems(expItems);
        saveExpandedItems();
    }

    function onItemClicked(event, id, item) {
        event.preventDefault();
        event.stopPropagation();

        if (item.hasOwnProperty("items") && item.items && item.items.length > 0) {
            setItemExpanded(id);
        } else {
            Navigator.navigate(item.url);
        }
    }

    function handleBodyClassMutation() {
        if (!document.body.classList.contains("sidebar-mini")) {
            document.body.classList.add("sidebar-mini");
        }

        if (expanded) {
            if (document.body.classList.contains("sidebar-collapse")) {
                document.body.classList.remove("sidebar-collapse");
            }
        } else {
            if (!document.body.classList.contains("sidebar-collapse")) {
                document.body.classList.add("sidebar-collapse");
            }
        }
    }

    function processNavItem(item, parentId) {
        if (!item) return [];

        let id = sha256(JSON.stringify(item)).toString();

        let isExpanded = isItemExpanded(id);

        let out = [];

        let items = [];
        if (!parentId || isExpanded) {
            if (item.hasOwnProperty("items") && item.items) {
                for (let child of item.items) {
                    items.push(processNavItem(child, id));
                }
            }
        }

        let badge = [];
        if (item.hasOwnProperty("badge") && item.badge) {
            badge = (
                <span className={"right badge badge-danger"}>{item.badge}</span>
            )
        }

        let icon = [];
        if (item.hasOwnProperty("icon") && item.icon) {
            icon = (
                <i className={"nav-icon fas " + item.icon} />
            )
        }

        let chevron = [];
        if (item.hasOwnProperty("items") && item.items && item.items.length > 0) {
            chevron = (
                <i className="fas fa-angle-left right" />
            );
        }

        if (item.hasOwnProperty("url") && item.url !== undefined) {
            if (items.length > 0) {
                items = (
                    <ul className={"nav nav-treeview"}>
                        {items}
                    </ul>
                );
            }

            let extraClass = "";
            if (isExpanded) {
                extraClass += " menu-open";
            }

            out = (
                <li className={"nav-item" + extraClass}>
                    <NavLink to={item.url} className={"nav-link"} onClick={(e) => onItemClicked(e, id, item)}>
                        {icon}
                        <p>
                            {item.title}
                            {badge}
                            {chevron}
                        </p>
                    </NavLink>

                    {items}
                </li>
            )
        } else {
            let titleElem = [];
            if (item.title) {
                titleElem = (
                    <li className={"nav-header"}>
                        {item.title}
                        {chevron}
                    </li>
                );
            }

            out = (
                <>
                    {icon}
                    {titleElem}
                    {items}
                </>
            )
        }

        return out;
    }

    let userElem = [];
    // if (user) {
    //     userElem = (
    //         <div className={"user-panel mt-3 pb-3 mb-3 d-flex"}>
    //             <div className={"image"}>
    //                 <img src={""} className={"img-circle elevation-2"} alt={"TODO"} />
    //             </div>
    //             <div className={"info"}>
    //                 <NavLink to={"#"} className={"d-block"}>USER USER</NavLink>
    //             </div>
    //         </div>
    //     );
    // }

    let navContent = [];
    if (items) {
        for (let item of items) {
            if (item) {
                navContent.push(processNavItem(item));
            }
        }

        if (navContent.length > 0) {
            navContent = (
                <div className={"mt-2"}>
                    <ul className={"nav nav-pills nav-sidebar flex-column"} role={"menu"}>
                        {navContent}
                    </ul>
                </div>
            );
        }
    }

    console.log("EXPANDED: " + expanded);
    let sidebarClass = "os-host-scrollbar-horizontal-hidden";
    if (!expanded) {
        sidebarClass = "os-host-overflow-x";
    }

    return (
        <aside className={"main-sidebar"}>
            <div className={"sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-transition " + sidebarClass}>
                <div className={"os-resize-observer-host observed"}>
                    <div className={"os-resize-observer"} style={{left : 0, right: "auto"}} />
                </div>

                <div className={"os-padding"}>
                    <div className={"os-viewport os-viewport-native-scrollbars-invisible"} style={{overflowY : "scroll"}}>
                        <div className={"os-content"} style={{padding: "0 8px", height : "100%", width: "100%"}}>

                            {userElem}

                            {navContent}

                        </div>
                    </div>
                </div>
            </div>

        </aside>
    )

}