import Rosetta from "../../../../rosetta/Rosetta";
import {CommonUtil} from "../../../../util/CommonUtil";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {API, ENDPOINTS} from "../../../../network/API";
import {Column} from "../../../table/TableComponent";
import {useEffect, useState} from "react";
import {Chronos} from "../../../../chronos/Chronos";
import Axios from "axios";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Toast} from "../../../toast/TokyoToaster";

export const EvacuationListScreen = (props) => {

    const [sites, setSites] = useState([]);
    const [sitesNetworkInFlight, setSitesNetworkInFlight] = useState(false);

    const [siteId, setSiteId] = useState(null);

    const [tableData, setTableData] = useState({
        startDate : Chronos.now().seconds(),
        endDate : Chronos.now().seconds()
    });
    const [page, setPage] = useState(1);

    const [exportInFlight, setExportInFlight] = useState(false);

    useEffect(() => {
        fetchSitesFromNetwork();
    }, []);

    useEffect(() => {
        let useSiteId = siteId;
        if (siteId < 0) {
            useSiteId = undefined;
        }
        setTableDataProperty("siteId", useSiteId);
    }, [siteId]);

    function setTableDataProperty(keyName, value) {
        let td = CommonUtil.cloneObject(tableData);
        td[keyName] = value;
        setTableData(td);
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data);
        }
    }

    function fetchSitesFromNetwork() {
        if (sitesNetworkInFlight) return;
        setSitesNetworkInFlight(true);

        Axios.post(ENDPOINTS.site.getSites, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSites(resp.data.data);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSitesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSitesNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SEL1000C"));
            });
    }

    function exportListOverNetwork() {
        if (exportInFlight) return;
        setExportInFlight(true);

        const data = {
            siteId
        };

        Axios.post(ENDPOINTS.site.exportEvacuationList, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("evac_list.export_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    window.location.href = resp.data.downloadUri;
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExportInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setExportInFlight(false);
                AlertModal.showError(API.defaultError("SEPE1000C"));
            })
    }

    // RENDER

    let exportButton = (<button className={"btn btn-primary"} onClick={() => exportListOverNetwork()}>{Rosetta.string("evac_list.action_export")}</button>);
    if (exportInFlight) {
        exportButton = (<button className={"btn btn-primary disabled"}>{Rosetta.string("evac_list.action_exporting")}</button>)
    }

    // <Column name={"preferredLanguage"} title={Rosetta.string("evac_list.table_pref_lang")} />

    return (
        <div className={"container-fluid"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"content-header"}>
                        <h1>{Rosetta.string("evac_list.title")}</h1>
                    </div>
                </div>
            </div>

            <div className={"filing-inspections-component animate-screen-content"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-6 col-lg-4 col-xl-3"}>
                        <label>{Rosetta.string("evac_list.filter_site")}</label>
                        <select className={"form-select"} value={siteId} onChange={(e) => setSiteId(e.target.value)}>
                            <option value={"-1"}>{Rosetta.string("evac_list.filter_site_all")}</option>
                            {sites.map((site) => (
                                <option value={site.id}>{site.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-8 col-xl-9 text-end"}>
                        {exportButton}
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <AutoTable
                                    url={ENDPOINTS.site.getEvacuationList}
                                    data={tableData}
                                    page={page}
                                    callback={tableDidCallback}>

                                    <Column name={"firstName"} title={Rosetta.string("evac_list.table_first_name")}  />
                                    <Column name={"lastName"} title={Rosetta.string("evac_list.table_last_name")} />
                                    <Column name={"genderName"} title={Rosetta.string("evac_list.table_gender")} />
                                    <Column name={"age"} title={Rosetta.string("evac_list.table_age")} />
                                    <Column name={"nationality"} title={Rosetta.string("evac_list.table_nationality")} />
                                    <Column name={"siteName"} title={Rosetta.string("evac_list.table_site")} />
                                    <Column name={"unitName"} title={Rosetta.string("evac_list.table_unit")} />
                                    <Column name={"unitBedroomName"} title={Rosetta.string("evac_list.table_bedroom")} />
                                    <Column name={"unitBedroomBedName"} title={Rosetta.string("evac_list.table_bed")} />

                                </AutoTable>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )

}