import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import Rosetta from "../../../../rosetta/Rosetta";
import {Button, Container, Former, FormerActions, Text} from "../../../form/Former";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import {Toast} from "../../../toast/TokyoToaster";
import WindowUtil from "../../../../util/WindowUtil";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";

export const UnitInventoryViewerModal = (props) => {

    const {shown} = props;
    const {siteId} = props;
    const {unitId} = props;
    const {inventory} = props;
    const {callback} = props;

    const [inventoryId, setInventoryId] = useState(undefined);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [formState, setFormState] = useState({});

    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(undefined);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setInventoryId(undefined);
            setSubmitNetworkInFlight(false);
            setFormState({});
            setCallbackData(undefined);
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            if (!data) {
                data = callbackData;
            }

            callback(action, data);
        }
    }

    function editInventory(inventory) {
        setInventoryId(inventory.id);
        setFormState(inventory);
    }

    function clearForm() {
        setInventoryId(undefined);
        setFormState({});
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitInventoryOverNetwork(data.data);
            }
        }
    }

    function submitInventoryOverNetwork(data) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        if (inventoryId) {
            data.id = inventoryId;
        }

        if (siteId) {
            data.siteId = siteId;
        }

        if (unitId) {
            data.unitId = unitId;
        }

        Axios.post(ENDPOINTS.unit.submitUnitInventory, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("inventory.editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setCallbackData(true);
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UIS1000C"));
            })
    }

    // RENDER

    if (!shown) return [];

    let listItems = (
        <div className={"empty-message"}>{Rosetta.string("inventory.empty_message")}</div>
    );

    if (inventory && inventory.length > 0) {
        listItems = inventory.map((item) => {
            let identifier = [];
            if (item.identifier) {
                identifier = " (" + item.identifier + ")";
            }

            let editButton = [];
            if (siteId) {
                editButton = (
                    <div className={"mt-1"}>
                        <button className={"btn btn-outline-dark"} onClick={() => editInventory(item)}>{Rosetta.string("common.edit")}</button>
                    </div>
                )
            }

            return (
                <li className={"list-group-item"}>
                    <div className={"ms-2 me-auto"}>
                        <div className={"fw-bold"}>{item.name + identifier}</div>
                        <div>
                            {Rosetta.string("inventory.unit_display", {name : item.unitName})}
                            {editButton}
                        </div>
                    </div>
                </li>
            )
        });
    }

    let editor = [];
    if (siteId) {
        let editSaveButton = (<Button label={Rosetta.string("common.save")} className={"btn btn-success"} />);
        if (submitNetworkInFlight) {
            editSaveButton = (<LoadingSpinner inline={true} small={true} />);
        }

        let editWarning = [];
        if (inventoryId) {
            editWarning = (
                <div className={"row mb-2"}>
                    <div className={"alert alert-warning text-center"}>
                        <div>
                            {Rosetta.string("inventory.editor_edit_warning", { name : formState.name, identifier : formState.identifier })}
                        </div>
                        <div className={"mt-2"}>
                            <button className={"btn btn-outline-dark"} onClick={() => clearForm()}>{Rosetta.string("common.clear")}</button>
                        </div>
                    </div>
                </div>
            )
        }

        editor = (
            <div className={"row mb-2"}>
                <div className={"col-12"}>

                    <div className={"card outlined"}>
                        <div className={"card-header"}>
                            {Rosetta.string("inventory.editor_title")}
                        </div>

                        <div className={"card-body"}>

                            {editWarning}

                            <Former
                                state={formState}
                                callback={formDidCallback}>

                                <Container className={"row"}>
                                    <Container className={"col-12"}>
                                        <Text name={"name"} label={Rosetta.string("inventory.editor_name")} className={"form-control"} mandatory={true} />
                                    </Container>
                                </Container>

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12"}>
                                        <Text name={"identifier"} label={Rosetta.string("inventory.editor_identifier")} className={"form-control"} />
                                    </Container>
                                </Container>

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12 text-center"}>
                                        {editSaveButton}
                                    </Container>
                                </Container>

                            </Former>

                        </div>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("inventory.title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            {editor}

            {listItems}

        </Offcanvas>
    )

}