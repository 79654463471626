import {useEffect, useState} from "react";
import Rosetta from "../../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {FilterText} from "../../../filter/FilterText";
import {CommonUtil} from "../../../../util/CommonUtil";
import {AutoTable, AutoTableActions} from "../../../table/AutoTable";
import {Toast} from "../../../toast/TokyoToaster";
import {Column} from "../../../table/TableComponent";
import {Chronos} from "../../../../chronos/Chronos";
import {Navigator} from "../../../../util/Navigator";

export const AvailabilityTableComponent = (props) => {

    const [tablePage, setTablePage] = useState(1);
    const [tablePayload, setTablePayload] = useState({});

    const [filterData, setFilterData] = useState({});
    const [filterNetworkInFLight, setFilterNetworkInFlight] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("");
    const [filterSiteId, setFilterSiteId] = useState(null);
    const [filterUnitId, setFilterUnitId] = useState(null);
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    const [filterBedType, setFilterBedType] = useState(null);
    const [filterNationality, setFilterNationality] = useState(null);

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, [])

    useEffect(() => {
        fetchFilterDataFromNetwork();
    }, [filterSiteId]);

    useEffect(() => {
        createTablePayload();
    }, [
        filterKeyword, filterSiteId, filterUnitId,
        filterStartDate, filterEndDate, filterBedType,
        filterNationality
    ])

    function createTablePayload() {
        const properties = [
            { key : "keyword", value : filterKeyword },
            { key : "siteIds", value : filterSiteId, isArray : true },
            { key : "unitIds", value : filterUnitId, isArray : true },
            { key : "startDate", value : filterStartDate, isDate : true },
            { key : "endDate", value : filterEndDate, isDate : true },
            { key : "bedTypeIds", value : filterBedType, isArray: true },
            { key : "nationalities", value : filterNationality, isArray: true }
        ];

        let tablePayload = CommonUtil.generateFilterPayload(properties);

        setTablePayload(tablePayload);
    }

    function fetchFilterDataFromNetwork() {
        if (filterNetworkInFLight) return;
        setFilterNetworkInFlight(true);

        const data = {
            siteId : filterSiteId && filterSiteId !== "-1" ? filterSiteId : undefined
        };

        Axios.post(ENDPOINTS.availability.getFilterData, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFilterData(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setFilterNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterNetworkInFlight(false);
            });
    }

    return (
        <div className={"availability-table-component"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row justify-content-center"}>

                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    <label>{Rosetta.string("availability.filter_search")}</label>
                                    <FilterText callback={setFilterKeyword} />
                                </div>

                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    {CommonUtil.generateFilterSelect(Rosetta.string("availability.filter_site"), CommonUtil.getOrDefault(filterData, "sites", []), filterSiteId, (e) => setFilterSiteId(e.target.value)) }
                                </div>

                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    {CommonUtil.generateFilterSelect(Rosetta.string("availability.filter_unit"), CommonUtil.getOrDefault(filterData, "units", []), filterUnitId, (e) => setFilterUnitId(e.target.value)) }
                                </div>

                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    <label>{Rosetta.string("availability.filter_start_date")}</label>
                                    <input type={"date"} className={"form-control"} value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                                </div>

                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    <label>{Rosetta.string("availability.filter_end_date")}</label>
                                    <input type={"date"} className={"form-control"} value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                                </div>

                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    {CommonUtil.generateFilterSelect(Rosetta.string("availability.filter_bed_type"), CommonUtil.getOrDefault(filterData, "bedTypes", []), filterBedType, (e) => setFilterBedType(e.target.value)) }
                                </div>

                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    {CommonUtil.generateFilterSelect(Rosetta.string("availability.filter_nationality"), CommonUtil.getOrDefault(filterData, "nationalities", []),  filterNationality, (e) => setFilterNationality(e.target.value)) }
                                </div>

                            </div>

                            <div className={"row mt-4"}>
                                <div className={"col-12"}>
                                    <AutoTable
                                        url={ENDPOINTS.availability.getBedAvailability}
                                        data={tablePayload}
                                        page={tablePage}
                                        callback={(action, data) => {
                                            if (action === AutoTableActions.PAGE_CHANGED) {
                                                setTablePage(data);
                                            } else if (action === AutoTableActions.SORT_CHANGE) {
                                                // TODO
                                            } else if (action === AutoTableActions.ROW_CLICKED) {
                                                Navigator.navigate("/bed/" + data.bedId);
                                            } else if (action === AutoTableActions.ERROR) {
                                                Toast.show(
                                                    Rosetta.string("common.error"),
                                                    data,
                                                    Toast.ERROR,
                                                    Toast.LONG
                                                );
                                            }
                                        }}>

                                        <Column name={"name"} title={Rosetta.string("availability.table_bed_name")} />
                                        <Column name={"unitBedroomName"} title={Rosetta.string("availability.table_bedroom")} />
                                        <Column name={"unitName"} title={Rosetta.string("availability.table_unit")} />
                                        <Column name={"siteName"} title={Rosetta.string("availability.table_site")} />
                                        <Column name={"occupied"} className={"text-center"} title={Rosetta.string("availability.table_occupied")} render={(data, row) => {
                                            if (parseInt(data) === 1) {
                                                return (
                                                    <span className={"badge badge-danger"}>{Rosetta.string("common.yes")}</span>
                                                )
                                            } else {
                                                return (
                                                    <span className={"badge badge-success"}>{Rosetta.string("common.no")}</span>
                                                )
                                            }
                                        }} />
                                        <Column name={"startDate"} className={"text-center"} title={Rosetta.string("availability.table_start_date")} render={(data, row) => {
                                            let out = "--";
                                            if (data !== null) {
                                                out = Chronos.withTimestampSeconds(data).format("dd/MM/yyyy")
                                            }
                                            return (<>{out}</>)
                                        }} />
                                        <Column name={"endDate"} className={"text-center"} title={Rosetta.string("availability.table_end_date")} render={(data, row) => {
                                            let out = "--";
                                            if (data !== null) {
                                                out = Chronos.withTimestampSeconds(data).format("dd/MM/yyyy")
                                            }
                                            return (<>{out}</>)
                                        }} />
                                        <Column name={"bedTypeName"} className={"text-center"} title={Rosetta.string("availability.table_bed_type")} />
                                        <Column name={"nationality"} className={"text-center"} title={Rosetta.string("availability.table_nationality")} />

                                    </AutoTable>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}